import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch } from 'src/store'
import { fetchClientDetails, updateClientDetails } from 'src/store/slices/clients.slice'
import { trackSnowplow } from 'src/store/slices/user.slice'
import { showErrorToast } from 'src/utils/toasts'
import type { ClientFormFields } from './types'
import { clientToForm, formToClientUpdate } from './utils'

type RouteParams = {
  clientId: string
}

const useClientDetails = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const form = useForm<ClientFormFields>({
    shouldFocusError: false,
    reValidateMode: 'onSubmit',
  })
  const params = useParams<RouteParams>()
  const clientId = parseInt(params.clientId ?? '', 10)

  useEffect(() => {
    dispatch(trackSnowplow({ category: 'client_profile', action: 'profile_visit' }))
  }, [])

  useEffect(() => {
    if (!clientId) {
      navigate('/dashboard')
      return
    }

    dispatch(fetchClientDetails({ id: clientId }))
      .unwrap()
      .then((client) => form.reset(clientToForm(client)))
      .catch(() => {
        showErrorToast('Failed to get client data')
        navigate('/dashboard')
      })
  }, [clientId]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateClient = (fields: ClientFormFields) =>
    dispatch(updateClientDetails({ id: clientId, update: formToClientUpdate(fields) }))
      .unwrap()
      .catch((e) => {
        showErrorToast('Failed to update client data')
        throw e
      })

  const saveAndExit = form.handleSubmit((fields) =>
    updateClient(fields).then(() => navigate('/dashboard')),
  )

  const saveAndContinue = form.handleSubmit((fields) => {
    dispatch(trackSnowplow({ category: 'client_profile', action: 'profile_continue' }))
    updateClient(fields).then(() => navigate(`/clients/${clientId}/companies`))
  })

  return { form, saveAndExit, saveAndContinue }
}

export default useClientDetails

import React from 'react'

import {
  ThreeDots,
  OwnerLoan,
  Dividend,
  Bulb,
  Flag,
  Furnishings,
  Maintenance,
  MoneyTag,
  PensionContribution,
  Plane,
  Property,
  LogoCookie,
  MoneyBagDollar,
  Management,
  ReceiptCategory,
  NewProperty,
  Insurance,
  Pot,
} from '@gground/capcom.icons'

export interface TxIconMap {
  icon: React.ReactNode
  category: string
  subcategory: string
}

export const transactionOutboundIconsMap = {
  OWNER_LOAN_REPAYMENT: {
    icon: OwnerLoan,
    category: 'OWNER_LOAN_REPAYMENT',
    subcategory: null,
    label: 'Owner loan repayment',
    color: '#98B18B',
  },
  DIVIDEND: {
    icon: Dividend,
    category: 'DIVIDEND',
    subcategory: null,
    label: 'Dividend',
    color: '#D288DA',
  },
  PENSION_CONTRIBUTION: {
    icon: PensionContribution,
    category: 'PENSION_CONTRIBUTION',
    subcategory: null,
    label: 'Pension contribution',
    color: '#94610A',
  },
  EVIDENCE_OF_PROPERTY_PURCHASE_FUNDS: {
    icon: Property,
    category: 'EXPENSES',
    subcategory: 'EVIDENCE_OF_PROPERTY_PURCHASE_FUNDS',
    label: 'Property purchase funds',
    color: '#2A6574',
  },
  FURNISHINGS: {
    icon: Furnishings,
    category: 'EXPENSES',
    subcategory: 'FURNISHINGS',
    label: 'Furnishings and machinery',
    color: '#E2AF74',
  },
  MANAGEMENT: {
    icon: Management,
    category: 'EXPENSES',
    subcategory: 'MANAGEMENT',
    label: 'Management and agency fees',
    color: '#E6BDEA',
  },
  AGENCY_FEES: {
    icon: MoneyTag,
    category: 'EXPENSES',
    subcategory: 'AGENCY_FEES',
    label: 'Agency fees',
    color: '#E8F4F7',
  },
  UTILITIES: {
    icon: Bulb,
    category: 'EXPENSES',
    subcategory: 'UTILITIES',
    label: 'Council tax and utilities',
    color: '#B53CC3',
  },
  SERVICE_CHARGES: {
    icon: Flag,
    category: 'EXPENSES',
    subcategory: 'SERVICE_CHARGES',
    label: 'Ground rent & service charges',
    color: '#CEDAC8',
  },
  TRAVEL: {
    icon: Plane,
    category: 'EXPENSES',
    subcategory: 'TRAVEL',
    label: 'Travel expenses',
    color: '#FACAC1',
  },
  MAINTENANCE: {
    icon: Maintenance,
    category: 'EXPENSES',
    subcategory: 'MAINTENANCE',
    label: 'Maintenance and repairs',
    color: '#FFEC99',
  },
  GET_GROUND_FEES: {
    icon: LogoCookie,
    category: 'EXPENSES',
    subcategory: 'GET_GROUND_FEES',
    label: 'GetGround fees',
    color: '#96CCD9',
  },
  MORTGAGE_REPAYMENT: {
    icon: Property,
    category: 'EXPENSES',
    subcategory: 'MORTGAGE_REPAYMENT',
    label: 'Mortgage repayment / Fee',
    color: '#2A6574',
  },
  INSURANCE: {
    icon: Insurance,
    category: 'EXPENSES',
    subcategory: 'INSURANCE',
    label: 'Insurance',
    color: '#BAF2C7',
  },
  RENOVATIONS: {
    icon: NewProperty,
    category: 'EXPENSES',
    subcategory: 'RENOVATIONS',
    label: 'Renovations',
    color: '#BAF2C7',
  },
  OTHER_EXPENSES: {
    icon: ReceiptCategory,
    category: 'EXPENSES',
    subcategory: 'OTHER',
    label: 'Other expenses',
    color: '#BAF2C7',
  },
  INVESTMENT_POT_TRANSFER: {
    icon: Pot,
    category: 'INVESTMENT_POT_TRANSFER',
    subcategory: null,
    label: 'Investment pot transfer',
    color: '#BAF2C7',
  },
  OTHER: {
    icon: ThreeDots,
    category: 'OTHER',
    subcategory: null,
    label: 'Other',
    color: '#4D6242',
  },
}

export const transactionInboundIconsMap = {
  RENT: {
    icon: MoneyBagDollar,
    category: 'RENT',
    subcategory: null,
    label: 'Rent',
    color: '#2A6574',
  },
  OWNER_LOAN: {
    icon: OwnerLoan,
    category: 'OWNER_LOAN',
    subcategory: null,
    label: 'Owner loan',
    color: '#E6BDEA',
  },
  INVESTMENT_POT_TRANSFER: {
    icon: Pot,
    category: 'INVESTMENT_POT_TRANSFER',
    subcategory: null,
    label: 'Investment pot transfer',
    color: '#BAF2C7',
  },
  OTHER: {
    icon: ThreeDots,
    category: 'OTHER',
    subcategory: null,
    label: 'Other',
    color: '#96CCD9',
  },
}

import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const CircledArrowIcon = (props: SvgIconProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M12.0001 20.5713C16.734 20.5713 20.5716 16.7338 20.5716 11.9999C20.5716 7.26603 16.734 3.42847 12.0001 3.42847C7.26627 3.42847 3.42871 7.26603 3.42871 11.9999C3.42871 16.7338 7.26627 20.5713 12.0001 20.5713Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 15.4284L15.4286 11.9999L12 8.57129"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.57178 12H15.4289"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)

export default CircledArrowIcon

import { useState } from 'react'
import { useAppDispatch } from 'src/store'
import { updateTransactionCategory } from 'src/store/slices/company.slice'
import useCategory from 'src/hooks/categories/useCategory'
import { trackSnowplow } from 'src/store/slices/user.slice'
import { Transaction } from 'src/types'

interface Props {
  tx: Transaction
  clientId: number
  companyId: number
}

const useTransaction = ({ tx, clientId, companyId }: Props) => {
  const dispatch = useAppDispatch()
  const [anchorEl, setAnchorEl] = useState<null | Element>(null)
  const [isAdditionalInfoOpen, setIsAdditionalInfoOpen] = useState(false)

  const { txsIconsMap } = useCategory({ direction: tx.direction })

  const selectedCategory = Object.values(txsIconsMap).find((item) =>
    tx.subcategory ? item.subcategory === tx.subcategory : item.category === tx.category,
  )

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setTimeout(() => setIsAdditionalInfoOpen(false), 200)
  }

  const handleChangeCategory = (category: string, subcategory: string | null) => {
    if (category === 'OTHER') {
      setIsAdditionalInfoOpen(true)
    } else {
      dispatch(
        updateTransactionCategory({
          clientId,
          companyId,
          transactionId: tx.id,
          update: { category, subcategory },
        }),
      )
      handleCloseMenu()
    }
  }

  const handleOpenCategoriesMenu = (e: React.MouseEvent) => {
    if (selectedCategory?.category === 'OTHER') {
      setIsAdditionalInfoOpen(true)
    }
    setAnchorEl(e.currentTarget)
    dispatch(
      trackSnowplow({
        category: 'transactions',
        action: selectedCategory ? 'change_category' : 'add_category',
        ppId: companyId,
      }),
    )
  }

  const openCategoriesMenu = () => setIsAdditionalInfoOpen(false)

  const submitAdditionalInfo = (values: { additionalInfo: string }) => {
    dispatch(
      updateTransactionCategory({
        clientId,
        companyId,
        transactionId: tx.id,
        update: {
          category: 'OTHER',
          subcategory: null,
          category_additional_info: values.additionalInfo,
        },
      }),
    )
    handleCloseMenu()
  }

  return {
    anchorEl,
    txsIconsMap,
    isAdditionalInfoOpen,
    selectedCategory,
    handleCloseMenu,
    handleChangeCategory,
    handleOpenCategoriesMenu,
    openCategoriesMenu,
    submitAdditionalInfo,
  }
}

export default useTransaction

import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { Input, Checkbox } from '@gground/capcom.form'
import { useFormContext } from 'react-hook-form'
import { validateLettersOnly } from './utils'
import type { ClientFormFields } from './types'

const ClientNameFormFragment = () => {
  const { register, watch, setValue, clearErrors, errors } = useFormContext<ClientFormFields>()

  const hasChangedName = watch('has_changed_name', false)
  const prefersDifferentName = watch('prefers_different_name', false)

  return (
    <Box mt={4} component="section">
      <Typography variant="h2" style={{ fontWeight: 500 }}>
        Client name
      </Typography>
      <Box mt={2}>
        <Input
          type="text"
          id="first_name"
          name="first_name"
          label="First name"
          helperText={errors.first_name && errors.first_name?.message}
          error={!!errors.first_name}
          onFocus={() => clearErrors()}
          ref={register({ validate: validateLettersOnly, required: 'This field is required' })}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <Input
          type="text"
          id="middle_name"
          name="middle_name"
          label="Middle name(s)"
          hint="If the client doesn't have any middle names you can leave this blank"
          helperText={errors.middle_name && errors.middle_name?.message}
          error={!!errors.middle_name}
          onFocus={() => clearErrors()}
          ref={register({ validate: validateLettersOnly })}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <Input
          type="text"
          id="last_name"
          name="last_name"
          label="Last name"
          helperText={errors.last_name && errors.last_name?.message}
          error={!!errors.last_name}
          onFocus={() => clearErrors()}
          ref={register({ validate: validateLettersOnly, required: 'This field is required' })}
          fullWidth
        />
      </Box>
      <Box mt={4}>
        <Checkbox
          id="has_changed_name"
          name="has_changed_name"
          label="Client has previously changed name"
          checked={hasChangedName}
          onChange={() => setValue('has_changed_name', !hasChangedName)}
          ref={register()}
        />
        {hasChangedName ? (
          <Box mt={2}>
            <Input
              type="text"
              id="previous_names"
              name="previous_names"
              label="Previous names"
              helperText={errors.previous_names && errors.previous_names?.message}
              error={!!errors.previous_names}
              onFocus={() => clearErrors()}
              ref={register({ validate: validateLettersOnly })}
              fullWidth
            />
          </Box>
        ) : null}
      </Box>
      <Box mt={2}>
        <Checkbox
          id="prefers_different_name"
          name="prefers_different_name"
          label="Client prefers to be called a different name"
          checked={prefersDifferentName}
          onChange={() => setValue('prefers_different_name', !prefersDifferentName)}
          ref={register()}
        />
        {prefersDifferentName ? (
          <Box mt={2}>
            <Input
              type="text"
              id="nickname"
              name="nickname"
              label="Preferred name"
              helperText={errors.nickname && errors.nickname?.message}
              error={!!errors.nickname}
              onFocus={() => clearErrors()}
              ref={register({ validate: validateLettersOnly })}
              fullWidth
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}

export default ClientNameFormFragment

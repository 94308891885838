import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Bank = (props: SvgIconProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <path d="M19.8049 7.22401L12.1249 4.02399C12.0449 3.992 11.9553 3.992 11.8785 4.02399L4.19842 7.22401C4.08 7.27523 4 7.39042 4 7.52161V8.48163C4 8.65764 4.144 8.80162 4.31999 8.80162H19.6801C19.8561 8.80162 20.0001 8.65764 20.0001 8.48163V7.52161C20.0001 7.39042 19.9233 7.27523 19.8049 7.22401Z" />
    <path d="M19.6801 18.4009H4.31999C4.144 18.4009 4 18.5417 4 18.7209V19.6809C4 19.8569 4.144 20.0009 4.31999 20.0009H19.6801C19.8561 20.0009 20.0001 19.8569 20.0001 19.6809V18.7209C20.0001 18.5417 19.8561 18.4009 19.6801 18.4009Z" />
    <path d="M15.9914 16.8522C15.8154 16.8522 15.6714 16.9962 15.6714 17.1722V17.761H19.1658V17.1722C19.1658 16.9962 19.0218 16.8522 18.8458 16.8522H18.5738V10.3465H18.8458C19.0218 10.3465 19.1658 10.2025 19.1658 10.0265V9.44092H15.6714V10.0265C15.6714 10.2025 15.8154 10.3465 15.9914 10.3465H16.2602V16.8522H15.9914Z" />
    <path d="M5.52019 16.8522C5.3442 16.8522 5.2002 16.9962 5.2002 17.1722V17.761H8.69462V17.1722C8.69462 16.9962 8.55062 16.8522 8.37463 16.8522H8.10581V10.3465H8.37463C8.55062 10.3465 8.69462 10.2025 8.69462 10.0265V9.44092H5.2002V10.0265C5.2002 10.2025 5.3442 10.3465 5.52019 10.3465H5.78901V16.8522H5.52019Z" />
    <path d="M10.7556 16.8522C10.5795 16.8522 10.4355 16.9962 10.4355 17.1722V17.761H13.93V17.1722C13.93 16.9962 13.786 16.8522 13.61 16.8522H13.3412V10.3465H13.61C13.786 10.3465 13.93 10.2025 13.93 10.0265V9.44092H10.4355V10.0265C10.4355 10.2025 10.5795 10.3465 10.7556 10.3465H11.0243V16.8522H10.7556Z" />
  </SvgIcon>
)

export default Bank

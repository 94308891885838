import React, { useEffect } from 'react'
import { Typography, Box, makeStyles, Grid } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles/withStyles'
import { ExternalLink, Check as CheckIcon } from '@gground/capcom.icons'
import { useNavigate, useParams } from 'react-router-dom'

import Layout from 'src/components/Layout'
import BackButton from 'src/components/BackButton'
import StandaloneSVG from 'src/assets/images/standalone.svg'
import HoldingCoSVG from 'src/assets/images/holdingco.svg'
import SubsidiarySVG from 'src/assets/images/subsidiary.svg'
import { useAppDispatch, useAppSelector } from 'src/store'
import { fetchPartnerOverview } from 'src/store/slices/partner.slice'
import { trackSnowplow } from 'src/store/slices/user.slice'
import { OverviewStatus } from 'src/types'
import StructureOption from './StructureOption'

const useStyles = makeStyles((theme) => ({
  cardsContainer: {
    display: 'flex',
    marginTop: theme.spacing(4),
  },
  cardsList: {
    marginTop: '10px',
    marginBottom: theme.spacing(3),
    '& p': {
      marginBottom: theme.spacing(1),
      lineHeight: '18px',
    },
    '& svg': {
      color: '#53C163',
      fontSize: 16,
      marginRight: theme.spacing(0.5),
      paddingTop: theme.spacing(0.25),
    },
  },
  extLink: {
    marginTop: theme.spacing(1),
    '& > svg': {
      color: '#006BC2',
      fontSize: '12px',
      marginLeft: 3,
      marginRight: 7,
    },
  },
}))

const options = [
  {
    title: 'Standalone limited company',
    cta: 'Setup stand-alone company',
    image: StandaloneSVG,
    description: (classes: ClassNameMap) => (
      <>
        <Typography variant="subtitle1">
          Shareholders own shares in a limited company that will contain a single buy-to-let
          property.
        </Typography>
        <Box className={classes.cardsList}>
          <Typography>
            <CheckIcon /> 100 ordinary shares
          </Typography>
          <Typography>
            <CheckIcon /> Includes SPV company formation, incorporated under SIC code 68209
          </Typography>
          <Typography>
            <CheckIcon /> Best-in-class legal documents
          </Typography>
          <Typography>
            <CheckIcon /> Secretarial services & Business account
          </Typography>
          <Typography>
            <CheckIcon /> Your clients can have multiple SPVs
          </Typography>
        </Box>
      </>
    ),
    onClick:
      ({ navigate, dispatch }) =>
      () => {
        dispatch(trackSnowplow({ category: 'company_structure', action: 'spv_selected' }))
        navigate(`shareholding`, { state: { isHoldCo: false } })
      },
  },
  {
    title: 'Holding company & subsidiary',
    cta: 'Setup holding company & subsidiary',
    image: HoldingCoSVG,
    description: (classes: ClassNameMap) => (
      <>
        <Typography variant="subtitle1">
          Shareholders will own shares in a holding company which owns 100% of all subsidiaries.s
        </Typography>
        <Box className={classes.cardsList}>
          <Typography>
            <CheckIcon /> 1000 ordinary shares in the holding company
          </Typography>
          <Typography>
            <CheckIcon /> Each subsidiary is an SPV containing a single buy-to-let property
          </Typography>
          <Typography>
            <CheckIcon /> After holding company setup, add as many subsidiaries as needed
          </Typography>
          <Typography>
            <CheckIcon /> Each subsidiary gets a business account & secretarial services
          </Typography>
          <Typography>
            <a
              href="https://help.getground.co.uk/en/collections/3490973-holding-company-structure-explained"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
              className={classes.extLink}
            >
              <ExternalLink /> Learn more
            </a>
          </Typography>
        </Box>
      </>
    ),
    onClick:
      ({ navigate, dispatch }) =>
      () => {
        dispatch(trackSnowplow({ category: 'company_structure', action: 'holdco_selected' }))
        navigate(`shareholding`, { state: { isHoldCo: true } })
      },
  },
  {
    title: 'Add a subsidiary to holding company',
    cta: 'Add a subsidiary',
    image: SubsidiarySVG,
    description: (classes: ClassNameMap) => (
      <>
        <Typography variant="subtitle1">
          Add a limited company as a subsidiary to one of your client&apos;s existing holding
          companies.
        </Typography>
        <Box className={classes.cardsList}>
          <Typography>
            <CheckIcon /> Subsidiaries are 100% owned by the holding company
          </Typography>
          <Typography>
            <CheckIcon /> Each subsidiary is an SPV containing a single buy-to-let property
          </Typography>
          <Typography>
            <CheckIcon /> Each subsidiary gets a business account and secretarial services
          </Typography>
        </Box>
      </>
    ),
    onClick:
      ({ navigate, dispatch }) =>
      () => {
        dispatch(trackSnowplow({ category: 'company_structure', action: 'sub_selected' }))
        navigate(`controlling-holdco`)
      },
  },
]

const CompanyStructure = () => {
  const classes = useStyles()
  const params = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const clientOverview = useAppSelector((state) =>
    state.partner.clients.find((client) => client.id === parseInt(params.clientId ?? '', 10)),
  )
  const hasExistingHoldco = clientOverview?.property_purchases.some(
    (pp) => pp.is_holding_co && pp.company_formation.incorporation === OverviewStatus.COMPLETE,
  )

  useEffect(() => {
    dispatch(trackSnowplow({ category: 'company_structure', action: 'company_structure_visit' }))
    dispatch(fetchPartnerOverview())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // If client profile is still in progress, resume company creation
  useEffect(() => {
    if (!clientOverview) return
    if (clientOverview.profile_status !== OverviewStatus.IN_PROGRESS) return

    const ppInProgress = clientOverview.property_purchases.find(
      (pp) => pp.design_status === OverviewStatus.IN_PROGRESS,
    )

    if (!ppInProgress) return

    const companyId = ppInProgress.is_holding_co
      ? ppInProgress.subsidiaries?.[0].id
      : ppInProgress.id

    if (companyId) {
      navigate(`/clients/${clientOverview.id}/companies/${companyId}/shareholding`, {
        replace: true,
      })
    } else {
      navigate('/dashboard')
    }
  }, [clientOverview, navigate])

  return (
    <Layout>
      <Box my={4}>
        <BackButton to="/dashboard" />
        <Box my={2}>
          <Typography variant="h1">Choose your company structure</Typography>
        </Box>
        <Typography>
          Choosing the right company structure will help your client to optimise for their goals. We
          can help you understand the advantages of the different structures so that you can choose
          the one that works for them.
        </Typography>
        <Box className={classes.cardsContainer}>
          <Grid container justifyContent="center" spacing={4}>
            {options
              .filter((opt) => (hasExistingHoldco ? true : opt.cta !== 'Add a subsidiary'))
              .map(({ title, cta, image, description, onClick }) => (
                <Grid key={title} item xs={12} md={4}>
                  <StructureOption
                    title={title}
                    cta={cta}
                    image={image}
                    description={description(classes)}
                    onClick={onClick({
                      navigate,
                      dispatch,
                    })}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
    </Layout>
  )
}

export default CompanyStructure

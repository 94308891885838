import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as PropertyPurchasesAPI from 'src/api/propertyPurchases'
import type {
  CreatePropertyPurchasePayload,
  UpdatePropertyPurchasePayload,
  FetchPropertyPurchasePayload,
  SendEmailForApprovalOptions,
} from 'src/api/propertyPurchases'
import type { PropertyPurchase } from 'src/types'

type PropertyPurchasesState = Record<PropertyPurchase['id'], PropertyPurchase>

const initialState: PropertyPurchasesState = {}

const createPropertyPurchase = createAsyncThunk<PropertyPurchase, CreatePropertyPurchasePayload>(
  'propertyPurchases/createPropertyPurchase',
  (options) => PropertyPurchasesAPI.createPropertyPurchase(options),
)

const fetchPropertyPurchaseDetails = createAsyncThunk<
  PropertyPurchase,
  FetchPropertyPurchasePayload
>('propertyPurchases/fetchPropertyPurchaseDetails', (options) =>
  PropertyPurchasesAPI.fetchPropertyPurchase(options),
)

const updatePropertyPurchaseDetails = createAsyncThunk<
  PropertyPurchase,
  UpdatePropertyPurchasePayload
>('propertyPurchases/updatePropertyPurchaseDetails', (options) =>
  PropertyPurchasesAPI.updatePropertyPurchase(options),
)

const sendEmailForApproval = createAsyncThunk<void, SendEmailForApprovalOptions>(
  'clients/sendEmailForApproval',
  (options) => PropertyPurchasesAPI.sendEmailForApproval(options),
)

const propertyPurchasesSlice = createSlice({
  name: 'propertyPurchases',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPropertyPurchaseDetails.fulfilled, (state, action) => {
      const propertyPurchase = action.payload
      if (propertyPurchase?.id) {
        state[propertyPurchase.id] = propertyPurchase
      }
    })
  },
})

// actions
// export const { } = propertyPurchasesSlice.actions
export {
  createPropertyPurchase,
  updatePropertyPurchaseDetails,
  fetchPropertyPurchaseDetails,
  sendEmailForApproval,
}
// reducer
export default propertyPurchasesSlice.reducer

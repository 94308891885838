export const EXPENSE_CATEGORIES = [
  {
    category: 'FURNISHINGS',
    label: 'Property furnishings and machinery',
  },
  {
    category: 'MANAGEMENT',
    label: 'Management and agency fees',
  },
  {
    category: 'AGENCY_FEES',
    label: 'Agency fees',
  },
  {
    category: 'UTILITIES',
    label: 'Council tax and utilities',
  },
  {
    category: 'SERVICE_CHARGES',
    label: 'Ground rent & service charges',
  },
  {
    category: 'TRAVEL',
    label: 'Travel expenses',
  },
  {
    category: 'MAINTENANCE',
    label: 'Maintenance and repairs',
  },
  {
    category: 'GET_GROUND_FEES',
    label: 'GetGround fees',
  },
  {
    category: 'MORTGAGE_REPAYMENT',
    label: 'Mortgage repayment',
  },
  {
    category: 'OTHER',
    label: 'Other expenses',
  },
] as const

import { CURRENCY_SYMBOLS } from 'src/utils/currencySymbols'
import { DOCUMENT_TYPES } from 'src/utils/documentTypes'
import { EXPENSE_CATEGORIES } from 'src/utils/expenseCategories'

export type Auth2FAMethod = 'sms' | 'totp' | null

export type AuthStatus = 'UNAUTHENTICATED' | 'AUTHENTICATED' | 'AUTHORIZED'

type ClientEmploymentStatus =
  | 'full_time'
  | 'part_time'
  | 'self_employed'
  | 'unemployed'
  | 'pensioner'
  | 'student'
  | 'other'

export interface Client {
  id: number
  email: string
  first_name: string
  last_name: string
  middle_name: string | null
  nickname: string | null
  previous_names: string | null
  date_of_birth: string | null
  birth_town: string | null
  nationality: number | null
  passport_number: string | null
  employment_status: ClientEmploymentStatus | null
  employment_status_other_reason: string | null
  occupation: string | null
  first_time_buyer: boolean | null
  first_time_landlord: boolean | null
  global_properties_count: number | null
  uk_properties_count: number | null
  country: number
  postcode: string | null
  posttown: string | null
  premise: string | null
  street: string | null
  thoroughfare: string | null
  phone_number: string | null
}

export type ClientUpdate = Partial<Omit<Client, 'id' | 'email'>>

export interface Shareholder {
  first_name: string
  last_name: string
  email: string
  allocated_shares: number
  is_director: boolean
  is_existing_user?: boolean
  user_id: number | null
}

export interface Solicitor {
  deferred: boolean
  find_solicitor: boolean
  first_name: string | null
  last_name: string | null
  email: string | null
  phone_number: string | null
  case_ref: string | null
}

export type Address = {
  premise: string | null
  street: string | null
  post_town: string | null
  post_code: string | null
  country: string | null
}

export interface PropertyPurchase {
  id: number
  company_id: number | null
  shareholder_details: {
    shareholders: Shareholder[]
    is_complete: boolean
  }
  is_holding_co: boolean
  parent_company_id: number | null
  property_address: {
    address: Address | null
    is_new_build: boolean | null
    is_confirmed: boolean | null
    skipped: boolean
  } | null
  purchase_details: {
    price: {
      amount_in_cents: number
      currency: string
    } | null
    completion_date: string | null
    using_mortgage: boolean | null
    property_type: string | null
    bedrooms: string | null
    tenure_type: string | null
    property_type_other_reason: string | null
    tenure_type_other_reason: string | null
    estimated_monthly_rent: number | null
    source_of_funds: string | null
    source_of_funds_other_reason: string | null
  } | null
  tax_questions: {
    is_owner_of_other_companies: boolean | null
    assets_less_than_10m: boolean | null
    more_than_50_employees: boolean | null
    turnover_less_than_10m: boolean | null
  } | null
  solicitor_details: Solicitor | null
  property_agent_details: {
    has_property_agent: boolean | null
    email_address: string | null
  } | null
}

export type PropertyPurchaseUpdate = Partial<
  Omit<PropertyPurchase, 'id' | 'is_holding_co' | 'parent_company_id'>
>

export enum OverviewStatus {
  TODO = 'TODO',
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  COMPLETE = 'COMPLETE',
}
export interface PropertyPurchaseOverview {
  id: number
  is_holding_co: boolean
  address: string
  design_status:
    | OverviewStatus.TODO
    | OverviewStatus.IN_PROGRESS
    | OverviewStatus.PENDING_APPROVAL
    | OverviewStatus.COMPLETE
  client_approval: OverviewStatus.TODO | OverviewStatus.PENDING_APPROVAL | OverviewStatus.COMPLETE
  company_formation: {
    onboarding: OverviewStatus.IN_PROGRESS | OverviewStatus.COMPLETE
    incorporation: OverviewStatus.NOT_STARTED | OverviewStatus.IN_PROGRESS | OverviewStatus.COMPLETE
    signing: OverviewStatus.NOT_STARTED | OverviewStatus.IN_PROGRESS | OverviewStatus.COMPLETE
  }
  has_business_account: boolean
  subsidiaries: PropertyPurchaseOverview[] | null
}

export interface ClientOverview {
  id: number
  name: string
  email: string
  profile_status:
    | OverviewStatus.IN_PROGRESS
    | OverviewStatus.PENDING_APPROVAL
    | OverviewStatus.COMPLETE
  property_purchases: PropertyPurchaseOverview[]
}

export interface PartnerOverview {
  clients: ClientOverview[]
}
export interface Transaction {
  id: number
  date: string
  payer: string | null
  payee: string | null
  reference: string
  category: string | null
  subcategory: string | null
  category_additional_info: string | null
  direction: 'INBOUND' | 'OUTBOUND'
  amount_in_cents: number
  balance_in_cents: number | null
  fee?: {
    amount_in_cents: number
    status: 'PENDING' | 'FAILED' | 'SUCCESSFUL' | 'UNKNOWN'
  }
  status:
    | 'NONE'
    | 'ATTEMPTED'
    | 'ACKNOWLEDGED'
    | 'ERROR'
    | 'REJECTED'
    | 'SUCCESSFUL'
    | 'CANCELLED'
    | 'CONVERSION_ATTEMPTED'
    | 'CONVERSION_PENDING'
    | 'CONVERSION_FAILED'
    | 'FAILED_PRECONDITION'
    | 'FAILED'
}
export interface CompanyTransactions {
  transactions: [Transaction]
}

export interface AvailableBalance {
  available_balance_in_cents: number
}

export interface CompanyAccountDetails {
  institution_name: string
  institution_address: string
  account_name: string
  registered_address: string
  sort_code: string
  account_number: string
  international_details: {
    bank_name: string
    bank_address: string
    iban: string
    bic: string
  } | null
}

export interface TransactionCategoryUpdate {
  category: string | null
  subcategory: string | null
  category_additional_info?: string
}
export interface Document {
  id: string
  name: string
  type: typeof DOCUMENT_TYPES[number]['type']
  upload_date: string
  creator: Shareholder['user_id']
  creator_type: 'user' | 'partner' | 'auto' | 'employee'
  document_date: string | null
  expense_category: typeof EXPENSE_CATEGORIES[number]['category'] | null
  currency: typeof CURRENCY_SYMBOLS[number]['shorthand'] | null
  description: string | null
  merchant_name: string | null
  amount_in_cents: number | null
}

export type DocumentUpload = Omit<Document, 'id' | 'upload_date' | 'creator' | 'creator_type'>

export type DocumentUpdate = Omit<Document, 'upload_date' | 'creator' | 'creator_type'>

export interface StorageFileInfo {
  filename: string
  signed_url: string
}

export interface HoldingCompanyDetails {
  company_name: string
  registration_number: string
  utr_code: null
  registered_address: {
    premise: string | null
    street: string | null
    thoroughfare: string | null
    posttown: string | null
    postcode: string | null
    country: string | null
  }
  is_change_request_active: boolean
}

export interface SubsidiaryCompanyDetails {
  id: string
  name: string
  property_purchase_details: {
    property_purchase_id: string
    completion_date: string
    price_in_cents: number
    using_mortgage: boolean
    address: {
      premise: string | null
      street: string | null
      thoroughfare: string | null
      posttown: string | null
      postcode: string | null
      country: string | null
    }
  }
}

export interface CompanyDetails {
  company_details: {
    company_id: number
    company_name: string
    company_type: 'SPV' | 'SUBSIDIARY' | 'HOLDCO'
    registration_number: string
    utr_code: string | null
    registered_address: {
      premise: string
      street: string
      thoroughfare: string
      post_town: string
      post_code: string
      country: string
    }
    parent_company_id: null
    has_fees: boolean
  }
  property_purchase_details: {
    property_purchase_id: number
    completion_date: string | null
    price_in_cents: number
    using_mortgage: boolean
    address: {
      premise: string | null
      street: string | null
      thoroughfare: string | null
      post_town: string | null
      post_code: string | null
      country: string | null
    }
  } | null
  shareholders: Shareholder[]
  solicitor_details: {
    first_name: string | null
    last_name: string | null
    email: string | null
    phone_number: string | null
    case_reference: string | null
    solicitor_email_sent_at: string | null
  } | null
  parent_company_details: HoldingCompanyDetails | null
  subsidiary_companies: SubsidiaryCompanyDetails[]
}

import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import Layout from 'src/components/Layout'
import CopyToClipboard from 'src/components/CopyToClipboard'
import SpeechIcon from 'src/icons/SpeechIcon'
import GoogleAuthenticatorIcon from 'src/icons/GoogleAuthenticatorIcon'
import Auth2FASMSImage from 'src/assets/images/auth-2fa-sms.svg'
import Auth2FAAuthenticatorImage from 'src/assets/images/auth-2fa-authenticator.svg'
import { SignUp2FACard } from './SignUp2FACard'

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(8),
  },
  info: {
    marginTop: theme.spacing(4),
  },
  subtitle: {
    marginTop: theme.spacing(2),
    color: theme.palette.info.main,
    fontWeight: 500,
  },
  container: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      flex: '1 1 100%',
    },
    '& > :not(:first-child)': {
      margin: theme.spacing(0, 0, 0, 2),
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& > :not(:first-child)': {
        margin: theme.spacing(2, 0, 0),
      },
    },
  },
  icon: {
    width: 36,
    height: 36,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flexGrow: 1,
    padding: theme.spacing(6, 0),
  },
}))

interface SignUp2FASelectProps {
  onSelectSMS: () => void
  onSelectOTP: () => void
}

const SignUp2FASelect = ({ onSelectSMS, onSelectOTP }: SignUp2FASelectProps) => {
  const classes = useStyles()

  return (
    <Layout>
      <Typography variant="h1" className={classes.title}>
        Additional security measure
      </Typography>
      <Typography className={classes.info}>
        We take privacy and security very seriously. Each time you log in we will ask you to
        authorise this using an additional security measure - you can choose from the two methods
        below.
      </Typography>
      <Typography variant="h2" className={classes.subtitle}>
        Which additional security method would you like to use?
      </Typography>
      <Box className={classes.container}>
        <SignUp2FACard
          title="SMS Text Messages"
          cta="Use SMS Text Messages"
          icon={<SpeechIcon className={classes.icon} />}
          image={<img src={Auth2FASMSImage} alt="" />}
          text={
            <span>
              Receive text messages on your phone. This is a common method for two factor
              authentication that is quick and simple to use.
            </span>
          }
          onClick={onSelectSMS}
        />
        <SignUp2FACard
          title="Google Authenticator"
          cta="Use Google Authenticator"
          icon={<GoogleAuthenticatorIcon className={classes.icon} />}
          image={<img src={Auth2FAAuthenticatorImage} alt="" />}
          text={
            <span>
              This is the <strong>safest method</strong> of two factor authentication available, and
              it&apos;s the one we recommend you use for maximum security of your account.
            </span>
          }
          onClick={onSelectOTP}
        />
      </Box>
      <Box className={classes.footer}>
        <Typography>
          Need help? You can email <CopyToClipboard text="gethelp@getground.co.uk" />
        </Typography>
      </Box>
    </Layout>
  )
}

export default SignUp2FASelect

const PING_INTERVAL_MS = 1000 // ping once every second
const INACTIVITY_THRESHOLD_MS = 60000 // ignore up to 1 minute of inactivity

export const onWakeUp = (callback: () => void) => {
  let lastCheckTimestamp = Date.now()

  const check = () => {
    const now = Date.now()
    if (now > lastCheckTimestamp + PING_INTERVAL_MS + INACTIVITY_THRESHOLD_MS) {
      callback()
    }
    lastCheckTimestamp = now

    setTimeout(check, PING_INTERVAL_MS)
  }

  check()
}

import React from 'react'
import { Typography, Box, makeStyles } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { Button, ButtonOpeningModal, Callout } from '@gground/capcom.core'
import { Mail as MailIcon } from '@gground/capcom.icons'

import { useAppDispatch } from 'src/store'
import { trackSnowplow } from 'src/store/slices/user.slice'
import Layout from 'src/components/Layout'
import BackButton from 'src/components/BackButton'
import ConfirmationModal from './ConfirmationModal'
import useCompanyReview from './useCompanyReview'
import Section from './Section'
import { OverviewStatus } from '../../types'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 600,
    margin: theme.spacing(2, 0),
  },
}))

type RouteParams = {
  clientId: string
  companyId: string
}

const CompanyReview = () => {
  const classes = useStyles()
  const params = useParams<RouteParams>()
  const clientId = Number(params.clientId)
  const companyId = Number(params.companyId)
  const dispatch = useAppDispatch()
  const {
    isHoldingCo,
    isSubsidiary,
    clientName,
    clientProfileRows,
    shareholderDetailsRows,
    companyDetailsRows,
    clientStatus,
  } = useCompanyReview(clientId, companyId)

  return (
    <Layout>
      <Box my={4} className={classes.container}>
        <BackButton to={`/clients/${clientId}/companies/${companyId}/details`} />
        <Box my={2}>
          <Typography variant="h1">Send to client for approval</Typography>
        </Box>
        <Typography>
          Please review the information you have entered on behalf of {clientName}. If it is
          correct, then you can proceed by sending it to them for approval.
        </Typography>
        {!isSubsidiary && (
          <>
            <Section
              title="Client profile"
              link={
                clientStatus === OverviewStatus.IN_PROGRESS ? `/clients/${clientId}` : undefined
              }
              rows={clientProfileRows}
              onLinkClick={() =>
                dispatch(
                  trackSnowplow({
                    category: 'review_screen',
                    action: 'review_screen_change_profile',
                    ppId: companyId,
                  }),
                )
              }
            />
            <Section
              title={isHoldingCo ? 'Holding Company shareholder details' : 'Shareholder details'}
              link={`/clients/${clientId}/companies/${companyId}/shareholding`}
              rows={shareholderDetailsRows}
              onLinkClick={() =>
                dispatch(
                  trackSnowplow({
                    category: 'review_screen',
                    action: 'review_screen_change_shareholder',
                    ppId: companyId,
                  }),
                )
              }
            />
          </>
        )}
        <Section
          title={isHoldingCo ? 'Subsidiary Company details' : 'Company details'}
          link={`/clients/${clientId}/companies/${companyId}/details`}
          rows={companyDetailsRows}
          onLinkClick={() =>
            dispatch(
              trackSnowplow({
                category: 'review_screen',
                action: 'review_screen_change_company',
                ppId: companyId,
              }),
            )
          }
        />

        <Box mt={4} mb={2}>
          <Callout severity="warning">
            <Typography>
              Once you proceed, you will no longer be able to edit <b>{clientName}’s</b> profile or
              company details. If something is incorrect or missing, they will be able to update it
              before company formation begins.
            </Typography>
          </Callout>
        </Box>
        <Box mb={4}>
          <ButtonOpeningModal
            title="Send to client for approval"
            dialogProps={{
              maxWidth: 'sm',
              fullWidth: true,
            }}
            handleClick={() =>
              dispatch(
                trackSnowplow({
                  category: 'review_screen',
                  action: 'review_screen_send_to_client',
                  ppId: companyId,
                }),
              )
            }
            button={<Button startIcon={<MailIcon />}>Send to client</Button>}
          >
            {(closeModal) => (
              <ConfirmationModal
                clientId={clientId}
                propertyPurchaseId={companyId}
                onClose={closeModal}
                clientName={clientName}
              />
            )}
          </ButtonOpeningModal>
        </Box>
      </Box>
    </Layout>
  )
}

export default CompanyReview

import React, { useState } from 'react'
import {
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
  FormHelperText,
  Typography,
  InputLabel,
} from '@material-ui/core'
import { PasswordReveal as EyeIcon, Check as CheckIcon } from '@gground/capcom.icons'
import {
  hasLowerCaseLetter,
  hasCapitalLetter,
  hasSpecialCharacter,
  hasNumber,
} from 'src/utils/validate'
import type { UseFormMethods } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
  passwordHintsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1, 0, 0.5, 0),

    '& span': {
      marginBottom: theme.spacing(1),
      fontSize: 12,
      color: '#4E545F',
    },
  },
  label: {
    marginBottom: theme.spacing(0.5),
  },
  check: {
    marginRight: 6,
    color: '#53C163',
  },
}))

type FormOptions = Pick<UseFormMethods, 'errors' | 'register' | 'watch'>

type PasswordInputProps = FormOptions & {
  withHints?: boolean
  isFormSubmitted: boolean
  onFocus: (arg: never) => void
}

const PasswordInput = ({
  errors,
  register,
  watch,
  withHints = false,
  onFocus,
  isFormSubmitted,
}: PasswordInputProps) => {
  const classes = useStyles()

  const [passwordType, setPasswordType] = useState(true)
  const userPassword = watch && watch('password', '')

  const passwordHint = (copy: string, validation: (x: string) => boolean, id: string) => (
    <Typography
      variant="caption"
      id={`password-hint-${id}`}
      style={{
        display: 'flex',
        color: isFormSubmitted && !validation(userPassword) ? '#DF3011' : undefined,
      }}
    >
      {validation(userPassword) ? <CheckIcon fontSize="small" className={classes.check} /> : '-'}{' '}
      {copy}
    </Typography>
  )

  const inputRefOptions = register(
    withHints
      ? {
          required: 'This is a required field',
          minLength: 8,
          validate: {
            lowerCaseLetter: (value: string) =>
              hasLowerCaseLetter(value) || 'At least one lowercase letter',
            upperCaseLetter: (value: string) =>
              hasCapitalLetter(value) || 'At least one uppercase letter',
            specialChar: (value: string) =>
              hasSpecialCharacter(value) || 'At least one special character e.g (!,@,$)',
            number: (value: string) => hasNumber(value) || 'At least one number',
          },
          maxLength: {
            value: 128,
            message: 'Password needs to be less than 128 characters',
          },
        }
      : {
          required: 'This is a required field',
        },
  )

  return (
    <>
      <InputLabel required htmlFor="password" className={classes.label}>
        Password
      </InputLabel>
      {withHints && (
        <div className={classes.passwordHintsWrapper}>
          {passwordHint('Minimum 8 characters', (value: string) => value.length >= 8, 'length')}
          {passwordHint('At least one lowercase letter', hasLowerCaseLetter, 'lowercase')}
          {passwordHint('At least one uppercase letter', hasCapitalLetter, 'uppercase')}
          {passwordHint(
            'At least one special character e.g (!,@,$)',
            hasSpecialCharacter,
            'special',
          )}
          {passwordHint('At least one number', hasNumber, 'number')}
        </div>
      )}
      <TextField
        fullWidth
        name="password"
        type={passwordType ? 'password' : 'text'}
        id="password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setPasswordType(!passwordType)}
              >
                <EyeIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputRef={inputRefOptions}
        error={Boolean(errors.password)}
        style={{ marginTop: '-20px' }}
        onFocus={onFocus}
      />
      {!withHints && errors.password && (
        <FormHelperText error>{errors.password.message}</FormHelperText>
      )}
    </>
  )
}

export default PasswordInput

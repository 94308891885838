import React from 'react'
import dayjs from 'dayjs'
import { Box, FormHelperText, makeStyles, Typography } from '@material-ui/core'
import { Input } from '@gground/capcom.form'
import { useFormContext } from 'react-hook-form'
import type { ClientFormFields } from './types'
import { validateDateOfBirth } from './utils'

const useStyles = makeStyles((theme) => ({
  birthDate: {
    display: 'flex',
    '& > :not(:last-child)': {
      width: 50,
    },
    '& > :last-child': {
      width: 96,
    },
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
}))

const DateOfBirthFormFragment = () => {
  const { register, getValues, clearErrors, errors } = useFormContext<ClientFormFields>()
  const classes = useStyles()

  const now = dayjs()
  const minBirthYear = now.year() - 99
  const maxBirthYear = now.year() - 18

  const validate = () =>
    validateDateOfBirth(
      getValues('year_of_birth'),
      getValues('month_of_birth'),
      getValues('day_of_birth'),
    )

  return (
    <Box mt={4} component="section">
      <Typography variant="h2" style={{ fontWeight: 500 }}>
        Date of birth
      </Typography>
      <Box mt={2} className={classes.birthDate}>
        <Input
          type="number"
          inputProps={{ min: 1, max: 31 }}
          id="day_of_birth"
          name="day_of_birth"
          label="Day"
          error={!!errors.day_of_birth}
          onFocus={() => clearErrors()}
          ref={register({ validate, valueAsNumber: true })}
          fullWidth
        />
        <Input
          type="number"
          inputProps={{ min: 1, max: 12 }}
          id="month_of_birth"
          name="month_of_birth"
          label="Month"
          error={!!errors.month_of_birth}
          onFocus={() => clearErrors()}
          ref={register({ validate, valueAsNumber: true })}
          fullWidth
        />
        <Input
          type="number"
          inputProps={{ min: minBirthYear, max: maxBirthYear }}
          id="year_of_birth"
          name="year_of_birth"
          label="Year"
          error={!!errors.year_of_birth}
          onFocus={() => clearErrors()}
          ref={register({ validate, valueAsNumber: true })}
          fullWidth
        />
      </Box>
      {errors.day_of_birth ? (
        <FormHelperText error>{errors.day_of_birth?.message}</FormHelperText>
      ) : null}
    </Box>
  )
}

export default DateOfBirthFormFragment

import { useState, useEffect } from 'react'
import { getSourceOfFundsTypes } from 'src/api/utils'
import { showErrorToast } from 'src/utils/toasts'

interface Item {
  text: string
  value: string
}

interface SourceOfFunds {
  sourceOfFundsTypes: Item[]
}

interface SourceOfFundsItem {
  key: string
  label: string
}

const useSourceOfFundsTypes = (): SourceOfFunds => {
  const [sourceOfFundsTypes, setSourceOfFundsTypes] = useState<Item[]>([])

  useEffect(() => {
    getSourceOfFundsTypes()
      .then((res: SourceOfFundsItem[]) => {
        setSourceOfFundsTypes(
          res.map((TType: SourceOfFundsItem) => ({
            text: TType.label,
            value: TType.key,
          })),
        )
      })
      .catch((e) => {
        showErrorToast(`Can't fetch source of funds types`)
      })
  }, [])

  return { sourceOfFundsTypes }
}

export default useSourceOfFundsTypes

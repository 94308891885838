import { noTrailingWhitespace, lettersOnly, isEmail, isGreaterThanZero } from 'src/utils/validate'
import { stringToBoolean, booleanToString, poundsToPennies, penniesToPounds } from 'src/utils/misc'
import { PropertyPurchase } from 'src/types'
import dayjs from 'dayjs'

export const validateWhitespace = (value: string) =>
  noTrailingWhitespace(value) || 'Extra whitespace(s) is not allowed'

export const validateLettersOnly = (value: string) =>
  lettersOnly(value) || 'Remove invalid characters'

export const validateEmail = (value: string) =>
  value ? isEmail(value) || 'Please enter a valid email' : true

export const validateGreaterThanZero = (value: string | null | undefined) =>
  ![null, undefined].includes(value) ? isGreaterThanZero(value) : true

export const propertyPurchaseToForm = (propertyPurchase: PropertyPurchase) => {
  const form = {
    ...propertyPurchase.property_address?.address,
    postcode: propertyPurchase.property_address?.address.post_code,
    posttown: propertyPurchase.property_address?.address.post_town,
    is_new_build: booleanToString(propertyPurchase.property_address?.is_new_build),
    skipped: propertyPurchase.property_address?.skipped,
    ...propertyPurchase.purchase_details,
    ...propertyPurchase.solicitor_details,
    ...propertyPurchase.property_agent_details,
    is_owner_of_other_companies: [
      propertyPurchase?.tax_questions,
      propertyPurchase?.tax_questions?.is_owner_of_other_companies,
    ].includes(null)
      ? null
      : booleanToString(!!propertyPurchase.tax_questions?.is_owner_of_other_companies),
    using_mortgage:
      propertyPurchase.purchase_details === null
        ? null
        : booleanToString(!!propertyPurchase.purchase_details?.using_mortgage),
    assets_less_than_10m: [
      propertyPurchase?.tax_questions,
      propertyPurchase?.tax_questions?.is_owner_of_other_companies,
    ].includes(null)
      ? null
      : booleanToString(!!propertyPurchase?.tax_questions?.assets_less_than_10m),
    more_than_50_employees: [
      propertyPurchase?.tax_questions,
      propertyPurchase?.tax_questions?.is_owner_of_other_companies,
    ].includes(null)
      ? null
      : booleanToString(!!propertyPurchase?.tax_questions?.more_than_50_employees),
    turnover_less_than_10m: [
      propertyPurchase?.tax_questions,
      propertyPurchase?.tax_questions?.turnover_less_than_10m,
    ].includes(null)
      ? null
      : booleanToString(!!propertyPurchase?.tax_questions?.turnover_less_than_10m),
    estimated_monthly_rent: propertyPurchase?.purchase_details?.estimated_monthly_rent
      ? penniesToPounds(propertyPurchase.purchase_details.estimated_monthly_rent)
      : null,
    price_of_property: propertyPurchase.purchase_details?.price?.amount_in_cents
      ? penniesToPounds(propertyPurchase.purchase_details.price.amount_in_cents)
      : null,
    // Mapping has_property_agent TO no_property_agent for ease of using it in the form
    no_property_agent:
      propertyPurchase.property_agent_details === null
        ? null
        : !propertyPurchase.property_agent_details?.has_property_agent,
  }

  return form
}

export const formToPropertyPurchaseUpdate = (fields) => {
  // convert empty string and undefined values to null
  const sanitized = Object.entries(fields).reduce(
    (ac, [key, value]) => ({ [key]: ['', undefined].includes(value) ? null : value, ...ac }),
    {},
  )
  const {
    is_owner_of_other_companies,
    assets_less_than_10m,
    more_than_50_employees,
    turnover_less_than_10m,
    estimated_monthly_rent,
    price_of_property,
    source_of_funds_other_reason,
    skipped,
    ...rest
  } = sanitized

  const update = {
    property_address: {
      address: {
        premise: skipped ? 'TBC' : rest.premise,
        street: skipped ? 'TBC' : rest.street,
        post_town: skipped ? 'TBC' : rest.posttown,
        post_code: skipped ? 'TBC' : rest.postcode,
        country: skipped ? 'United Kingdom of Great Britain and Northern Ireland' : rest.country,
      },
      is_new_build: skipped ? true : stringToBoolean(rest.is_new_build),
      skipped,
    },
    purchase_details: {
      price: price_of_property
        ? {
            amount_in_cents: price_of_property ? poundsToPennies(price_of_property) : null,
            currency: 'GBP',
          }
        : null,
      completion_date: rest.completion_date
        ? dayjs(rest.completion_date).format('YYYY-MM-DD')
        : null,
      using_mortgage: rest.using_mortgage === null ? null : stringToBoolean(rest.using_mortgage),
      estimated_monthly_rent: estimated_monthly_rent
        ? poundsToPennies(estimated_monthly_rent)
        : null,
      source_of_funds: rest.source_of_funds,
      ...(source_of_funds_other_reason && { source_of_funds_other_reason }),
    },
    tax_questions: {
      is_owner_of_other_companies:
        is_owner_of_other_companies !== null ? stringToBoolean(is_owner_of_other_companies) : null,
      assets_less_than_10m: stringToBoolean(is_owner_of_other_companies)
        ? stringToBoolean(assets_less_than_10m)
        : null,
      more_than_50_employees: stringToBoolean(is_owner_of_other_companies)
        ? stringToBoolean(more_than_50_employees)
        : null,
      turnover_less_than_10m: stringToBoolean(is_owner_of_other_companies)
        ? stringToBoolean(turnover_less_than_10m)
        : null,
    },
    solicitor_details: {
      case_ref: rest.case_ref,
      deferred: rest.deferred || false,
      email: rest.email,
      find_solicitor: rest.find_solicitor,
      first_name: rest.first_name,
      last_name: rest.last_name,
      permission: false,
      phone_number: rest.deferred || rest.find_solicitor ? null : rest.phone_number,
    },
    property_agent_details: {
      has_property_agent: rest.email_address
        ? true
        : rest.no_property_agent === null
        ? null
        : !rest.no_property_agent,
      email_address: rest.no_property_agent ? null : rest.email_address,
    },
  }

  return update
}

import React from 'react'
import { makeStyles, Grid, Box } from '@material-ui/core'
import { Button } from '@gground/capcom.core'
import { Copy as CopyIcon } from '@gground/capcom.icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { showSuccessToast } from 'src/utils/toasts'

const useStyles = makeStyles((theme) => ({
  contentToEnd: {
    justifyContent: 'flex-end',
  },
  bold: {
    fontWeight: 500,
  },
  itemWrapper: {
    display: 'contents',
    '&:nth-child(odd) .MuiGrid-item': {
      backgroundColor: '#F9F9FA',
    },
    '& .MuiGrid-item': {
      padding: theme.spacing(1),
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

interface Props {
  label: string
  value: string
}

const AccountTableRow = ({ label, value }: Props) => {
  const classes = useStyles()

  return (
    <Box className={classes.itemWrapper}>
      <Grid item xs={3}>
        {label}:
      </Grid>
      <Grid item xs={7} className={classes.bold}>
        <span className={classes.truncate}>{value}</span>
      </Grid>
      <Grid item xs={2} className={classes.contentToEnd}>
        <CopyToClipboard text={value} onCopy={() => showSuccessToast(`${label} copied`)}>
          <Button variant="text" startIcon={<CopyIcon />}>
            Copy
          </Button>
        </CopyToClipboard>
      </Grid>
    </Box>
  )
}

export default AccountTableRow

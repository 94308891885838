import React from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import Layout from 'src/components/Layout'

const Invite = () => {
  const [params] = useSearchParams()
  const signUpEmail = params.get('email')
  const signUpCode = params.get('sign_up_code')

  return (
    <Layout>
      <Navigate to="/sign-up" state={{ signUpEmail, signUpCode }} replace />
    </Layout>
  )
}

export default Invite

import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const BoxIcon = (props: SvgIconProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props} viewBox="0 0 40 40">
    <path
      d="M34.4476 30.289L20 38.2224L5.55237 30.289V14.4226L20 6.48926L34.4476 14.4226V30.289Z"
      fill="#E2AF74"
    />
    <path
      opacity="0.3"
      d="M5.55237 14.4226V14.4229L20 22.3563L34.4476 14.4229V14.4226L20 6.48926L5.55237 14.4226Z"
      fill="#9C6321"
    />
    <path
      opacity="0.5"
      d="M5.55237 14.4275V30.289L20 38.2224L20.0085 38.2178V22.356L5.56091 14.4226L5.55237 14.4275Z"
      fill="#F6E7D5"
    />
    <path
      d="M34.4476 14.4229L39.2188 19.1937L24.7711 27.127L20 22.3559L34.4476 14.4229Z"
      fill="#F6E7D5"
    />
    <path
      d="M5.55237 14.4229L0.78125 19.1937L15.2289 27.127L20 22.3559L5.55237 14.4229Z"
      fill="#F6E7D5"
    />
    <path
      d="M34.4476 14.4229L39.2188 9.6521L24.7711 1.71875L20 6.48987L34.4476 14.4229Z"
      fill="#F6E7D5"
    />
    <path
      d="M5.55237 14.4229L0.78125 9.6521L15.2289 1.71875L20 6.48987L5.55237 14.4229Z"
      fill="#F6E7D5"
    />
    <path
      d="M35.5527 14.4229L39.7712 10.2045C39.9451 10.0309 40.0269 9.78519 39.9921 9.54196C39.9576 9.29874 39.8102 9.08573 39.5948 8.96732L34.5234 6.18259C34.1453 5.97507 33.6704 6.11331 33.4626 6.49143C33.2551 6.86984 33.393 7.3447 33.7714 7.55222L37.9304 9.83585L34.3101 13.4558L21.2891 6.30588L24.9091 2.68588L28.5685 4.69547C28.9466 4.90299 29.4217 4.76505 29.6293 4.38663C29.8371 4.00852 29.6988 3.53336 29.3204 3.32584L25.1472 1.03397C24.8426 0.866731 24.4642 0.920747 24.2188 1.16641L20.0001 5.38516L15.7813 1.16641C15.5356 0.920747 15.1572 0.867036 14.853 1.03397L0.405331 8.96732C0.189877 9.08573 0.0424768 9.29843 0.0079919 9.54196C-0.0267981 9.78519 0.054989 10.0309 0.228939 10.2045L4.44738 14.4229L0.228939 18.6411C0.054989 18.815 -0.0267981 19.0604 0.0079919 19.3039C0.0424768 19.5472 0.189877 19.7602 0.405331 19.8783L4.77118 22.2757V30.289C4.77118 30.5744 4.92651 30.8368 5.17645 30.9742L19.6241 38.9072C19.7413 38.9716 19.8707 39.0036 20.0001 39.0036C20.1295 39.0036 20.2588 38.9716 20.376 38.9072L34.8237 30.9742C35.0736 30.8368 35.2289 30.5744 35.2289 30.289V22.2757L39.5948 19.8786C39.8102 19.7602 39.9576 19.5472 39.9921 19.3039C40.0269 19.0607 39.9451 18.815 39.7712 18.6414L35.5527 14.4229ZM20.0001 21.4646L7.17566 14.4226L20.0001 7.3804L32.8245 14.4226L20.0001 21.4646ZM15.0913 2.68588L18.711 6.30588L5.69006 13.4558L2.07006 9.83585L15.0913 2.68588ZM5.69067 15.3897L18.7116 22.5397L15.0913 26.16L2.07006 19.01L5.69067 15.3897ZM33.6664 29.827L20.7813 36.9022V28.916C20.7813 28.4845 20.4316 28.1348 20.0001 28.1348C19.5685 28.1348 19.2188 28.4845 19.2188 28.916V36.9022L6.33368 29.827V23.1339L14.853 27.8119C14.9711 27.8766 15.1005 27.9084 15.2286 27.9084C15.431 27.9084 15.6312 27.8296 15.7813 27.6795L20.0001 23.4607L24.2188 27.6795C24.369 27.8299 24.5691 27.9084 24.7715 27.9084C24.8997 27.9084 25.029 27.8766 25.1472 27.8119L33.6664 23.1339V29.827ZM24.9091 26.16L21.2888 22.5394L34.3097 15.3897L37.9304 19.0097L24.9091 26.16Z"
      fill="#1F1F33"
    />
    <path
      d="M31.625 6.26367C31.8304 6.26367 32.0312 6.18005 32.1774 6.03448C32.3226 5.88922 32.4062 5.68781 32.4062 5.48242C32.4062 5.27673 32.3226 5.07532 32.1774 4.93005C32.0321 4.78448 31.8304 4.70117 31.625 4.70117C31.4187 4.70117 31.2179 4.78448 31.0726 4.93005C30.9265 5.07532 30.8438 5.27673 30.8438 5.48242C30.8438 5.68781 30.9265 5.88922 31.0726 6.03448C31.2179 6.18005 31.4196 6.26367 31.625 6.26367Z"
      fill="black"
    />
    <path
      d="M20 25.541C19.7946 25.541 19.5929 25.6243 19.4476 25.7699C19.3024 25.9158 19.2188 26.1166 19.2188 26.3223C19.2188 26.5283 19.3024 26.7291 19.4476 26.8752C19.5929 27.0205 19.7946 27.1035 20 27.1035C20.2054 27.1035 20.4071 27.0205 20.5524 26.8752C20.6976 26.7291 20.7812 26.5283 20.7812 26.3223C20.7812 26.1166 20.6976 25.9158 20.5524 25.7699C20.4071 25.6243 20.2054 25.541 20 25.541Z"
      fill="black"
    />
  </SvgIcon>
)

export default BoxIcon

import React from 'react'
import { createTheme } from '@material-ui/core/styles'

import CheckboxIcon from 'src/icons/CheckboxIcon'
import CheckboxCheckedIcon from 'src/icons/CheckboxCheckedIcon'

const primaryColor = '#2A6574'
const secondaryColor = '#9CA7B4'
const successColor = '#53C163'
const successLightColor = '#BAF2C7'
const errorColor = '#DF3011'
const infoColor = '#1C444E'
const lightGreyColor = '#F9F9FA'

const successLightTextColor = '#163C1B'
const errorLightColor = '#FACAC1'
const errorLightTextColor = '#4D1005'
const warningLightColor = '#FFEC99'
const warningLightTextColor = '#5C3E00'
const infoLightColor = '#E8F4F7'

const defaultTheme = createTheme()

const capcomTheme = createTheme({
  palette: {
    primary: { main: primaryColor },
    secondary: { main: secondaryColor },
    success: { main: successColor, light: successLightColor },
    error: { main: errorColor },
    text: { primary: '#1F1F33', disabled: '#C8D1DE' },
    info: { main: infoColor },
  },
  typography: {
    fontFamily: 'post-grotesk, sans-serif',
    h1: {
      fontSize: '32px',
      fontWeight: 'bold',
      lineHeight: '150%',
      color: infoColor,
    },
    h2: { fontSize: '24px', fontWeight: 'bold', lineHeight: '150%' },
    h3: { fontSize: '16px', fontWeight: '500', lineHeight: '150%' },
    h6: { fontSize: '1rem', fontWeight: '500', lineHeight: '150%' },
    subtitle1: { fontSize: '12px', lineHeight: '150%' },
    subtitle2: { fontSize: '14px', fontWeight: 'bold' },
    body1: { fontSize: '14px' },
    body2: { lineHeight: '125%' },
    caption: { fontSize: '10px', lineHeight: '100%', color: '#4E545F' },
  },
  props: {
    MuiButton: { color: 'primary', disableRipple: true },
    MuiFab: { disableRipple: true },
    MuiTooltip: { arrow: true },
    MuiLink: { underline: 'always' },
    MuiTextField: {
      variant: 'outlined',
      size: 'small',
      fullWidth: true,
      InputProps: { required: false },
    },
    MuiOutlinedInput: { notched: false },
    MuiSelect: {
      variant: 'outlined',
      required: false,
      inputProps: { size: 'small' },
      MenuProps: {
        variant: 'menu',
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      },
    },
    MuiInputLabel: { shrink: false },
    MuiCheckbox: {
      disableRipple: true,
      icon: <CheckboxIcon />,
      checkedIcon: <CheckboxCheckedIcon />,
    },
    MuiRadio: {
      disableRipple: true,
      color: 'primary',
    },
    MuiAlert: {
      icon: false,
    },
  },
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#2A6574',
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        padding: '5px 16px',
        '&$disabled': {
          background: '#DEE0E3',
          backgroundColor: '#DEE0E3',
          color: '#4E545F',
          pointerEvents: 'none',
          cursor: 'not-allowed',
          '&:hover': {
            background: '#DEE0E3',
            backgroundColor: '#DEE0E3',
          },
        },
        '@media (max-width:600px)': {
          padding: defaultTheme.spacing(1),
          width: '100%',
        },
      },
      contained: { boxShadow: 'none' },
      outlinedPrimary: {
        background: '#FFFFFF',
        '&:hover': {
          backgroundColor: '#FFFFFF',
        },
      },
      outlinedSecondary: {
        background: '#F7F7F8',
        color: '#2A6574',
        borderColor: '#2A6574',
        '&:hover': {
          backgroundColor: '#F7F7F8',
        },
      },
      containedSecondary: {
        background: 'transparent',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#E8F4F7',
          boxShadow: 'none',
        },
      },
    },
    MuiFab: {
      root: {
        minHeight: '24px',
        backgroundColor: '#F9F9FA',
        boxShadow: '0px 1px 1px rgba(97, 97, 97, 0.2), 0px 0px 2px rgba(97, 97, 97, 0.2)',
      },
      sizeSmall: { width: '24px', height: '24px' },
    },
    MuiSvgIcon: { fontSizeSmall: { fontSize: '12px' } },
    MuiTooltip: {
      tooltip: {
        backgroundColor: primaryColor,
        padding: '8px 12px',
        fontSize: '14px',
        fontWeight: 'normal',
      },
      arrow: { color: primaryColor, fontSize: '10px' },
    },
    MuiLink: { underlineAlways: { color: '#006BC2' } },
    MuiTableContainer: { root: { maxHeight: '100vh', background: '#DEE0E3' } },
    MuiTableRow: {
      root: {
        backgroundColor: '#F9F9FA',
        '&:nth-child(2n)': { backgroundColor: 'white' },
      },
    },
    MuiTableCell: {
      root: {
        padding: '11px 16px',
        maxWidth: '315px',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        '&:first-child': { paddingLeft: '24px' },
      },
      head: {
        padding: '11px 16px',
        color: secondaryColor,
        fontWeight: 'normal',
        lineHeight: '125%',
        background: '#FFFFFF',
      },
      stickyHeader: { backgroundColor: 'white', left: 'auto' },
      body: { borderBottom: 'none' },
    },
    MuiLinearProgress: {
      root: { height: '10px', width: '40px', borderRadius: '20px' },
      colorPrimary: { backgroundColor: '#DEE0E3' },
      barColorPrimary: { backgroundColor: successColor },
    },
    MuiChip: {
      root: {
        backgroundColor: successLightColor,
        fontWeight: '500',
        color: '#163C1B',
      },
    },
    MuiCard: {
      root: { margin: defaultTheme.spacing(1, 0) },
    },
    MuiCardHeader: {
      root: {
        padding: defaultTheme.spacing(0, 2),
        height: 56,
        backgroundColor: lightGreyColor,
        borderBottom: 'solid 1px #DEE0E3',
        color: infoColor,
      },
      action: {
        marginTop: 0,
        marginRight: 0,
        alignSelf: 'unset',
      },
    },
    MuiCardContent: {
      root: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiFormControl: {
      root: {
        '& .MuiFormHelperText-contained': {
          position: 'absolute',
          marginTop: 18,
          fontSize: 10,
        },
      },
      marginNormal: {
        marginTop: 0,
        marginBottom: 0,
        '& .MuiInputBase-root': {
          marginTop: 33,
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: '#1F1F33',
        '&$error': { color: '#1F1F33' },
        '&$focused': { color: '#1F1F33' },
      },
    },
    MuiInput: { root: { marginTop: 20 } },
    MuiInputBase: {
      root: {
        marginTop: 20,
        backgroundColor: 'white',
        '&$disabled': { color: '#4E545F', backgroundColor: '#F9F9FA' },
      },
    },
    MuiInputLabel: {
      formControl: { transform: 'none' },
      marginDense: { transform: 'none' },
      outlined: { '&$marginDense': { transform: 'none' } },
      asterisk: { color: errorColor },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline, &$focused $notchedOutline': {
          borderColor: '#98B18B',
        },
        '@media (max-width:600px)': {
          padding: defaultTheme.spacing(1),
        },
      },
      input: {
        padding: defaultTheme.spacing(1),
      },
      inputMarginDense: {
        borderRadius: '4px',
        paddingTop: '7px',
        paddingBottom: '7px',
      },
      notchedOutline: {
        borderColor: secondaryColor,
      },
      adornedStart: {
        paddingLeft: '11px',
      },
      adornedEnd: {
        paddingRight: defaultTheme.spacing(1),
      },
    },
    MuiSelect: {
      select: { '&$disabled': { opacity: 0.5 } },
      icon: { '&$disabled': { display: 'none' } },
    },
    MuiFormHelperText: {
      root: { fontSize: 12, marginTop: 4 },
      contained: {
        marginLeft: 0,
        '&$error': { color: 'inherit' },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: defaultTheme.spacing(2, 2, 1),
        position: 'relative',
        color: infoColor,
        fontSize: 16,
      },
    },
    MuiDialogContent: { root: { padding: defaultTheme.spacing(0, 2, 1) } },
    MuiDialogActions: { root: { padding: defaultTheme.spacing(1, 0, 1) } },
    MuiPaper: {
      root: { backgroundColor: lightGreyColor },
      elevation1: { boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)' },
      elevation2: {
        boxShadow: '0px 1px 1px rgba(97, 97, 97, 0.2), 0px 0px 2px rgba(97, 97, 97, 0.2)',
      },
      elevation3: {
        boxShadow: '0px 2px 4px rgba(47, 84, 114, 0.25)',
      },
    },
    MuiIconButton: { root: { padding: 0 } },
    MuiAlert: {
      root: {
        margin: 0,
        padding: defaultTheme.spacing(2),
        borderRadius: 4,
        fontWeight: 500,
        lineHeight: 1.6,
      },
      message: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: 0,
      },
      standardError: {
        backgroundColor: errorLightColor,
        color: errorLightTextColor,
      },
      standardWarning: {
        backgroundColor: warningLightColor,
        color: warningLightTextColor,
      },
      standardInfo: {
        backgroundColor: infoLightColor,
        color: infoColor,
      },
      standardSuccess: {
        backgroundColor: successLightColor,
        color: successLightTextColor,
      },
    },
    MuiStepper: {
      root: {
        padding: 0,
      },
    },
    MuiStep: {
      horizontal: {
        paddingLeft: 0,
        paddingRight: 0,
        '& .MuiStepLabel-iconContainer': {
          paddingRight: 0,
        },
      },
      root: {
        '& ul': {
          paddingInlineStart: `${defaultTheme.spacing(2)}px`,
          marginBlockStart: `${defaultTheme.spacing(1)}px`,
          marginBlockEnd: `${defaultTheme.spacing(1)}px`,
        },
        '& li': {
          padding: defaultTheme.spacing(0.5, 2),
          lineHeight: '21px',
          fontSize: 14,
          textDecoration: 'underline',
          color: '#4E545F',
          fontWeight: 400,
          '&::marker': {
            fontSize: 11,
            color: '#9CA7B4',
            content: '"◯"',
          },
          '&.active': {
            color: '#1F1F33',
            '&:hover': {
              cursor: 'pointer',
            },
            '&::marker': {
              content: '"●"',
            },
          },
        },
        '&.expanded + .MuiStepConnector-vertical': {
          display: 'none',
        },
      },
    },
    MuiStepLabel: {
      label: {
        fontWeight: 500,
      },
      completed: {
        color: '#53C163',
      },
      labelContainer: {
        [defaultTheme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },
    },
    MuiStepIcon: {
      root: {
        color: '#E8F4F7',
        '&.MuiStepIcon-completed': {
          color: '#53C163',
        },
        '&.MuiStepIcon-active': {
          color: '#96CCD9',
          '& .MuiStepIcon-text': {
            color: 'white',
            fill: 'white',
          },
        },
      },
      text: {
        color: '#2A6574',
        fill: '#2A6574',
        fontSize: 12,
      },
    },
    MuiStepConnector: {
      vertical: {
        padding: 0,
      },
      line: {
        borderColor: '#E8F4F7',
      },
      lineVertical: {
        minHeight: defaultTheme.spacing(2),
        borderLeftWidth: 2,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
      },
    },
  },
})

export default capcomTheme

import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Typography, Box, makeStyles, RadioGroup, FormHelperText } from '@material-ui/core'
import { Callout } from '@gground/capcom.core'
import { Radio } from '@gground/capcom.form'

import { stringToBoolean } from 'src/utils/misc'

const useStyles = makeStyles((theme) => ({
  subTitle: {
    color: theme.palette.info.main,
  },
}))

const TaxQuestionsFormFragment = () => {
  const classes = useStyles()
  const { control, errors, watch } = useFormContext()

  const hasMoreCompanies = stringToBoolean(watch('is_owner_of_other_companies'))

  return (
    <Box component="section">
      <Box mt={4}>
        <Typography variant="h2" className={classes.subTitle}>
          Tax Questions
        </Typography>
        <Box mt={2}>
          <Callout severity="info">
            The money you provide to purchase your property is lent by you to your company. We
            create a loan note between yourself and your company for this. This question determines
            the interest rate we should apply to your loan to the company
          </Callout>
        </Box>
        <Box mt={2}>
          <Typography>
            Do they have ownership or voting rights of 25% or more in any companies, partnerships or
            other enterprises? (This does not include companies you have set up with GetGround)
          </Typography>

          <Controller
            control={control}
            name="is_owner_of_other_companies"
            defaultValue={hasMoreCompanies}
            as={
              <RadioGroup>
                <Radio
                  name="is_owner_of_other_companies"
                  value="no"
                  label="No"
                  error={Boolean(errors.is_owner_of_other_companies)}
                />
                <Radio
                  name="is_owner_of_other_companies"
                  value="yes"
                  label="Yes"
                  error={Boolean(errors.is_owner_of_other_companies)}
                />
              </RadioGroup>
            }
          />
          {errors?.is_owner_of_other_companies && (
            <FormHelperText error>{errors.is_owner_of_other_companies?.message}</FormHelperText>
          )}
        </Box>
        {hasMoreCompanies && (
          <>
            <Box mt={2}>
              <Typography>Do they employ more than 50 employees combined?</Typography>

              <Controller
                name="more_than_50_employees"
                control={control}
                rules={{
                  required: hasMoreCompanies ? 'This field is required' : false,
                }}
                as={
                  <RadioGroup>
                    <Radio
                      name="more_than_50_employees"
                      value="no"
                      label="No"
                      error={Boolean(errors?.more_than_50_employees)}
                    />
                    <Radio
                      name="more_than_50_employees"
                      value="yes"
                      label="Yes"
                      error={Boolean(errors?.more_than_50_employees)}
                    />
                  </RadioGroup>
                }
              />
              {errors?.more_than_50_employees && (
                <FormHelperText error>{errors.more_than_50_employees?.message}</FormHelperText>
              )}
            </Box>

            <Box mt={2}>
              <Typography>Do they have assets of &euro;10m or less combined?</Typography>

              <Controller
                name="assets_less_than_10m"
                control={control}
                rules={{
                  required: hasMoreCompanies ? 'This field is required' : false,
                }}
                as={
                  <RadioGroup>
                    <Radio
                      name="assets_less_than_10m"
                      value="no"
                      label="No"
                      error={Boolean(errors?.assets_less_than_10m)}
                    />
                    <Radio
                      name="assets_less_than_10m"
                      value="yes"
                      label="Yes"
                      error={Boolean(errors?.assets_less_than_10m)}
                    />
                  </RadioGroup>
                }
              />
              {errors?.assets_less_than_10m && (
                <FormHelperText error>{errors.assets_less_than_10m?.message}</FormHelperText>
              )}
            </Box>

            <Box mt={2}>
              <Typography>Do they have a turnover of &euro;10m or less combined?</Typography>

              <Controller
                name="turnover_less_than_10m"
                control={control}
                rules={{
                  required: hasMoreCompanies ? 'This field is required' : false,
                }}
                as={
                  <RadioGroup>
                    <Radio
                      name="turnover_less_than_10m"
                      value="no"
                      label="No"
                      error={Boolean(errors?.turnover_less_than_10m)}
                    />
                    <Radio
                      name="turnover_less_than_10m"
                      value="yes"
                      label="Yes"
                      error={Boolean(errors?.turnover_less_than_10m)}
                    />
                  </RadioGroup>
                }
              />
              {errors?.turnover_less_than_10m && (
                <FormHelperText error>{errors.turnover_less_than_10m?.message}</FormHelperText>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}

export default TaxQuestionsFormFragment

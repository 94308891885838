import React from 'react'
import { CopyToClipboard as ReactCopyToClipboard } from 'react-copy-to-clipboard'
import { makeStyles } from '@material-ui/core'

import { showInfoToast } from 'src/utils/toasts'

const useStyles = makeStyles(() => ({
  link: {
    color: '#006bc2',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))

interface CopyToClipboardProps {
  text?: string
  children?: React.ReactNode
}

const CopyToClipboard = ({ text, children }: CopyToClipboardProps) => {
  const classes = useStyles()

  return (
    <ReactCopyToClipboard text={text} onCopy={() => showInfoToast('Copied to clipboard')}>
      {children || <span className={classes.link}>{text}</span>}
    </ReactCopyToClipboard>
  )
}

export default CopyToClipboard

import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { makeStyles, Typography, Box } from '@material-ui/core'
import { MirroredInput } from '@gground/capcom.form'
import { Button, Callout } from '@gground/capcom.core'

import { useAppDispatch, useAppSelector } from 'src/store'
import CopyToClipboard from 'src/components/CopyToClipboard'
import Layout from 'src/components/Layout'
import { resendSMSCode, verify2FA } from 'src/store/slices/user.slice'
import { showErrorToast } from 'src/utils/toasts'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(8, 'auto', 0),
    maxWidth: 600,
    width: '100%',
  },
  bottomLink: {
    marginTop: 124,
  },
  resend_code: {
    color: '#006BC2',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))

const SignIn2FA = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { phone_number, method } = useAppSelector((state) => state.user.auth)
  const { handleSubmit, register, errors, clearErrors, control } = useForm({
    shouldFocusError: false,
    reValidateMode: 'onSubmit',
  })

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    control.fieldsRef.current.otp?.ref.focus?.()
  }, [control])

  const submit = ({ otp }: { otp: string }) =>
    dispatch(verify2FA({ otp }))
      .unwrap()
      .then(() => navigate('/dashboard'))
      .catch(() => showErrorToast('Failed to validate 2FA'))

  const resendCode = () => dispatch(resendSMSCode())

  return (
    <Layout>
      <Box className={classes.container}>
        <form onSubmit={handleSubmit(submit)}>
          <Typography variant="h1">2-Step Verification</Typography>
          <Box mt={4} mb={4}>
            <Callout severity="info">
              Please enter the OTP number from{' '}
              {method === 'totp'
                ? 'your Google Authenticator app to continue.'
                : 'the SMS you will receive.'}
            </Callout>
          </Box>
          {method === 'sms' && (
            <Box mb={2}>
              <Typography variant="h3">{phone_number}</Typography>
            </Box>
          )}
          <Box>
            <MirroredInput
              name="otp"
              id="otp"
              label="OTP Number"
              type="otp"
              inputProps={{
                hint: `The 6 digit number you will ${
                  method === 'totp'
                    ? 'find in your Google Authenticator app'
                    : 'receive in an SMS text message'
                }`,
                helperText: errors.otp && errors.otp?.message,
                ref: register({
                  required: 'This field is required',
                  minLength: {
                    value: 6,
                    message: 'Min 6 characters',
                  },
                }),
              }}
              error={errors.otp}
              onFocus={() => clearErrors()}
            />
          </Box>
          <Box mt={2}>
            <Button type="submit">Continue</Button>
          </Box>
          {method === 'sms' && (
            <Box my={4}>
              <Typography>
                Didn’t receive your code?{' '}
                <span className={classes.resend_code} onClick={resendCode}>
                  Resend code
                </span>
                .
              </Typography>
            </Box>
          )}
          <Typography className={classes.bottomLink}>
            Need help? You can email <CopyToClipboard text="gethelp@getground.co.uk" />
          </Typography>
        </form>
      </Box>
    </Layout>
  )
}

export default SignIn2FA

import React, { useEffect, useState } from 'react'
import { makeStyles, Box, Typography } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/store'
import { showErrorToast } from 'src/utils/toasts'
import { createClient } from 'src/store/slices/clients.slice'
import { trackSnowplow } from 'src/store/slices/user.slice'
import Layout from 'src/components/Layout'
import { fetchPartnerOverview } from 'src/store/slices/partner.slice'
import { ClientOverview } from 'src/types'
import { CreateClientOptions } from 'src/api/clients'
import { ErrorCode } from 'src/utils/errorCode'
import AddCompanyButton from './AddCompanyButton'
import ClientsList from './ClientsList'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerContainer: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    borderBottom: '1px solid #DEE0E3',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: 1008,
    margin: theme.spacing(4, 4, 2),
  },
  statsContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  stats: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: theme.spacing(1),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 1008,
    margin: theme.spacing(4),
    width: `calc(100% - ${theme.spacing(8)}px)`,
  },
}))

const getStats = (clients: ClientOverview[]) => {
  let active = 0
  let inProgress = 0

  clients.forEach((client) => {
    if (client.property_purchases.length === 0) {
      inProgress += 1
    }

    client.property_purchases.forEach((propertyPurchase) => {
      active += propertyPurchase.has_business_account ? 1 : 0
      inProgress += propertyPurchase.has_business_account ? 0 : 1
      const subsidiaries = propertyPurchase.subsidiaries ?? []

      subsidiaries.forEach((subsidiary) => {
        active += subsidiary.has_business_account ? 1 : 0
        inProgress += subsidiary.has_business_account ? 0 : 1
      })
    })
  })

  return { clients: clients.length, active, inProgress }
}

const Stat = ({ value, label }: { value: number; label: string }) => (
  <Box display="flex" alignItems="baseline">
    <Box mr={1}>
      <Typography variant="h2">{value}</Typography>
    </Box>
    <Typography variant="h3" style={{ fontWeight: 400 }}>
      {label}
    </Typography>
  </Box>
)

const Divider = () => <Box mx={2} border="1px #DEE0E3 solid" />

const Dashboard = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const clients = useAppSelector((state) => state.partner.clients)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    dispatch(trackSnowplow({ category: 'dashboard', action: 'dashboard_visit' }))
    dispatch(fetchPartnerOverview())
      .unwrap()
      .then(() => setLoading(false))
      .catch(() => {
        showErrorToast('Failed fetch overview data.')
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const stats = getStats(clients)

  const handleCreateClient = (data: CreateClientOptions) => {
    dispatch(createClient(data))
      .unwrap()
      .then(({ id }) => navigate(`/clients/${id}`))
      .catch(({ error_code }: any) => {
        const message =
          error_code === ErrorCode.CLIENT_ALREADY_EXISTS
            ? 'This email is already associated with an existing client.'
            : 'Failed to create client. Please try again.'
        showErrorToast(message)
      })
  }

  const handleAddToClient = (clientId: number) => {
    navigate(`/clients/${clientId}/companies`)
  }

  return (
    <Layout fullWidth>
      <Box className={classes.container}>
        <Box className={classes.headerContainer}>
          <Box className={classes.header}>
            <Typography variant="h1">Companies</Typography>
            <Box className={classes.statsContainer}>
              <Box className={classes.stats}>
                <Stat value={stats.clients} label="Clients" />
                <Divider />
                <Stat value={stats.active} label="Active company" />
                <Divider />
                <Stat value={stats.inProgress} label="Companies in progress" />
              </Box>
              <AddCompanyButton
                clients={clients}
                disabled={loading}
                onAddToClient={handleAddToClient}
                onCreateClient={handleCreateClient}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.content}>
          <ClientsList clients={clients} loading={loading} />
        </Box>
      </Box>
    </Layout>
  )
}

export default Dashboard

export const CURRENCY_SYMBOLS = [
  {
    shorthand: 'AUD',
    symbol: '$',
  },
  {
    shorthand: 'OMR',
    symbol: 'ر.ع.',
  },
  {
    shorthand: 'BHD',
    symbol: 'BD',
  },
  {
    shorthand: 'PLN',
    symbol: 'zł',
  },
  {
    shorthand: 'BGN',
    symbol: 'Лв.',
  },
  {
    shorthand: 'QAR',
    symbol: 'QR',
  },
  {
    shorthand: 'CAD',
    symbol: '$',
  },
  {
    shorthand: 'RON',
    symbol: 'Lei',
  },
  {
    shorthand: 'HRK',
    symbol: 'kn',
  },
  {
    shorthand: 'RUB',
    symbol: '₽',
  },
  {
    shorthand: 'CZK',
    symbol: 'Kč',
  },
  {
    shorthand: 'SAR',
    symbol: 'SR',
  },
  {
    shorthand: 'DKK',
    symbol: 'Kr.',
  },
  {
    shorthand: 'SGD',
    symbol: '$',
  },
  {
    shorthand: 'EUR',
    symbol: '€',
  },
  {
    shorthand: 'ZAR',
    symbol: 'R',
  },
  {
    shorthand: 'HKD',
    symbol: '$',
  },
  {
    shorthand: 'SEK',
    symbol: 'kr',
  },
  {
    shorthand: 'HUF',
    symbol: 'Ft',
  },
  {
    shorthand: 'CHF',
    symbol: 'CHf',
  },
  {
    shorthand: 'ILS',
    symbol: '₪',
  },
  {
    shorthand: 'THB',
    symbol: '฿',
  },
  {
    shorthand: 'JPY',
    symbol: '¥',
  },
  {
    shorthand: 'TRY',
    symbol: '₺',
  },
  {
    shorthand: 'KES',
    symbol: 'Ksh',
  },
  {
    shorthand: 'UGX',
    symbol: 'USh',
  },
  {
    shorthand: 'KWD',
    symbol: 'KD',
  },
  {
    shorthand: 'GBP',
    symbol: '£',
  },
  {
    shorthand: 'MXN',
    symbol: '$',
  },
  {
    shorthand: 'AED',
    symbol: 'د.إ',
  },
  {
    shorthand: 'NZD',
    symbol: '$',
  },
  {
    shorthand: 'USD',
    symbol: '$',
  },
  {
    shorthand: 'NOK',
    symbol: 'kr',
  },
] as const

import React from 'react'
import { Box, MenuItem, Typography } from '@material-ui/core'
import { Input, Select } from '@gground/capcom.form'
import { Controller, useFormContext } from 'react-hook-form'
import { countries } from 'src/constants'
import { validateNoSpecialCharacter } from './utils'
import type { ClientFormFields } from './types'

const NationalityFormFragment = () => {
  const { register, clearErrors, control, errors } = useFormContext<ClientFormFields>()

  return (
    <Box mt={4} component="section">
      <Typography variant="h2" style={{ fontWeight: 500 }}>
        Nationality
      </Typography>
      <Box mt={2}>
        <Controller
          name="nationality"
          control={control}
          defaultValue=""
          render={({ onChange, value }) => (
            <Select
              id="nationality"
              name="nationality"
              label="Nationality"
              value={value}
              onChange={onChange}
              fullWidth
            >
              {Object.values(countries).map(({ nationality, country_name, id }) => (
                <MenuItem key={id} value={id}>
                  {`${nationality} - ${country_name}`}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Box>
      <Box mt={2}>
        <Input
          type="text"
          id="birth_town"
          name="birth_town"
          label="City of birth"
          helperText={errors.birth_town && errors.birth_town?.message}
          error={!!errors.birth_town}
          onFocus={() => clearErrors()}
          ref={register({ validate: validateNoSpecialCharacter })}
          fullWidth
        />
      </Box>
    </Box>
  )
}

export default NationalityFormFragment

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as CompaniesAPI from 'src/api/companies'
import type {
  FetchBusinessAccountBalancePayload,
  FetchBusinessAccountTransactionsPayload,
  FetchBusinessAccountDetailsPayload,
  DownloadBusinessAccountTransactionsPayload,
  FetchDocumentsPayload,
  FetchDocumentLinksPayload,
  DownloadDocumentsPayload,
  FetchCompanyDetailsPayload,
  DeleteDocumentsPayload,
  UploadDocumentPayload,
  EditDocumentsPayload,
} from 'src/api/companies'
import type {
  CompanyTransactions,
  AvailableBalance,
  Transaction,
  CompanyAccountDetails,
  Document,
  StorageFileInfo,
  CompanyDetails,
} from 'src/types'

interface CompanyState {
  wallet: {
    available_balance_in_cents: number
    transactions: [Transaction] | []
    details: CompanyAccountDetails | null
  }
  documents: Document[]
  details: CompanyDetails | null
}

const initialState: CompanyState = {
  wallet: {
    available_balance_in_cents: 0,
    transactions: [],
    details: null,
  },
  documents: [],
  details: null,
}

const fetchBusinessAccountBalance = createAsyncThunk<
  AvailableBalance,
  FetchBusinessAccountBalancePayload
>('company/fetchBusinessAccountBalance', CompaniesAPI.fetchBusinessAccountBalance)

const fetchBusinessAccountTransactions = createAsyncThunk<
  CompanyTransactions,
  FetchBusinessAccountTransactionsPayload
>('company/fetchBusinessAccountTransactions', CompaniesAPI.fetchBusinessAccountTransactions)

const fetchBusinessAccountDetails = createAsyncThunk<
  CompanyAccountDetails,
  FetchBusinessAccountDetailsPayload
>('company/fetchBusinessAccountDetails', CompaniesAPI.fetchBusinessAccountDetails)

const downloadBusinessAccountTransactions = createAsyncThunk<
  void,
  DownloadBusinessAccountTransactionsPayload
>('company/downloadBusinessAccountTransactions', CompaniesAPI.downloadBusinessAccountTransactions)

const updateTransactionCategory = createAsyncThunk(
  'company/updateTransactionCategory',
  CompaniesAPI.updateTransactionCategory,
)

const fetchDocuments = createAsyncThunk<Document[], FetchDocumentsPayload>(
  'company/fetchDocuments',
  CompaniesAPI.fetchDocuments,
)

const fetchDocumentLinks = createAsyncThunk<StorageFileInfo[], FetchDocumentLinksPayload>(
  'company/fetchDocumentLinks',
  CompaniesAPI.fetchDocumentLinks,
)

const downloadDocuments = createAsyncThunk<void, DownloadDocumentsPayload>(
  'company/downloadDocuments',
  CompaniesAPI.downloadDocuments,
)

const fetchCompanyDetails = createAsyncThunk<CompanyDetails, FetchCompanyDetailsPayload>(
  'company/fetchCompanyDetails',
  CompaniesAPI.fetchCompanyDetails,
)

const deleteDocuments = createAsyncThunk<unknown, DeleteDocumentsPayload>(
  'company/deleteDocuments',
  CompaniesAPI.deleteDocuments,
)

const uploadDocument = createAsyncThunk<Document, UploadDocumentPayload>(
  'company/uploadDocument',
  CompaniesAPI.uploadDocument,
)

const editDocuments = createAsyncThunk<Document[], EditDocumentsPayload>(
  'company/editDocument',
  CompaniesAPI.editDocuments,
)

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBusinessAccountBalance.fulfilled, (state, action) => {
      state.wallet = { ...state.wallet, ...action.payload }
    })
    builder.addCase(fetchBusinessAccountTransactions.fulfilled, (state, action) => {
      state.wallet.transactions = action.payload.transactions
    })
    builder.addCase(fetchBusinessAccountDetails.fulfilled, (state, action) => {
      state.wallet.details = action.payload
    })
    builder.addCase(updateTransactionCategory.fulfilled, (state, action) => {
      state.wallet.transactions = state.wallet.transactions.map((tx: Transaction) =>
        tx.id === action.meta.arg.transactionId ? { ...tx, ...action.meta.arg.update } : tx,
      )
    })
    builder.addCase(fetchDocuments.pending, (state) => {
      state.documents = []
    })
    builder.addCase(fetchDocuments.fulfilled, (state, action) => {
      state.documents = action.payload
    })
    builder.addCase(fetchCompanyDetails.fulfilled, (state, action) => {
      state.details = action.payload
    })
    builder.addCase(deleteDocuments.fulfilled, (state, action) => {
      state.documents = state.documents.filter(
        (document) => !action.meta.arg.documentIds.includes(document.id),
      )
    })
    builder.addCase(uploadDocument.fulfilled, (state, action) => {
      state.documents.unshift(action.payload)
    })
    builder.addCase(editDocuments.fulfilled, (state, action) => {
      const updates = action.payload.reduce<Record<Document['id'], Document>>(
        (map, doc) => ({ ...map, [doc.id]: doc }),
        {},
      )
      state.documents = state.documents.map((doc) => updates[doc.id] ?? doc)
    })
  },
})

// actions
// export const { } = companySlice.actions
export {
  fetchBusinessAccountBalance,
  fetchBusinessAccountTransactions,
  fetchBusinessAccountDetails,
  downloadBusinessAccountTransactions,
  updateTransactionCategory,
  fetchDocuments,
  fetchDocumentLinks,
  downloadDocuments,
  fetchCompanyDetails,
  deleteDocuments,
  uploadDocument,
  editDocuments,
}
// reducer
export default companySlice.reducer

import React, { useEffect } from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { Button, Callout } from '@gground/capcom.core'
import { MirroredInput } from '@gground/capcom.form'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from 'src/store'
import { trackSnowplow } from 'src/store/slices/user.slice'
import Layout from 'src/components/Layout'
import CopyToClipboard from 'src/components/CopyToClipboard'
import PhoneNumberInput from 'src/components/PhoneNumberInput'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(8, 'auto', 0),
    maxWidth: 600,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  link: {
    color: '#006bc2',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  number: {
    fontSize: 16,
    marginRight: theme.spacing(1),
    fontWeight: 500,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flexGrow: 1,
    padding: theme.spacing(6, 0),
  },
}))

interface SignUp2FASMSProps {
  phone: string | null
  onSwitchMethod: () => void
  onAddPhone: (phone: string) => void
  onClearPhone: () => void
  onValidate: (otp: string) => void
}

const SignUp2FASMS = ({
  phone,
  onSwitchMethod,
  onAddPhone,
  onClearPhone,
  onValidate,
}: SignUp2FASMSProps) => {
  const classes = useStyles()
  const phoneForm = useForm({ shouldFocusError: false, reValidateMode: 'onSubmit' })
  const otpForm = useForm({ shouldFocusError: false, reValidateMode: 'onSubmit' })
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(trackSnowplow({ category: '2fa', action: '2fa_sms_selection' }))
  }, [])

  return (
    <Layout>
      <Box className={classes.container}>
        <Typography variant="h1">2-Step Verification</Typography>
        <Box mt={4}>
          <Callout severity="info">
            Please follow the instructions to set up SMS Authentication
          </Callout>
        </Box>
        <Box mt={2}>
          <Typography>
            We will send an authentication code to this phone number via text message. We will use
            this phone number each time. You can always update this later.
          </Typography>
        </Box>

        <Box mt={2} display={phone ? 'none' : 'block'}>
          <form onSubmit={phoneForm.handleSubmit(({ phoneNumber }) => onAddPhone(phoneNumber))}>
            <PhoneNumberInput
              control={phoneForm.control}
              name="phoneNumber"
              disabled={false}
              notRequired={false}
              inputFullWidth={false}
              error={phoneForm.errors.phoneNumber}
              clearErrors={phoneForm.clearErrors}
            />
            <Box mt={3}>
              <Button type="submit">Add phone number</Button>
            </Box>
          </form>
          <Box mt={4}>
            <Typography>
              <span>
                If you&apos;re country is not listed it means SMS Text delivery is not available for
                your country. If this is the case you can use&nbsp;
              </span>
              <span className={classes.link} onClick={onSwitchMethod}>
                Google Authenticator instead
              </span>
              <span>.</span>
            </Typography>
          </Box>
        </Box>

        <Box mt={4} display={phone ? 'block' : 'none'}>
          <Typography>
            <span className={classes.number}>{phone}</span>
            <span className={classes.link} onClick={onClearPhone}>
              Edit
            </span>
          </Typography>
          <Box mt={2}>
            <form onSubmit={otpForm.handleSubmit(({ otp }) => onValidate(otp))}>
              <MirroredInput
                name="otp"
                id="otp"
                type="otp"
                label="OTP Number"
                inputProps={{
                  required: true,
                  hint: 'The 6 digit number you will receive in an SMS text message',
                  helperText: otpForm.errors.otp && otpForm.errors.otp?.message,
                  ref: otpForm.register({
                    required: 'This field is required',
                  }),
                }}
                error={otpForm.errors.otp}
                onFocus={() => otpForm.clearErrors()}
              />
              <Box mt={2}>
                <Button type="submit">Verify code and enable</Button>
              </Box>
            </form>
          </Box>
          <Box mt={4}>
            <Typography>
              <span>Didn&apos;t receive your code? </span>
              <span className={classes.link} onClick={() => onAddPhone(phone!)}>
                Resend code
              </span>
              <span>. Alternatively you can use </span>
              <span className={classes.link} onClick={onSwitchMethod}>
                Google Authenticator instead
              </span>
              <span>.</span>
            </Typography>
          </Box>
        </Box>

        <Box className={classes.footer}>
          <Typography>
            Need help? You can email <CopyToClipboard text="gethelp@getground.co.uk" />
          </Typography>
        </Box>
      </Box>
    </Layout>
  )
}

export default SignUp2FASMS

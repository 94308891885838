import { Middleware } from 'redux'
import dayjs from 'dayjs'
import jwt_decode, { JwtPayload } from 'jwt-decode'
import { setUserId } from '@snowplow/browser-tracker'

import { setSentryUser } from 'src/utils/sentry'
import { refreshToken, checkToken, signOut, signIn, signUp } from '../slices/user.slice'
// eslint-disable-next-line import/no-cycle
import { RootState } from '..'

const MINUTE = 60 * 1000

let refreshTokenTimeoutId: ReturnType<typeof setTimeout>

const auth: Middleware<unknown, RootState> = (store) => (next) => async (action) => {
  const { dispatch, getState } = store
  const state = getState()

  switch (action.type) {
    case checkToken.type: {
      const TIME_LEFT_BEFORE_REFRESHING = 5
      const { token } = state.user.auth
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const decodedToken: JwtPayload = jwt_decode<JwtPayload>(token!)
      const minutesToLive = dayjs(decodedToken.exp! * 1000).diff(dayjs(), 'minutes')
      if (import.meta.env.MODE === 'production') {
        const userId = decodedToken.consultant_id
        setSentryUser(userId)
      }

      if (minutesToLive <= TIME_LEFT_BEFORE_REFRESHING) {
        dispatch(refreshToken())
      } else {
        clearTimeout(refreshTokenTimeoutId)
        refreshTokenTimeoutId = setTimeout(
          () => dispatch(refreshToken()),
          (minutesToLive - TIME_LEFT_BEFORE_REFRESHING) * MINUTE,
        )
      }
      next(action)
      break
    }

    case signIn.fulfilled.type: {
      const consultantId = jwt_decode<JwtPayload>(action.payload.token).consultant_id
      setUserId(consultantId)
      next(action)
      break
    }

    case signUp.fulfilled.type: {
      const consultantId = jwt_decode<JwtPayload>(action.payload.token).consultant_id
      setUserId(consultantId)
      next(action)
      break
    }

    case refreshToken.fulfilled.type: {
      dispatch(checkToken())
      next(action)
      break
    }

    case refreshToken.rejected.type: {
      dispatch(signOut())
      next(action)
      break
    }

    default:
      next(action)
  }
}

export default auth

import React from 'react'
import { TableRow, TableCell, makeStyles, Link } from '@material-ui/core'
import { Checkbox } from '@gground/capcom.form'
import {
  Bin as RubbishBinIcon,
  Edit as EditIcon,
  Download as DownloadIcon,
} from '@gground/capcom.icons'
import dayjs from 'dayjs'
import { Document } from 'src/types'
import { DOCUMENT_TYPES } from 'src/utils/documentTypes'

const useStyles = makeStyles((theme) => ({
  actionIcon: {
    width: 16,
    height: 16,
    marginRight: theme.spacing(2),
    cursor: 'pointer',
    '& > path': {
      fill: '#1C444E',
    },
  },
}))

interface DocumentListItemProps {
  document: Document
  selected: boolean
  creator: string
  onToggle: () => void
  onView: () => void
  onDownload: () => void
  onDelete: () => void
  onEdit: () => void
}

const getDocumentTypeLabel = (type: string): string => {
  return DOCUMENT_TYPES.find((docType) => docType.type === type)?.label ?? type
}

const DocumentListItem = ({
  document,
  selected,
  creator,
  onToggle,
  onView,
  onDownload,
  onDelete,
  onEdit,
}: DocumentListItemProps) => {
  const classes = useStyles()

  return (
    <TableRow key={document.id}>
      <TableCell>
        <Checkbox name={`select-${document.id}`} checked={!!selected} onChange={onToggle} />
      </TableCell>
      <TableCell>
        <Link component="button" onClick={onView}>
          {document.name}
        </Link>
      </TableCell>
      <TableCell>{dayjs(document.upload_date).format('DD MMM, YYYY')}</TableCell>
      <TableCell>{creator}</TableCell>
      <TableCell>{getDocumentTypeLabel(document.type!)}</TableCell>
      <TableCell>
        {['user', 'partner'].includes(document.creator_type) && (
          <>
            <EditIcon className={classes.actionIcon} onClick={onEdit} />
            <RubbishBinIcon className={classes.actionIcon} onClick={onDelete} />
          </>
        )}
        <DownloadIcon className={classes.actionIcon} onClick={onDownload} />
      </TableCell>
    </TableRow>
  )
}

export default DocumentListItem

import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Hourglass = (props: SvgIconProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.99997 2.57153C5.52658 2.57153 5.14282 2.95529 5.14282 3.42868V6.0001C5.14282 6.47349 5.52658 6.85725 5.99996 6.85725L5.14282 3.42868L6.00155 6.85725H17.9984M17.1428 5.14296H6.85711V4.28582H17.1428V5.14296ZM18 6.85725C18.4734 6.85725 18.8571 6.47349 18.8571 6.0001V3.42868C18.8571 3.39909 18.8556 3.36985 18.8527 3.34104C18.8088 2.90882 18.4438 2.57153 18 2.57153H5.99997"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.99997 17.1431C5.52658 17.1431 5.14282 17.5268 5.14282 18.0002V18.0002V20.5716C5.14282 20.9859 5.43663 21.3314 5.82722 21.4114C5.88302 21.4228 5.94079 21.4288 5.99997 21.4288L6.00179 21.4288H17.9981M17.1428 19.7145H6.85711V18.8574H17.1428V19.7145ZM18 21.4288C18.4734 21.4288 18.8571 21.045 18.8571 20.5716V18.0002C18.8571 17.9706 18.8556 17.9414 18.8527 17.9126C18.8088 17.4804 18.4438 17.1431 18 17.1431H5.99997"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8325 9.96967C15.631 10.2617 15.4344 10.5467 15.2663 10.8299C14.9189 11.4149 14.7452 11.7075 14.7452 12C14.7452 12.2926 14.9189 12.5851 15.2663 13.1702C15.4344 13.4534 15.631 13.7383 15.8325 14.0304C16.4638 14.9455 17.1429 15.9299 17.1429 17.1429H15.4286C15.4286 15.9508 14.0796 14.43 13.897 14.2864C12 12 12 12 13.8969 9.71365C14.0795 9.57012 15.4286 8.0493 15.4286 6.85718H17.1429C17.1429 8.07011 16.4638 9.05456 15.8325 9.96967ZM10.1031 9.71363C12 12 12 12 10.1031 14.2865C9.92045 14.43 8.57146 15.9508 8.57146 17.1429H6.85718C6.85718 15.9299 7.53629 14.9455 8.16759 14.0304C8.36905 13.7383 8.56565 13.4534 8.73375 13.1702C9.08118 12.5851 9.2549 12.2926 9.2549 12C9.2549 11.7075 9.08119 11.4149 8.73379 10.8299C8.56569 10.5467 8.36908 10.2617 8.16759 9.96967C7.5363 9.05456 6.85718 8.07011 6.85718 6.85718H8.57146C8.57146 8.04929 9.90832 9.45759 10.1031 9.71363Z"
      fill="inherit"
    />
  </SvgIcon>
)

export default Hourglass

import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import user from './slices/user.slice'
import clients from './slices/clients.slice'
import propertyPurchases from './slices/propertyPurchases.slice'
import partner from './slices/partner.slice'
import company from './slices/company.slice'
import authMiddleware from './middlewares/auth' // eslint-disable-line import/no-cycle

const reducer = combineReducers({ user, clients, propertyPurchases, partner, company })

export type RootState = ReturnType<typeof reducer>

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
}

const middlewares = [authMiddleware]

export const createStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: persistReducer(persistConfig, reducer),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(middlewares),
    preloadedState,
  })

export type AppStore = ReturnType<typeof createStore>

export type AppDispatch = AppStore['dispatch']

export const useAppDispatch: () => AppDispatch = useDispatch

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

import React from 'react'
import { Box, MenuItem, Typography } from '@material-ui/core'
import { Input, Select } from '@gground/capcom.form'
import { Controller, useFormContext } from 'react-hook-form'
import type { ClientFormFields } from './types'

const OtherPropertiesFormFragment = () => {
  const { register, watch, clearErrors, control, errors } = useFormContext<ClientFormFields>()
  const firstTimeLandlord = watch('first_time_landlord')

  return (
    <Box mt={4} component="section">
      <Typography variant="h2" style={{ fontWeight: 500 }}>
        Other buy-to-let properties
      </Typography>
      <Box mt={2}>
        <Controller
          name="first_time_landlord"
          control={control}
          defaultValue=""
          render={({ onChange, value }) => (
            <Select
              id="first_time_landlord"
              name="first_time_landlord"
              label="Are they a first time landlord?"
              value={value}
              onChange={onChange}
              fullWidth
            >
              <MenuItem value={true as any}>Yes</MenuItem>
              <MenuItem value={false as any}>No</MenuItem>
            </Select>
          )}
        />
        {firstTimeLandlord === true ? (
          <Box mt={2}>
            <Controller
              name="first_time_buyer"
              control={control}
              defaultValue=""
              render={({ onChange, value }) => (
                <Select
                  id="first_time_buyer"
                  name="first_time_buyer"
                  label="Are they a first time buyer?"
                  value={value}
                  onChange={onChange}
                  fullWidth
                >
                  <MenuItem value={true as any}>Yes</MenuItem>
                  <MenuItem value={false as any}>No</MenuItem>
                </Select>
              )}
            />
          </Box>
        ) : null}
        {firstTimeLandlord === false ? (
          <>
            <Box mt={2}>
              <Input
                type="number"
                id="global_properties_count"
                name="global_properties_count"
                label="How many buy-to-let properties they own globally?"
                error={!!errors.global_properties_count}
                onFocus={() => clearErrors()}
                ref={register({ valueAsNumber: true })}
                fullWidth
              />
            </Box>
            <Box mt={2}>
              <Input
                type="number"
                id="uk_properties_count"
                name="uk_properties_count"
                label="How many of these are in the UK?"
                error={!!errors.uk_properties_count}
                onFocus={() => clearErrors()}
                ref={register({ valueAsNumber: true })}
                fullWidth
              />
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  )
}

export default OtherPropertiesFormFragment

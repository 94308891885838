import React from 'react'
import Layout from 'src/components/Layout'

const NotFound = () => (
  <Layout>
    <h1>Not Found</h1>
  </Layout>
)

export default NotFound

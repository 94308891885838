import React, { useCallback, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import pluralize from 'pluralize'
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone'
import {
  Box,
  DialogActions,
  LinearProgress,
  makeStyles,
  Snackbar,
  Theme,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { FormProvider, useForm } from 'react-hook-form'
import { Button } from '@gground/capcom.core'
import { useAppDispatch } from 'src/store'
import { trackSnowplow } from 'src/store/slices/user.slice'
import { uploadDocument } from 'src/store/slices/company.slice'
import { showErrorToast, showSuccessToast, showWarningToast } from 'src/utils/toasts'
import ModalDialog from 'src/components/ModalDialog/ModalDialog'
import UploadIcon from 'src/icons/Upload'
import { DocumentUpload } from 'src/types'
import DocumentsFormFragment from './DocumentsFormFragment'
import { CompanyOutletContext } from '../types'
import { DocumentsForm, FormDoc } from './types'

interface StyleProps {
  disabled?: boolean
  active?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  dropzone: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderWidth: '1px',
    borderStyle: 'dashed',
    borderRadius: 4,
    textAlign: 'center',
    borderColor: ({ active }) => (active ? '#2A6574' : '#CBCBCB'),
    background: ({ active }) => (active ? '#E8F4F7' : '#F9F9FA'),
    cursor: ({ disabled }) => (disabled ? 'not-allowed' : 'pointer'),
    opacity: ({ disabled }) => (disabled ? '0.5' : '1'),
  },
}))

interface DocumentsUploadProps {
  className?: string
  disabled?: boolean
}

const DocumentsUpload = ({ className = '', disabled = false }: DocumentsUploadProps) => {
  const { clientId, companyId } = useOutletContext<CompanyOutletContext>()
  const dispatch = useAppDispatch()
  const [files, setFiles] = useState<{ file: File; document: FormDoc }[]>([])
  const [documents, setDocuments] = useState<FormDoc[]>([])
  const [modalOpen, setModalOpen] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState({ current: 0, target: 0 })
  const form = useForm<DocumentsForm>({
    shouldFocusError: false,
    reValidateMode: 'onSubmit',
  })

  useEffect(() => {
    setDocuments(files.map((f) => f.document))
  }, [files])

  const closeModal = useCallback(() => {
    setModalOpen(false)
    setFiles([])
  }, [])

  useEffect(() => {
    if (files.length === 0 && modalOpen) {
      closeModal()
    }
  }, [files, modalOpen, closeModal])

  const removeDocument = useCallback(
    (index: number) => {
      setFiles([...files.slice(0, index), ...files.slice(index + 1)])
    },
    [files, setFiles],
  )

  const handleSubmit = useCallback(
    ({ list }: DocumentsForm) => {
      setUploadProgress({ current: 0, target: list.length })
      setUploading(true)

      Promise.all(
        list.map((doc, index) =>
          dispatch(
            uploadDocument({
              clientId,
              companyId,
              file: files[index].file,
              document: {
                document_date: null,
                expense_category: null,
                currency: null,
                description: null,
                merchant_name: null,
                amount_in_cents: null,
                ...doc,
              } as DocumentUpload,
            }),
          )
            .unwrap()
            .then(() => setUploadProgress((p) => ({ ...p, current: p.current + 1 }))),
        ),
      )
        .then(() => {
          showSuccessToast(
            `${list.length} ${pluralize('document', list.length)} uploaded successfully`,
          )
          dispatch(trackSnowplow({ category: 'documents', action: 'upload_document' }))
        })
        .catch(() => showErrorToast(`Failed to upload ${pluralize('document', list.length)}`))
        .finally(() => setUploading(false))

      closeModal()
    },
    [closeModal, dispatch, files, clientId, companyId],
  )

  const handleFileDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      const error = fileRejections[0].errors[0].code as ErrorCode
      const messages = {
        [ErrorCode.FileInvalidType]: 'You can only upload *.jpg, *.jpeg, *.png and *.pdf files',
        [ErrorCode.TooManyFiles]: 'You can only upload 10 files at once',
        [ErrorCode.FileTooLarge]: "File size can't exceed 10MB",
        [ErrorCode.FileTooSmall]: "Files can't be empty",
      }
      showWarningToast(
        messages[error] ?? `Failed to upload ${pluralize('document', fileRejections.length)}`,
      )
      setFiles([])
    } else {
      setFiles(
        acceptedFiles.map((file) => ({
          file,
          document: { name: file.name, type: null },
        })),
      )
      setModalOpen(true)
    }
  }, [])

  const dropzone = useDropzone({
    onDrop: handleFileDrop,
    disabled,
    maxFiles: 10,
    maxSize: 1e7, // 10MB
    minSize: 1,
    accept: { 'image/jpeg': ['.jpeg', '.jpg'], 'image/png': ['.png'], 'application/pdf': ['.pdf'] },
  })

  const classes = useStyles({ disabled, active: dropzone.isDragActive })

  return (
    <Box className={className}>
      <Box {...dropzone.getRootProps({ className: classes.dropzone })}>
        <input {...dropzone.getInputProps()} />
        <UploadIcon style={{ color: '#1C444E', marginRight: 8 }} />
        <Typography>
          Click here to upload a document, or drag and drop a document here from your desktop (max.
          10 at a time)
        </Typography>
      </Box>

      <ModalDialog
        open={modalOpen}
        onClose={closeModal}
        title={`Upload ${pluralize('document', files.length)}`}
      >
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <DocumentsFormFragment documents={documents} onRemove={removeDocument} />
            <DialogActions>
              <Button onClick={closeModal} variant="text">
                Cancel
              </Button>
              <Button type="submit" variant="contained" data-testid="upload-documents-button">
                {`Upload ${files.length} ${pluralize('document', files.length)}`}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </ModalDialog>

      <Snackbar open={uploading} style={{ minWidth: 300 }}>
        <Alert icon={false} color="warning" style={{ width: '100%' }}>
          <Box width="100%">
            <Box display="flex" alignItems="center">
              <LinearProgress
                style={{ flexGrow: 1 }}
                color="secondary"
                variant="determinate"
                value={(uploadProgress.current / uploadProgress.target) * 100 || 0}
              />
              <Box display="flex" justifyContent="center" ml={1}>
                <Typography
                  color="textSecondary"
                  style={{ fontSize: 12, fontVariantNumeric: 'tabular-nums' }}
                >
                  ({uploadProgress.current} of {uploadProgress.target})
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" mt={1}>
              <Typography variant="body1">Do not close or refresh the page</Typography>
            </Box>
          </Box>
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default DocumentsUpload

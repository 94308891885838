import React from 'react'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    '&:last-child $lineEnd': {
      display: 'none',
    },
  },
  lines: {
    flex: 'none',
    width: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  lineStart: {
    height: theme.spacing(4),
    flex: 'none',
    border: '1px solid #DEE0E3',
    borderTop: 'none',
    borderRight: 'none',
    borderRadius: '0 0 0 4px',
  },
  lineEnd: {
    flexGrow: 1,
    borderLeft: '1px solid #DEE0E3',
    marginTop: -4,
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
  },
}))

interface SubContainerProps {
  children: React.ReactNode
}

const SubContainer = ({ children }: SubContainerProps) => {
  const classes = useStyles()
  return (
    <Box>
      {React.Children.map(children, (child) => (
        <Box className={classes.item}>
          <Box className={classes.lines}>
            <Box className={classes.lineStart} />
            <Box className={classes.lineEnd} />
          </Box>
          <Box className={classes.content}>{child}</Box>
        </Box>
      ))}
    </Box>
  )
}

export default SubContainer

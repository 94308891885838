import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as PartnerAPI from 'src/api/partner'
import type { PartnerOverview, ClientOverview } from 'src/types'

interface PartnerState {
  clients: ClientOverview[]
}

const initialState: PartnerState = {
  clients: [],
}

const fetchPartnerOverview = createAsyncThunk<PartnerOverview>('partner/fetchOverview', () =>
  PartnerAPI.fetchPartnerOverview(),
)

const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPartnerOverview.fulfilled, (state, action) => {
      state.clients = action.payload.clients
    })
  },
})

// actions
// export const { } = partnerSlice.actions
export { fetchPartnerOverview }
// reducer
export default partnerSlice.reducer

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, makeStyles } from '@material-ui/core'
import { Button } from '@gground/capcom.core'
import { ClientOverview, OverviewStatus, PropertyPurchaseOverview } from 'src/types'
import { trackSnowplow } from 'src/store/slices/user.slice'
import { useAppDispatch } from 'src/store'
import ProgressStep from './ProgressStep'
import StepTask from './StepTask'

const useStyles = makeStyles((theme) => ({
  outlineButtonContainer: {
    '& > button': {
      border: `1px solid ${theme.palette.primary.main}`,
      whiteSpace: 'nowrap',
    },
  },
}))

interface CreateCompanyStepProps {
  clientId: ClientOverview['id']
  propertyPurchase?: PropertyPurchaseOverview
}

const CreateCompanyStep = ({ clientId, propertyPurchase }: CreateCompanyStepProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const status = propertyPurchase?.design_status ?? OverviewStatus.TODO
  const subsidiaryId = propertyPurchase?.is_holding_co ? propertyPurchase?.subsidiaries[0].id : null

  const getStarted = () => {
    dispatch(trackSnowplow({ category: 'dashboard', action: 'company_get_started' }))
    navigate(`/clients/${clientId}/companies`)
  }

  const resume = () => {
    dispatch(
      trackSnowplow({
        category: 'dashboard',
        action: 'company_resume',
        ppId: propertyPurchase?.id,
      }),
    )
    navigate(`/clients/${clientId}/companies/${subsidiaryId || propertyPurchase?.id}/shareholding`)
  }

  return (
    <ProgressStep status={status} name="Create a Company" position={2}>
      {status === OverviewStatus.TODO ? (
        <Box mx={2} mb={2} className={classes.outlineButtonContainer}>
          <Button variant="oulined" onClick={getStarted}>
            Get started
          </Button>
        </Box>
      ) : status === OverviewStatus.IN_PROGRESS ? (
        <Box mx={2} mb={2}>
          <Button onClick={resume}>Resume</Button>
        </Box>
      ) : status === OverviewStatus.PENDING_APPROVAL ? (
        <StepTask name="Sent for review" status={status} />
      ) : null}
    </ProgressStep>
  )
}

export default CreateCompanyStep

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { newTracker, trackSelfDescribingEvent } from '@snowplow/browser-tracker'
import { GaCookiesPlugin } from '@snowplow/browser-plugin-ga-cookies'
import jwt_decode, { JwtPayload } from 'jwt-decode'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import * as UserAPI from 'src/api/user'
import type {
  SignUpOptions,
  SignUpResponse,
  SignInOptions,
  SignInResponse,
  SignUp2FAInitOptions,
  SignUp2FAInitResponse,
  SignUp2FAValidateOptions,
  SignUp2FAValidateResponse,
  Verify2FAOptions,
  Verify2FAResponse,
  RefreshTokenResponse,
} from 'src/api/user'
import type { AuthStatus, Auth2FAMethod } from 'src/types'

interface UserState {
  auth: {
    status: AuthStatus
    token: string | null
    method: Auth2FAMethod
    phone_number: string | null
  }
}

const initialState: UserState = {
  auth: {
    status: 'UNAUTHENTICATED',
    token: null,
    method: null,
    phone_number: null,
  },
}

interface TrackSnowplow {
  category: string
  action: string
  ppId?: number
  companyId?: number
}

dayjs.extend(utc)
newTracker('sp1', `${import.meta.env.VITE_SNOWPLOW_COLLECTOR}`, {
  appId: 'partner-portal',
  postPath: '/com.getground/track',
  discoverRootDomain: true,
  stateStorageStrategy: 'cookieAndLocalStorage',
  cookieSameSite: 'Lax', // Recommended
  plugins: [GaCookiesPlugin()],
})

export const trackSnowplow = createAsyncThunk(
  `user/trackSnowplow`,
  ({ category, action, ppId, companyId }: TrackSnowplow, { getState }) => {
    const state = getState()
    const { token } = state?.user?.auth
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const consultantId = token ? jwt_decode<JwtPayload>(token!).consultant_id.toString() : null
    const uid = null
    const timestamp = dayjs.utc().format('YYYY-MM-DDTHH:mm:ss')

    return trackSelfDescribingEvent({
      event: {
        schema: 'iglu:com.getground/partner_portal/jsonschema/1-0-0',
        data: {
          category,
          action,
          pp_id: ppId ? ppId.toString() : null,
          consultant_id: consultantId,
          company_id: companyId ? companyId.toString() : null,
          uid,
          timestamp,
        },
      },
    })
  },
)

const signUp = createAsyncThunk<SignUpResponse, SignUpOptions>('user/signup', (options) =>
  UserAPI.signUp(options),
)

const signUp2FAInit = createAsyncThunk<SignUp2FAInitResponse, SignUp2FAInitOptions>(
  'user/signup/2fa/init',
  (options) => UserAPI.signUp2FAInit(options),
)

const signUp2FAValidate = createAsyncThunk<SignUp2FAValidateResponse, SignUp2FAValidateOptions>(
  'user/signup/2fa/validate',
  (options) => UserAPI.signUp2FAValidate(options),
)

const signIn = createAsyncThunk<SignInResponse, SignInOptions>(
  'user/signin',
  async (options, thunkAPI) => {
    try {
      const req = await UserAPI.signIn(options)
      return req
    } catch (e: any) {
      if (!e.response) {
        throw e
      }
      return thunkAPI.rejectWithValue(e.response.data)
    }
  },
)

const resendSMSCode = createAsyncThunk<void, void>('user/resendSMSCode', () =>
  UserAPI.resendSMSCode(),
)

const verify2FA = createAsyncThunk<Verify2FAResponse, Verify2FAOptions>(
  'user/signin/2fa',
  (options) => UserAPI.verify2FA(options),
)

const refreshToken = createAsyncThunk('user/refreshToken', () => UserAPI.refreshToken())

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signOut: (state) => {
      state.auth = initialState.auth
    },
    checkToken: (state) => state,
  },
  extraReducers: (builder) => {
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.auth.status = 'AUTHENTICATED'
      state.auth.token = action.payload.token
    })
    builder.addCase(signUp.rejected, (state) => {
      state.auth.status = 'UNAUTHENTICATED'
      state.auth.token = null
    })
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.auth.method = action.payload['2fa_method']
      state.auth.token = action.payload.token
      state.auth.status = 'AUTHENTICATED'
      state.auth.phone_number = action.payload.phone_number || null
    })
    builder.addCase(signUp2FAValidate.fulfilled, (state, action) => {
      state.auth.method = action.meta.arg.method
      state.auth.token = action.payload.token
      state.auth.status = 'AUTHORIZED'
    })
    builder.addCase(verify2FA.fulfilled, (state, action) => {
      state.auth.token = action.payload.token
      state.auth.status = 'AUTHORIZED'
    })
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.auth.token = action.payload.jwt_token
    })
  },
})

// actions
export const { signOut, checkToken } = userSlice.actions
export { signUp, signUp2FAInit, signUp2FAValidate, signIn, resendSMSCode, verify2FA, refreshToken }
// reducer
export default userSlice.reducer

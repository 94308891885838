import dayjs from 'dayjs'
import { isAddressLine, lettersOnly, noSpecialCharacter } from 'src/utils/validate'
import type { Client, ClientUpdate } from 'src/types'
import type { ClientFormFields } from './types'

export const validateLettersOnly = (text: string) =>
  lettersOnly(text) || 'Remove invalid characters'

export const validateNoSpecialCharacter = (text: string) =>
  noSpecialCharacter(text) || 'Remove invalid characters'

export const validateAddressLine = (text: string) =>
  isAddressLine(text) || 'Remove invalid characters'

export const validateDateOfBirth = (year: number, month: number, day: number) => {
  // valid if all empty
  if (!day && !month && !year) return true

  // invalid if some empty
  if (!day || !month || !year) return 'Invalid birth date'

  // check invalid date format
  const yy = year.toString().padStart(2, '0')
  const mm = month.toString().padStart(2, '0')
  const dd = day.toString().padStart(2, '0')
  const date = dayjs(`${yy}-${mm}-${dd}`, 'YYYY-MM-DD', true)
  if (!date.isValid()) {
    return 'Invalid birth date'
  }

  // check minimum age
  const age = dayjs().diff(date, 'years')
  if (age < 18) {
    return 'The client must be at least 18 years old to join GetGround'
  }

  return true
}

// get form fields from client object
export const clientToForm = (client: Client): ClientFormFields => {
  const { id, email, date_of_birth, ...rest } = client
  const [year_of_birth, month_of_birth, day_of_birth] = (date_of_birth ?? '')
    .split('-')
    .map((s) => parseInt(s, 10))
  const has_changed_name = !!rest.previous_names
  const prefers_different_name = !!rest.nickname

  return {
    ...rest,
    year_of_birth,
    month_of_birth,
    day_of_birth,
    has_changed_name,
    prefers_different_name,
  }
}

// get client update from form fields
export const formToClientUpdate = (fields: ClientFormFields): ClientUpdate => {
  const {
    has_changed_name,
    prefers_different_name,
    year_of_birth,
    month_of_birth,
    day_of_birth,
    ...rest
  } = fields
  const validDate = year_of_birth && month_of_birth && day_of_birth
  const date_of_birth = validDate
    ? [
        `${year_of_birth}`,
        `${month_of_birth}`.padStart(2, '0'),
        `${day_of_birth}`.padStart(2, '0'),
      ].join('-')
    : null
  const nickname = prefers_different_name ? rest.nickname : null
  const previous_names = has_changed_name ? rest.previous_names : null
  const update = { ...rest, date_of_birth, nickname, previous_names }

  // convert empty string values to null
  const sanitized = Object.entries(update).reduce(
    (ac, [key, value]) => ({ [key]: value === '' ? null : value, ...ac }),
    {} as ClientUpdate,
  )

  return sanitized
}

import React from 'react'
import dayjs from 'dayjs'
import { Card, CardHeader, CardContent, Grid, Box, Typography, makeStyles } from '@material-ui/core'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { getOneLineAddress, formatCurrencyWithComma, penniesToPounds } from 'src/utils/misc'
import { showSuccessToast } from 'src/utils/toasts'
import type { CompanyDetails } from 'src/types'

const useStyles = makeStyles({
  box: {
    background: '#F9F9FA',
    borderRadius: 4,
    padding: 8,
    cursor: 'pointer',
    height: 58,
  },
  name: {
    color: '#4E545F',
  },
  value: {
    fontWeight: 500,
  },
})

interface Props {
  propertyPurchaseDetails: CompanyDetails['property_purchase_details']
}

const PropertyPurchaseDetailsSection = ({ propertyPurchaseDetails }: Props) => {
  const classes = useStyles()
  const address = getOneLineAddress(propertyPurchaseDetails?.address) || ''
  const propertyPurchasePrice =
    propertyPurchaseDetails?.price_in_cents &&
    formatCurrencyWithComma(penniesToPounds(propertyPurchaseDetails?.price_in_cents))
  const completionDate = propertyPurchaseDetails?.completion_date
    ? dayjs(propertyPurchaseDetails.completion_date).format('D MMMM YYYY')
    : ''
  const isUsingMortgage = propertyPurchaseDetails?.using_mortgage ? 'Yes' : 'No'

  return (
    <Card>
      <CardHeader
        title="Property purchase details"
        action={
          <Box>
            <Typography variant="subtitle1" component="i">
              Hint: Click on a field to copy it
            </Typography>
          </Box>
        }
      />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Box className={classes.box}>
              <Typography variant="body1" className={classes.name}>
                Address:
              </Typography>
              <CopyToClipboard text={address} onCopy={() => showSuccessToast('Copied')}>
                <Typography variant="body1" className={classes.value}>
                  {address}
                </Typography>
              </CopyToClipboard>
            </Box>
          </Grid>
          <Grid item sm={4}>
            <Box className={classes.box}>
              <Typography variant="body1" className={classes.name}>
                Price:
              </Typography>
              <CopyToClipboard
                text={`£${propertyPurchasePrice}`}
                onCopy={() => showSuccessToast('Copied')}
              >
                <Typography variant="body1" className={classes.value}>
                  £{propertyPurchasePrice}
                </Typography>
              </CopyToClipboard>
            </Box>
          </Grid>
          <Grid item sm={4}>
            <Box className={classes.box}>
              <Typography variant="body1" className={classes.name}>
                Completion date:
              </Typography>
              <CopyToClipboard text={completionDate} onCopy={() => showSuccessToast('Copied')}>
                <Typography variant="body1" className={classes.value}>
                  {completionDate}
                </Typography>
              </CopyToClipboard>
            </Box>
          </Grid>
          <Grid item sm={4}>
            <Box className={classes.box}>
              <Typography variant="body1" className={classes.name}>
                Purchasing with a mortgage:
              </Typography>
              <CopyToClipboard text={isUsingMortgage} onCopy={() => showSuccessToast('Copied')}>
                <Typography variant="body1" className={classes.value}>
                  {isUsingMortgage}
                </Typography>
              </CopyToClipboard>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default PropertyPurchaseDetailsSection

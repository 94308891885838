import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { Button } from '@gground/capcom.core'
import { ClientOverview, OverviewStatus, PropertyPurchaseOverview } from 'src/types'
import { useAppDispatch } from 'src/store'
import { trackSnowplow } from 'src/store/slices/user.slice'
import { useNavigate } from 'react-router-dom'
import ProgressStep from './ProgressStep'
import StepTask from './StepTask'

const useStyles = makeStyles((theme) => ({
  outlineButtonContainer: {
    '& > button': {
      border: `1px solid ${theme.palette.primary.main}`,
      whiteSpace: 'nowrap',
    },
  },
}))

interface ClientApprovalStepProps {
  clientId: ClientOverview['id']
  propertyPurchase?: PropertyPurchaseOverview
}

const ClientApprovalStep = ({ clientId, propertyPurchase }: ClientApprovalStepProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const companyStatus = propertyPurchase?.design_status ?? OverviewStatus.TODO
  const approvalStatus = propertyPurchase?.client_approval ?? OverviewStatus.TODO
  const showReviewButton =
    companyStatus !== OverviewStatus.TODO && approvalStatus === OverviewStatus.TODO

  const handleSnowplow = () => {
    dispatch(
      trackSnowplow({
        category: 'dashboard',
        action: 'dashboard_client_approval_review',
        ppId: propertyPurchase?.id,
      }),
    )
  }

  return (
    <ProgressStep status={approvalStatus} name="Client approval" position={3}>
      {showReviewButton ? (
        <Box mx={2} mb={2} className={classes.outlineButtonContainer}>
          <Button
            variant="oulined"
            onClick={() => {
              handleSnowplow()
              navigate(`/clients/${clientId}/companies/${propertyPurchase?.id}/review`)
            }}
          >
            Review and send
          </Button>
        </Box>
      ) : approvalStatus === OverviewStatus.PENDING_APPROVAL ? (
        <StepTask name="Sent for review" status={approvalStatus} highlighted />
      ) : null}
    </ProgressStep>
  )
}

export default ClientApprovalStep

import React, { useState } from 'react'
import { QRCodeSVG } from 'qrcode.react'
import { makeStyles, Box, Typography } from '@material-ui/core'
import { Arrow as ArrowIcon } from '@gground/capcom.icons'
import AppStoreBadge from 'src/assets/images/app-store-badge.svg'
import PlayStoreBadge from 'src/assets/images/play-store-badge.svg'
import GoogleAuthenticatorImage from 'src/assets/images/google-authenticator.svg'
import IOSAddIcon from 'src/assets/images/ios-add-icon.svg'
import AndroidAddIcon from 'src/assets/images/android-add-icon.svg'
import CopyToClipboard from 'src/components/CopyToClipboard'

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(8),
  },
  link: {
    color: '#006bc2',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  footer: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flexGrow: 1,
    padding: theme.spacing(6, 0),
  },
  list: {
    margin: theme.spacing(4, 0, 0),
    padding: 0,
    listStyle: 'none',
    counterReset: 'otp-instructions',
  },
  listItem: {
    counterIncrement: 'otp-instructions',
    paddingBottom: theme.spacing(3),
    '&:not(:last-child)': {
      borderBottom: '1px solid #DEE0E3',
      marginBottom: theme.spacing(3),
    },
    '&::before': {
      content: 'counter(otp-instructions) ". "',
      fontSize: '24px',
      fontWeight: 500,
    },
  },
  appStoreBadges: {
    display: 'flex',
    marginTop: theme.spacing(1),
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
  badge: {
    minWidth: 0,
    maxWidth: 135,
  },
  plusIcons: {
    display: 'flex',
    marginTop: theme.spacing(1),
    '& > figure': {
      display: 'flex',
      margin: 0,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    '& > figure:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
    '& > figure > figcaption': {
      fontSize: 12,
      color: theme.palette.info.main,
    },
  },
  authenticatorKey: {
    marginTop: theme.spacing(1),
  },
  authenticatorQR: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  qrImage: {
    minWidth: 80,
    maxWidth: 128,
  },
  qrArrow: {
    transform: 'rotate(180deg)',
    margin: theme.spacing(0, 1),
  },
  qrAlert: {
    maxWidth: 130,
    padding: theme.spacing(1),
    backgroundColor: '#FFCF00',
    borderRadius: 4,
    fontWeight: 500,
  },
  authenticatorImage: {
    marginTop: theme.spacing(1),
    display: 'block',
    width: '100%',
    maxWidth: 412,
  },
}))

const useSecret = (keyUri: string) => {
  let secret

  try {
    const url = new URL(keyUri)
    const params = new URLSearchParams(url.search)
    secret = params.get('secret') ?? null
  } catch {
    secret = null
  }

  return secret
}

interface SignUp2FAOTPGuideProps {
  otpKeyUri: string
}

const SignUp2FAOTPGuide = ({ otpKeyUri }: SignUp2FAOTPGuideProps) => {
  const classes = useStyles()
  const [showSecret, setShowSecret] = useState(false)
  const secret = useSecret(otpKeyUri)

  return (
    <Box>
      <Typography variant="h1" className={classes.title}>
        Google Authenticator App instructions
      </Typography>
      <ol className={classes.list}>
        <li className={classes.listItem}>
          <Typography component="span">
            Download Google Authenticator to your smartphone.
          </Typography>
          <Box className={classes.appStoreBadges}>
            <img src={AppStoreBadge} className={classes.badge} alt="App Store Badge" />
            <img src={PlayStoreBadge} className={classes.badge} alt="Play Store Badge" />
          </Box>
        </li>
        <li className={classes.listItem}>
          <Typography component="span">
            After you have downloaded the Google Authenticator App, open it and tap the plus (+)
            icon. Here is how that appears on both iOS and Android.
          </Typography>
          <Box className={classes.plusIcons}>
            <figure>
              <figcaption>iOS</figcaption>
              <img src={IOSAddIcon} alt="iOS" />
            </figure>
            <figure>
              <figcaption>Android</figcaption>
              <img src={AndroidAddIcon} alt="Android" />
            </figure>
          </Box>
        </li>
        <li className={classes.listItem}>
          <Typography component="span">
            <span>
              After you click the plus icon you will be asked to scan the QR code shown.&nbsp;
            </span>
            {showSecret ? (
              <span className={classes.link} onClick={() => setShowSecret(false)}>
                Click here for a barcode to scan
              </span>
            ) : (
              <>
                <span>Can&apos;t scan the QR code?&nbsp;</span>
                <span className={classes.link} onClick={() => setShowSecret(true)}>
                  Click here for a key to manually type into the Google Authenticator App
                </span>
              </>
            )}
            {showSecret ? (
              <Box className={classes.authenticatorKey}>
                <CopyToClipboard text={secret!}>
                  <Typography>
                    Tap here to copy your key: <strong>{secret}</strong>
                  </Typography>
                </CopyToClipboard>
              </Box>
            ) : (
              <Box className={classes.authenticatorQR}>
                <QRCodeSVG className={classes.qrImage} value={otpKeyUri} />
                <ArrowIcon className={classes.qrArrow} />
                <Typography className={classes.qrAlert}>
                  Scan this barcode with the Google Authenticator app
                </Typography>
              </Box>
            )}
          </Typography>
        </li>
        <li className={classes.listItem}>
          <Typography component="span">
            Enter the 6 digit number that appears in your Google Authenticator app after you scan
            the QR code shown in step 3. It should look like the example image shown below.
            <img
              src={GoogleAuthenticatorImage}
              className={classes.authenticatorImage}
              alt="Google Authenticator"
            />
          </Typography>
        </li>
        <li className={classes.listItem}>
          <Typography component="span">Submit your code.</Typography>
        </li>
      </ol>
    </Box>
  )
}

export default SignUp2FAOTPGuide

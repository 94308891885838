import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { makeStyles, Box, Typography } from '@material-ui/core'
import { Button, Callout } from '@gground/capcom.core'
import { MirroredInput } from '@gground/capcom.form'
import { useAppDispatch } from 'src/store'
import { trackSnowplow } from 'src/store/slices/user.slice'
import Layout from 'src/components/Layout'
import CopyToClipboard from 'src/components/CopyToClipboard'
import SignUp2FAOTPGuide from './SignUp2FAOTPGuide'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexGrow: 1,
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '60%',
    padding: theme.spacing(0, 4),
    paddingLeft: `max(calc((100% - 1008px) / 2), ${theme.spacing(4)}px)`,
  },
  guideContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: '40%',
    backgroundColor: 'white',
    overflowY: 'auto',
    position: 'relative',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    borderLeft: '1px solid #DEE0E3',
    borderTop: 'none',
    [theme.breakpoints.down('xs')]: {
      borderLeft: 'none',
      borderTop: '1px solid #DEE0E3',
    },
  },
  guide: {
    position: 'absolute',
    inset: 0,
    padding: theme.spacing(0, 4),
    [theme.breakpoints.down('xs')]: {
      position: 'initial',
    },
  },
  link: {
    color: '#006bc2',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  switchMethod: {
    margin: theme.spacing(2, 0),
  },
  footer: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flexGrow: 1,
    padding: theme.spacing(6, 0),
  },
}))

interface SignUp2FAOTPProps {
  otpKeyUri: string
  onSwitchMethod: () => void
  onValidate: (otp: string) => void
}

const SignUp2FAOTP = ({ otpKeyUri, onSwitchMethod, onValidate }: SignUp2FAOTPProps) => {
  const classes = useStyles()
  const { handleSubmit, register, errors, clearErrors } = useForm({
    shouldFocusError: false,
    reValidateMode: 'onSubmit',
  })
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(trackSnowplow({ category: '2fa', action: '2fa_auth_selection' }))
  }, [])

  const helpLink = (
    <Typography>
      Need help? You can email <CopyToClipboard text="gethelp@getground.co.uk" />
    </Typography>
  )

  return (
    <Layout fullWidth>
      <Box className={classes.wrapper}>
        <Box mt={8} className={classes.formContainer}>
          <Typography variant="h1">2-Step Verification</Typography>
          <Box mt={4}>
            <Callout severity="info">
              Please follow the instructions to download Google Authenticator, and scan the barcode
              to link your account
            </Callout>
          </Box>
          <Box mt={2}>
            <form onSubmit={handleSubmit(({ otp }) => onValidate(otp))}>
              <MirroredInput
                name="otp"
                id="otp"
                type="otp"
                label="OTP Number"
                inputProps={{
                  required: true,
                  hint: 'The 6 digit number you will find in your Google Authenticator App',
                  helperText: errors.otp && errors.otp?.message,
                  ref: register({
                    required: 'This field is required',
                  }),
                }}
                error={errors.otp}
                onFocus={() => clearErrors()}
              />
              <Box mt={2}>
                <Button type="submit">Continue</Button>
              </Box>
            </form>
          </Box>
          <Typography className={classes.switchMethod}>
            <span>Having trouble with Google Authenticator? </span>
            <span className={classes.link} onClick={onSwitchMethod}>
              Use SMS Text Message instead
            </span>
            <span>.</span>
          </Typography>
          <Box className={classes.footer} display={{ xs: 'none', sm: 'flex' }}>
            {helpLink}
          </Box>
        </Box>

        <Box className={classes.guideContainer}>
          <Box className={classes.guide}>
            <SignUp2FAOTPGuide otpKeyUri={otpKeyUri} />
            <Box className={classes.footer} display={{ xs: 'flex', sm: 'none' }}>
              {helpLink}
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export default SignUp2FAOTP

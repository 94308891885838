import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { Typography, Box, makeStyles } from '@material-ui/core'
import { Input, Checkbox } from '@gground/capcom.form'

import PhoneNumberInput from 'src/components/PhoneNumberInput'
import { validateWhitespace, validateLettersOnly, validateEmail } from './utils'

const useStyles = makeStyles((theme) => ({
  subTitle: {
    color: theme.palette.info.main,
  },
}))

const TaxQuestionsFormFragment = () => {
  const classes = useStyles()
  const { control, errors, register, clearErrors, watch, setValue } = useFormContext()

  const deferredSolicitor = watch('deferred', false)
  const needsSolicitor = watch('find_solicitor')
  const noFormInfo = deferredSolicitor || needsSolicitor

  return (
    <Box component="section">
      <Box mt={4}>
        <Typography variant="h2" className={classes.subTitle}>
          Solicitor details
        </Typography>
        <Box mt={2}>
          <Controller
            name="deferred"
            control={control}
            defaultValue={null}
            render={({ value }) => (
              <Checkbox
                id="deferred"
                name="deferred"
                label="They don’t have these details yet"
                disabled={needsSolicitor}
                checked={value}
                onChange={(e) => {
                  setValue('deferred', !value)
                  clearErrors()
                }}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            name="find_solicitor"
            control={control}
            defaultValue={null}
            render={({ value }) => (
              <Checkbox
                id="find_solicitor"
                name="find_solicitor"
                label="Please refer a solicitor to me"
                disabled={deferredSolicitor}
                checked={needsSolicitor}
                onChange={() => {
                  setValue('find_solicitor', !value)
                  clearErrors()
                }}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Input
            label="First name"
            name="first_name"
            id="first_name"
            type="text"
            fullWidth
            disabled={noFormInfo}
            ref={register({
              validate: {
                ...(!noFormInfo && {
                  lettersOnly: validateLettersOnly,
                  noWhiteSpace: validateWhitespace,
                }),
              },
            })}
            inputProps={{ spellCheck: 'false' }}
            error={Boolean(errors.first_name)}
            helperText={errors.first_name && errors.first_name?.message}
          />
        </Box>
        <Box mt={2}>
          <Input
            label="Surname (Family name)"
            name="last_name"
            id="last_name"
            type="text"
            fullWidth
            disabled={noFormInfo}
            ref={register({
              validate: {
                ...(!noFormInfo && {
                  lettersOnly: validateLettersOnly,
                  noWhiteSpace: validateWhitespace,
                }),
              },
            })}
            inputProps={{ spellCheck: 'false' }}
            error={Boolean(errors.last_name)}
            helperText={errors.last_name && errors.last_name?.message}
          />
        </Box>
        <Box mt={2}>
          <Input
            label="Email"
            name="email"
            id="email"
            fullWidth
            disabled={noFormInfo}
            ref={register({
              validate: {
                ...(!noFormInfo && {
                  validateEmail,
                }),
              },
              maxLength: {
                value: 128,
                message: 'Email needs to be under 128 characters',
              },
            })}
            error={Boolean(errors.email)}
            helperText={errors.email && errors.email?.message}
          />
        </Box>
        <Box mt={2}>
          <Input
            label="Case reference number (optional)"
            name="case_ref"
            id="case_ref"
            fullWidth
            disabled={noFormInfo}
            ref={register({
              maxLength: {
                value: 254,
                message: 'Maximum 254 characters',
              },
            })}
            error={Boolean(errors.case_ref)}
            helperText={errors.case_ref && errors.case_ref?.message}
          />
        </Box>
        <Box mt={2}>
          <PhoneNumberInput
            control={control}
            name="phone_number"
            notRequired
            inputFullWidth
            disabled={noFormInfo}
            error={Boolean(errors.phone_number)}
            clearErrors={clearErrors}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default TaxQuestionsFormFragment

import React, { useEffect } from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import { Outlet, useOutletContext } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/store'
import { fetchBusinessAccountBalance } from 'src/store/slices/company.slice'
import { trackSnowplow } from 'src/store/slices/user.slice'
import { formatCurrencyToTwoDP } from 'src/utils/misc'
import ArrowsIcon from 'src/icons/HorizontalArrows'
import BankIcon from 'src/icons/Bank'
import MenuItem from './MenuItem'
import type { CompanyOutletContext } from '../types'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderLeft: '1px solid #DEE0E3',
    borderRight: '1px solid #DEE0E3',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  topContainer: {
    height: 100,
    padding: theme.spacing(2),
    borderBottom: '1px solid #DEE0E3',
  },
  content: {
    display: 'flex',
    flexGrow: 1,
  },
  leftColumn: {
    minWidth: 155,
    width: 155,
    borderRight: '1px solid #DEE0E3',
    padding: theme.spacing(1),
  },
}))

const BusinessAccount = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const accountBalance = useAppSelector((state) => state.company.wallet?.available_balance_in_cents)
  const { clientId, companyId } = useOutletContext<CompanyOutletContext>()

  useEffect(() => {
    if (clientId && companyId) {
      dispatch(fetchBusinessAccountBalance({ clientId, companyId }))
    }
  }, [clientId, companyId, dispatch])

  const handleSnowplow = (value) => {
    dispatch(
      trackSnowplow({
        category: 'company_page',
        action: value,
        ppId: companyId,
      }),
    )
  }

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.topContainer}>
        <Typography variant="subtitle1">Account balance</Typography>
        <Typography variant="h1">{`£${formatCurrencyToTwoDP(accountBalance)}`}</Typography>
      </Box>

      <Box className={classes.content}>
        <Box className={classes.leftColumn}>
          <MenuItem to="" icon={<ArrowsIcon />} onClick={() => handleSnowplow('transactions')}>
            Transactions
          </MenuItem>
          {/* <MenuItem to="#" icon={<StatementsIcon />} onClick={() => handleSnowplow('statements')}>
            Statements
          </MenuItem> */}
          <MenuItem
            to="account-details"
            icon={<BankIcon />}
            onClick={() => handleSnowplow('account_details')}
          >
            Account details
          </MenuItem>
        </Box>

        <Outlet context={{ clientId, companyId }} />
      </Box>
    </Box>
  )
}

export default BusinessAccount

import React from 'react'
import { Box, makeStyles, Theme, Typography } from '@material-ui/core'
import { Check as CheckIcon } from '@gground/capcom.icons'
import HourglassIcon from 'src/icons/Hourglass'
import { OverviewStatus } from 'src/types'

interface StyleProps {
  completed: boolean
  highlighted?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {
    display: 'flex',
    padding: theme.spacing(2),
    borderTop: ({ highlighted }) => `1px solid ${highlighted ? '#FFCF00' : '#DEE0E3'}`,
    backgroundColor: ({ completed, highlighted }) =>
      highlighted ? '#FFEC99' : completed ? '#FFF' : '#F9F9FA',
    color: ({ completed, highlighted }) =>
      highlighted ? '#5C3E00' : completed ? '#1F1F33' : '#4E545F',
  },
  name: {
    lineHeight: '18px',
    marginLeft: theme.spacing(0.5),
  },
  checkIcon: {
    width: 12,
    height: 12,
    margin: '3px 0',
    fill: theme.palette.success.main,
  },
  hourglassIcon: {
    width: 12,
    height: 12,
    margin: '2px 0 4px',
    fill: ({ highlighted }) => (highlighted ? '#5C3E00' : '#4E545F'),
  },
  bulletIcon: {
    width: 6,
    height: 6,
    margin: '5px 3px 7px',
    borderRadius: '50%',
    backgroundColor: '#9CA7B4',
    flex: 'none',
  },
}))

interface StepTaskProps {
  status: OverviewStatus
  name: string
  highlighted?: boolean
}

const StepTask = ({ status, name, highlighted }: StepTaskProps) => {
  const completed = status === OverviewStatus.COMPLETE
  const pending = [OverviewStatus.IN_PROGRESS, OverviewStatus.PENDING_APPROVAL].includes(status)
  const classes = useStyles({ completed, highlighted })

  const icon = completed ? (
    <CheckIcon className={classes.checkIcon} />
  ) : pending ? (
    <HourglassIcon className={classes.hourglassIcon} />
  ) : (
    <Box className={classes.bulletIcon} />
  )

  return (
    <Box className={classes.container}>
      {icon}
      <Typography className={classes.name}>{name}</Typography>
    </Box>
  )
}

export default StepTask

import React from 'react'
import { Dialog, DialogTitle, DialogContent, IconButton, Box } from '@material-ui/core'
import { Close as CloseIcon } from '@gground/capcom.icons'

const ModalDialog: React.FC<{
  title: string
  onClose: () => void
  open: boolean
  forced?: boolean
  className?: string
}> = ({ title, onClose, children, open = true, forced = false, className }) => (
  <Dialog open={open} onClose={onClose} fullWidth className={className || ''}>
    <DialogTitle style={{ paddingRight: 45 }}>
      {title}

      {!forced && (
        <Box position="absolute" top={16} right={16} lineHeight={0}>
          <IconButton aria-label="close" onClick={onClose} style={{ padding: 0 }}>
            <CloseIcon style={{ fontSize: 16 }} />
          </IconButton>
        </Box>
      )}
    </DialogTitle>

    <DialogContent>{children}</DialogContent>
  </Dialog>
)

export default ModalDialog

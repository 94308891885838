import React from 'react'
import { Typography, Box, makeStyles } from '@material-ui/core'
import { FormProvider } from 'react-hook-form'
import { Button } from '@gground/capcom.core'

import Layout from 'src/components/Layout'
import BackButton from 'src/components/BackButton'
import PropertyAddressFormFragment from './PropertyAddressFormFragment'
import PurchaseDetailsFormFragment from './PurchaseDetailsFormFragment'
import TaxQuestionsFormFragment from './TaxQuestionsFormFragment'
import SolicitorDetailsFormFragment from './SolicitorDetailsFormFragment'
import PropertyAgentFormFragment from './PropertyAgentFormFragment'
import useCompanyDetails from './useCompanyDetails'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 600,
    margin: theme.spacing(4, 0),
  },
}))

const CompanyDetailsForm = () => {
  const classes = useStyles()

  const { form, saveAndExit, saveAndContinue, isHoldCo, clientId, companyId, parentCompanyId } =
    useCompanyDetails()

  return (
    <Layout saveAndExit={saveAndExit}>
      <Box className={classes.container}>
        <BackButton
          to={
            !isHoldCo && parentCompanyId
              ? `/dashboard`
              : `/clients/${clientId}/companies/${companyId}/shareholding`
          }
        />
        <Box mt={2}>
          <Typography variant="h1">
            {!isHoldCo ? 'Company details' : 'Subsidiary company details'}
          </Typography>
        </Box>
        <FormProvider {...form}>
          <form onSubmit={saveAndContinue}>
            <PropertyAddressFormFragment />
            <PurchaseDetailsFormFragment />
            <TaxQuestionsFormFragment />
            <SolicitorDetailsFormFragment />
            <PropertyAgentFormFragment />
            <Box mt={4}>
              <Button type="submit">Next</Button>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Layout>
  )
}

export default CompanyDetailsForm

import React from 'react'
import { Outlet } from 'react-router-dom'
import { Typography, Box, makeStyles } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { Arrow as ArrowIcon } from '@gground/capcom.icons'
import { Chip, Tabs } from '@gground/capcom.core'

import Layout from 'src/components/Layout'
import { getOneLineAddress } from 'src/utils/misc'
import useCompany from './useCompany'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
  },
  headerContainer: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '1px solid #DEE0E3',
    padding: theme.spacing(0, 2),
  },
  header: {
    maxWidth: 1008,
    paddingTop: theme.spacing(2),
    width: '100%',
    '& .MuiTabs-root': {
      borderBottom: 'none',
    },
    height: 200,
    display: 'flex',
    flexDirection: 'column',
  },
  backButton: {
    display: 'flex',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#1C444E',
    alignItems: 'center',
    '& svg': {
      transform: 'rotate(180deg)',
    },
    marginBottom: theme.spacing(2),
  },
  chipContainer: {
    display: 'flex',
    '& > :first-child': {
      marginRight: theme.spacing(1),
    },
    marginTop: theme.spacing(1),
  },
  content: {
    maxWidth: 1008,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
}))

const Company = () => {
  const classes = useStyles()
  const {
    isLoading,
    tabs,
    tabValue,
    handleClickBack,
    handleChangeDefaultTabs,
    client,
    propertyPurchase,
    clientId,
    companyId,
  } = useCompany()

  const clientFullName = `${client?.first_name} ${client?.last_name}`
  const companyType = propertyPurchase?.parent_company_id
    ? 'Subsidiary company'
    : propertyPurchase?.is_holding_co
    ? 'Holding company'
    : 'Standalone company'

  return (
    <Layout fullWidth>
      <Box className={classes.container}>
        <Box className={classes.headerContainer}>
          <Box className={classes.header}>
            <Box className={classes.backButton} onClick={handleClickBack}>
              <ArrowIcon />
              <Typography>Back to all companies</Typography>
            </Box>
            {isLoading ? (
              <>
                <Skeleton height={48} width={600} />

                <Skeleton height={22} width={200} />
              </>
            ) : (
              <>
                <Typography variant="h1">
                  {propertyPurchase?.property_address?.address &&
                    getOneLineAddress(propertyPurchase?.property_address?.address, true)}
                </Typography>
                <Box className={classes.chipContainer}>
                  <Chip
                    label={clientFullName}
                    customColors={{
                      background: '#4D6242',
                      text: '#FFFFFF',
                    }}
                  />
                  <Chip
                    label={companyType}
                    customColors={{
                      background: '#F6E8F8',
                      text: '#49184E',
                    }}
                  />
                </Box>
                <Box marginTop="auto">
                  <Tabs
                    tabs={tabs}
                    value={tabValue}
                    onChange={handleChangeDefaultTabs}
                    size="small"
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Box className={classes.content}>
          <Outlet context={{ clientId, companyId }} />
        </Box>
      </Box>
    </Layout>
  )
}

export default Company

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

export const setupSentry = () => {
  Sentry.init({
    dsn: 'https://863b6cd4aada419bb95cd1c0cbb6b161@o348885.ingest.sentry.io/6681821',
    integrations: [new BrowserTracing()],
    environment: import.meta.env.MODE,
    release: `${import.meta.env.VITE_APP_VERSION}`,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

export const setSentryUser = (userId: string) => {
  Sentry.configureScope((scope) => {
    scope.setUser({
      id: userId,
    })
  })
}

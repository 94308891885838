import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as ClientsAPI from 'src/api/clients'
import type {
  FetchClientDetailsOptions,
  UpdateClientDetailsOptions,
  CreateClientOptions,
} from 'src/api/clients'
import type { Client } from 'src/types'

type ClientsState = Record<Client['id'], Client>

const initialState: ClientsState = {}

const createClient = createAsyncThunk<Client, CreateClientOptions>(
  'clients/createClient',
  async (options, thunkAPI) => {
    try {
      return await ClientsAPI.createClient(options)
    } catch (error: any) {
      if (!error.response?.data) throw error
      return thunkAPI.rejectWithValue(error.response.data)
    }
  },
)

const fetchClientDetails = createAsyncThunk<Client, FetchClientDetailsOptions>(
  'clients/fetchDetails',
  (options) => ClientsAPI.fetchClientDetails(options),
)

const updateClientDetails = createAsyncThunk<Client, UpdateClientDetailsOptions>(
  'clients/updateDetails',
  (options) => ClientsAPI.updateClientDetails(options),
)

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchClientDetails.fulfilled, (state, action) => {
      const client = action.payload
      if (client?.id) {
        state[client.id] = client
      }
    })
  },
})

// actions
// export const { } = clientsSlice.actions
export { createClient, fetchClientDetails, updateClientDetails }
// reducer
export default clientsSlice.reducer

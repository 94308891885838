import type { Client, ClientUpdate } from 'src/types'
import { API } from './base'

export type CreateClientOptions = Pick<Client, 'email' | 'first_name' | 'last_name' | 'country'>

export const createClient = async (data: CreateClientOptions): Promise<Client> =>
  API.post(`partner_portal/clients`, data).then((response) => response.data)

export interface FetchClientDetailsOptions {
  id: Client['id']
}

export const fetchClientDetails = async ({ id }: FetchClientDetailsOptions): Promise<Client> =>
  API.get(`partner_portal/clients/${id}`).then((response) => response.data)

export interface UpdateClientDetailsOptions {
  id: Client['id']
  update: ClientUpdate
}

export const updateClientDetails = async ({
  id,
  update,
}: UpdateClientDetailsOptions): Promise<Client> =>
  API.patch(`partner_portal/clients/${id}`, update).then((response) => response.data)

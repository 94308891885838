import React, { useEffect, useRef } from 'react'
import { Box, Grid, InputLabel, makeStyles, MenuItem, Paper, Typography } from '@material-ui/core'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { Input, Select, DatePicker } from '@gground/capcom.form'
import { CURRENCY_SYMBOLS } from 'src/utils/currencySymbols'
import { isCurrencyValue } from 'src/utils/validate'
import { DOCUMENT_TYPES } from 'src/utils/documentTypes'
import { EXPENSE_CATEGORIES } from 'src/utils/expenseCategories'
import { penniesToPounds, poundsToPennies } from 'src/utils/misc'
import { DocumentsForm, FormDoc } from './types'

const useStyles = makeStyles((theme) => ({
  document: {
    background: 'white',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& label': { lineHeight: 1.5 },
  },
  filename: {
    color: '#9CA7B4',
    fontSize: 12,
  },
  remove: {
    color: '#DF3011',
    textDecorationLine: 'underline',
    cursor: 'pointer',
    fontSize: 12,
  },
  currency: {
    '& .MuiInputBase-root': {
      minWidth: 80,
    },
  },
}))

interface DocumentsFormFragmentProps {
  documents: FormDoc[]
  onRemove: (index: number) => void
}

const DocumentsFormFragment = ({ documents, onRemove }: DocumentsFormFragmentProps) => {
  const { control, errors, register, reset, watch, clearErrors, getValues } =
    useFormContext<DocumentsForm>()
  const fieldArray = useFieldArray<FormDoc>({ control, name: 'list' })

  // internal ref, to help reuse options when files are removed
  const documentsRef = useRef<FormDoc[]>([])

  useEffect(() => {
    reset({
      list: documents.map((doc) => {
        // find existing document, by object reference or document id
        const previous =
          getValues().list?.[
            documentsRef.current.findIndex((d) => doc === d || (doc.id && doc.id === d.id))
          ]
        // keep form changes when possible, use original otherwise
        return previous ?? { ...doc }
      }),
    })
    documentsRef.current = documents
  }, [documents])

  const classes = useStyles()

  return (
    <>
      {fieldArray.fields.map((field, index) => {
        const selectedType = watch(`list.${index}.type`)
        const typeConfig = DOCUMENT_TYPES.find(({ type }) => type === selectedType)

        return (
          <Paper key={field.id} elevation={2} className={classes.document}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="caption" className={classes.filename}>
                    {documents[index]?.name}
                  </Typography>
                  <Typography
                    variant="caption"
                    onClick={() => onRemove(index)}
                    className={classes.remove}
                  >
                    Remove
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel required>Document name</InputLabel>
                <Input
                  type="text"
                  id={`list.${index}.name`}
                  name={`list.${index}.name`}
                  defaultValue={field.name}
                  helperText={errors?.list?.[index]?.name?.message}
                  error={!!errors?.list?.[index]?.name}
                  onFocus={() => clearErrors()}
                  required
                  ref={register({
                    required: 'This field is required',
                    maxLength: { value: 125, message: 'You have exceeded the character limit' },
                  })}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name={`list.${index}.type`}
                  control={control}
                  rules={{ validate: (value) => !!value || 'This field is required' }}
                  defaultValue={field.type ?? 0}
                  render={({ onChange, value }) => (
                    <>
                      <InputLabel required>Document type</InputLabel>
                      <Select
                        id={`list.${index}.type`}
                        name={`list.${index}.type`}
                        value={value}
                        onChange={(e) => {
                          // clear properties on type change
                          fieldArray.remove(index)
                          fieldArray.insert(index, { id: field.id, name: field.name, type: value })
                          onChange(e)
                          clearErrors()
                        }}
                        fullWidth
                        error={!!errors?.list?.[index]?.type}
                      >
                        <MenuItem value={0} disabled style={{ display: 'none' }}>
                          Select a type
                        </MenuItem>
                        {DOCUMENT_TYPES.map(({ type, label }) => (
                          <MenuItem key={type} value={type}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                />
              </Grid>
              {typeConfig?.isExpense ? (
                <>
                  <Grid item xs={12} md={6}>
                    <InputLabel required>Merchant name</InputLabel>
                    <Input
                      type="text"
                      id={`list.${index}.merchant_name`}
                      name={`list.${index}.merchant_name`}
                      defaultValue={field.merchant_name ?? ''}
                      helperText={errors?.list?.[index]?.merchant_name?.message}
                      error={!!errors?.list?.[index]?.merchant_name}
                      onFocus={() => clearErrors()}
                      required
                      ref={register({
                        required: 'This field is required',
                        maxLength: {
                          value: 125,
                          message: 'You have exceeded the character limit',
                        },
                      })}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel required>Payment date</InputLabel>
                    <DatePicker
                      name={`list.${index}.document_date`}
                      initialDate={field.document_date}
                      ref={register({ required: 'This field is required' })}
                      onChange={() => clearErrors()}
                      error={!!errors?.list?.[index]?.document_date}
                      helperText={errors?.list?.[index]?.document_date?.message}
                      format="YYYY-MM-DD"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4} md={2} className={classes.currency}>
                    <Controller
                      name={`list.${index}.currency`}
                      defaultValue={field.currency ?? 'GBP'}
                      control={control}
                      rules={{ required: 'This field is required' }}
                      render={({ onChange, value }) => (
                        <>
                          <InputLabel required>Currency</InputLabel>
                          <Select
                            id={`list.${index}.currency`}
                            name={`list.${index}.currency`}
                            value={value}
                            onChange={(e) => {
                              onChange(e)
                              clearErrors()
                            }}
                            fullWidth
                            error={!!errors?.list?.[index]?.currency}
                          >
                            {CURRENCY_SYMBOLS.map(({ shorthand }) => (
                              <MenuItem key={shorthand} value={shorthand}>
                                {shorthand}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={8} md={4}>
                    <InputLabel required>Payment amount</InputLabel>
                    <Input
                      id={`list.${index}.amount_in_cents`}
                      name={`list.${index}.amount_in_cents`}
                      defaultValue={
                        (field.amount_in_cents && penniesToPounds(field.amount_in_cents)) ?? ''
                      }
                      helperText={errors?.list?.[index]?.amount_in_cents?.message}
                      error={!!errors?.list?.[index]?.amount_in_cents}
                      onFocus={() => clearErrors()}
                      required
                      ref={register({
                        setValueAs: (value) => value && poundsToPennies(value),
                        validate: (value) => isCurrencyValue(value) || 'Enter a valid amount',
                      })}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name={`list.${index}.expense_category`}
                      control={control}
                      rules={{ validate: (value) => !!value || 'This field is required' }}
                      defaultValue={field.expense_category ?? 0}
                      render={({ onChange, value }) => (
                        <>
                          <InputLabel required>Expense category</InputLabel>
                          <Select
                            id={`list.${index}.expense_category`}
                            name={`list.${index}.expense_category`}
                            value={value}
                            onChange={(e) => {
                              onChange(e)
                              clearErrors()
                            }}
                            fullWidth
                            error={!!errors?.list?.[index]?.expense_category}
                          >
                            <MenuItem value={0} disabled style={{ display: 'none' }}>
                              Select an expense category
                            </MenuItem>
                            {EXPENSE_CATEGORIES.map(({ category, label }) => (
                              <MenuItem key={category} value={category}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Expense description</InputLabel>
                    <Input
                      type="text"
                      id={`list.${index}.description`}
                      name={`list.${index}.description`}
                      defaultValue={field.description ?? ''}
                      helperText={errors?.list?.[index]?.description?.message}
                      error={!!errors?.list?.[index]?.description}
                      hint="Write anything about this expense that may be helpful for us when filing your returns"
                      onFocus={() => clearErrors()}
                      ref={register({
                        maxLength: {
                          value: 125,
                          message: 'You have exceeded the character limit',
                        },
                      })}
                      fullWidth
                    />
                  </Grid>
                </>
              ) : null}
              {typeConfig?.hasDate ? (
                <Grid item xs={12}>
                  <InputLabel required>What is the date on this document?</InputLabel>
                  <DatePicker
                    name={`list.${index}.document_date`}
                    initialDate={field.document_date}
                    ref={register({ required: 'This field is required' })}
                    onChange={() => clearErrors()}
                    error={!!errors?.list?.[index]?.document_date}
                    helperText={errors?.list?.[index]?.document_date?.message}
                    format="YYYY-MM-DD"
                    fullWidth
                  />
                </Grid>
              ) : null}
            </Grid>
          </Paper>
        )
      })}
    </>
  )
}

export default DocumentsFormFragment

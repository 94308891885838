import React, { useCallback } from 'react'
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { Checkbox } from '@gground/capcom.form'
import { Document } from 'src/types'
import { useOutletContext } from 'react-router-dom'
import { useAppSelector } from 'src/store'
import DocumentListItem from './DocumentListItem'
import { CompanyOutletContext } from '../types'

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginBottom: theme.spacing(2),
    '& th': {
      color: '#4E545F',
    },
    '& th, & td': {
      padding: 8,
      '&:first-child': {
        paddingRight: 0,
        width: 0,
      },
    },
  },
}))

interface DocumentsListProps {
  documents: Document[]
  loading: boolean
  selected: Record<Document['id'], boolean>
  onToggle: (id: Document['id']) => void
  onToggleAll: () => void
  onView: (id: Document['id']) => void
  onDownload: (id: Document['id']) => void
  onDelete: (id: Document['id']) => void
  onEdit: (id: Document['id']) => void
}

const DocumentsList = ({
  documents,
  loading,
  selected,
  onToggle,
  onToggleAll,
  onView,
  onDownload,
  onDelete,
  onEdit,
}: DocumentsListProps) => {
  const { companyId } = useOutletContext<CompanyOutletContext>()
  const shareholders = useAppSelector(
    (store) =>
      Object.values(store.propertyPurchases).find((pp) => pp.company_id === companyId)
        ?.shareholder_details.shareholders ?? [],
  )
  const getCreatorName = useCallback(
    (document: Document) => {
      if (document.creator_type === 'user') {
        const creator = shareholders.find((s) => s.user_id === document.creator)
        return creator ? `${creator.first_name} ${creator.last_name}` : '-'
      }

      return document.creator_type === 'partner' ? 'Partner' : 'GetGround'
    },
    [shareholders],
  )
  const classes = useStyles()
  const allSelected = !loading && Object.values(selected).every((s) => !!s)
  const someSelected = !allSelected && Object.values(selected).some((s) => !!s)

  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead>
          {loading && (
            <TableRow>
              <TableCell colSpan={6} style={{ height: 42 }} />
            </TableRow>
          )}
          {!loading && (
            <TableRow>
              <TableCell>
                <Checkbox
                  name="select-all"
                  disabled={loading}
                  checked={allSelected}
                  indeterminate={someSelected}
                  onChange={onToggleAll}
                />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Upload date</TableCell>
              <TableCell>Uploaded by</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>{/* actions */}</TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {loading &&
            Array.from({ length: 3 }).map((_, i) => (
              <TableRow key={i}>
                <TableCell colSpan={6} style={{ padding: '8px 24px' }}>
                  <Skeleton height={26} />
                </TableCell>
              </TableRow>
            ))}
          {!loading &&
            [...documents]
              .sort((a, b) => new Date(b.upload_date).getTime() - new Date(a.upload_date).getTime())
              .map((document) => (
                <DocumentListItem
                  document={document}
                  onToggle={() => onToggle(document.id)}
                  onView={() => onView(document.id)}
                  onDelete={() => onDelete(document.id)}
                  onEdit={() => onEdit(document.id)}
                  onDownload={() => onDownload(document.id)}
                  selected={!!selected[document.id]}
                  creator={getCreatorName(document)}
                  key={document.id}
                />
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DocumentsList

import React from 'react'
import { makeStyles, Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Button } from '@gground/capcom.core'

import GGLogo from 'src/icons/GGLogo'
import { useAppDispatch, useAppSelector } from 'src/store'
import { signOut } from 'src/store/slices/user.slice'

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.info.main,
    height: 64,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 4),
  },
  container: {
    maxWidth: 1008,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    display: 'block',
    color: '#FFF',
    width: 106,
    height: 32,
    cursor: 'pointer',
  },
  saveAndExit: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  logOut: {
    color: 'white',
    fontWeight: 500,
    cursor: 'pointer',
    flex: 'none',
    margin: theme.spacing(1, 1, 1, 3),
  },
}))

interface HeaderProps {
  saveAndExit?: () => void
}

const Header = ({ saveAndExit }: HeaderProps) => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const auth = useAppSelector((state) => state.user.auth)

  const handleLogoutClick = () => dispatch(signOut())

  return (
    <header className={classes.header}>
      <Box className={classes.container}>
        <Link to="/dashboard">
          <GGLogo className={classes.logo} />
        </Link>
        {saveAndExit && (
          <Box className={classes.saveAndExit} ml={4} mr={1}>
            <Button variant="whiteLift" onClick={saveAndExit}>
              Save and exit
            </Button>
          </Box>
        )}

        {auth.status !== 'UNAUTHENTICATED' ? (
          <Box role="button" tabIndex={0} className={classes.logOut} onClick={handleLogoutClick}>
            Log out
          </Box>
        ) : null}
      </Box>
    </header>
  )
}

export default Header

import React, { useEffect, useState } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'

interface StyleProps {
  isActive: boolean
}

const useStyles = makeStyles({
  button: {
    color: '#2A6574',
    textAlign: 'left',
    height: 46,
    marginBottom: 6,
    justifyContent: 'start',
    backgroundColor: ({ isActive }: StyleProps) => (isActive ? '#E8F4F7' : 'transparent'),
    '&:hover': {
      backgroundColor: ({ isActive }: StyleProps) => (isActive ? '#E8F4F7' : 'initital'),
    },
  },
})
interface Props {
  to: string
  onClick?: () => void
  children: React.ReactNode
  icon: React.ReactNode
}

const MenuItem = ({ to, onClick, children, icon }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isActive, setIsActive] = useState(false)
  const classes = useStyles({ isActive })
  const last = location.pathname.split('/').pop()

  useEffect(() => {
    if (last === to || (last === 'business-account' && to === '')) {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }, [last])

  const handleClick = () => {
    navigate(to)
    if (onClick) {
      onClick()
    }
  }

  return (
    <Button startIcon={icon} fullWidth className={classes.button} onClick={handleClick}>
      {children}
    </Button>
  )
}

export default MenuItem

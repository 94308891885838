import React from 'react'
import { Box, Typography, DialogActions } from '@material-ui/core'
import { Button } from '@gground/capcom.core'
import ModalDialog from 'src/components/ModalDialog/ModalDialog'

interface ConfirmDeleteModalProps {
  open: boolean
  onCancel: () => void
  onConfirm: () => void
}

const ConfirmDeleteModal = ({ open, onCancel, onConfirm }: ConfirmDeleteModalProps) => (
  <ModalDialog open={open} onClose={onCancel} title="Delete document">
    <Box mt={1}>
      <Typography>Confirm that you would like to delete the selected documents.</Typography>
    </Box>
    <DialogActions>
      <Button variant="text" onClick={onCancel}>
        Cancel
      </Button>
      <Button variant="contained" palette="danger" onClick={onConfirm}>
        Delete
      </Button>
    </DialogActions>
  </ModalDialog>
)

export default ConfirmDeleteModal

import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const SpeechIcon = (props: SvgIconProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1294 10.2429C16.1785 10.2329 15.4033 10.9423 15.3842 11.84C15.365 12.737 16.1627 13.5222 17.0922 13.5212C17.977 13.5203 18.7697 12.7727 18.7862 11.9237C18.804 11.0159 18.0515 10.2525 17.1294 10.2429ZM12.0033 10.2434C11.082 10.2378 10.2937 10.9752 10.2944 11.8416C10.2953 12.7427 11.1045 13.5399 12.0076 13.5294C12.9015 13.5189 13.702 12.7421 13.6994 11.8877C13.6967 10.9816 12.9411 10.2491 12.0033 10.2434ZM8.61283 11.8732C8.61592 10.9835 7.84294 10.2464 6.90298 10.2429C5.99915 10.2395 5.21497 10.9709 5.20731 11.8246C5.19919 12.7258 5.98789 13.5197 6.90124 13.5299C7.7891 13.5398 8.6098 12.7454 8.61283 11.8732ZM20.499 20.5713C19.9089 20.3519 19.3186 20.1329 18.7286 19.9132C18.0968 19.6779 17.4676 19.4355 16.8316 19.212C16.713 19.1703 16.5522 19.1641 16.4367 19.2078C14.873 19.7987 13.2524 20.0723 11.5836 20.0087C9.06179 19.9125 6.75366 19.1852 4.76725 17.6105C3.05766 16.2552 1.96252 14.5287 1.74899 12.352C1.58118 10.6419 2.02466 9.05601 3.0462 7.65272C4.75675 5.30288 7.15045 4.05203 9.99427 3.59908C12.7362 3.16237 15.3648 3.5545 17.8094 4.88557C19.4833 5.79692 20.8253 7.06464 21.6196 8.79938C22.5995 10.9402 22.4868 13.0658 21.3582 15.1394C20.9016 15.9783 20.3011 16.7013 19.5704 17.3167C19.321 17.5268 19.2946 17.7153 19.4355 18.0002C19.7783 18.693 20.0916 19.3999 20.4136 20.1026C20.4736 20.2333 20.5188 20.3706 20.5708 20.5049C20.5468 20.5271 20.5229 20.5492 20.499 20.5713Z"
      fill="#2A6574"
    />
  </SvgIcon>
)

export default SpeechIcon

import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Upload = (props: SvgIconProps) => (
  // eslint-disable-line react/jsx-props-no-spreading
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.57146 11.4286C3.96595 11.4286 4.28575 11.7484 4.28575 12.1429V15C4.28575 15.1895 4.361 15.3711 4.49496 15.5051C4.62891 15.6391 4.8106 15.7143 5.00004 15.7143H15C15.1895 15.7143 15.3712 15.6391 15.5051 15.5051C15.6391 15.3711 15.7143 15.1895 15.7143 15V12.1429C15.7143 11.7484 16.0341 11.4286 16.4286 11.4286C16.8231 11.4286 17.1429 11.7484 17.1429 12.1429V15C17.1429 15.5683 16.9171 16.1134 16.5153 16.5152C16.1134 16.9171 15.5684 17.1429 15 17.1429H5.00004C4.43171 17.1429 3.88667 16.9171 3.48481 16.5152C3.08294 16.1134 2.85718 15.5683 2.85718 15V12.1429C2.85718 11.7484 3.17697 11.4286 3.57146 11.4286Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.49499 3.06636C9.77394 2.78741 10.2262 2.78741 10.5051 3.06636L14.0766 6.63779C14.3555 6.91673 14.3555 7.36899 14.0766 7.64794C13.7976 7.92688 13.3454 7.92688 13.0664 7.64794L10.0001 4.58159L6.93372 7.64794C6.65477 7.92688 6.20251 7.92688 5.92356 7.64794C5.64462 7.36899 5.64462 6.91673 5.92356 6.63779L9.49499 3.06636Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99993 2.85715C10.3944 2.85715 10.7142 3.17694 10.7142 3.57143V12.1429C10.7142 12.5374 10.3944 12.8571 9.99993 12.8571C9.60544 12.8571 9.28564 12.5374 9.28564 12.1429V3.57143C9.28564 3.17694 9.60544 2.85715 9.99993 2.85715Z"
    />
  </SvgIcon>
)

export default Upload

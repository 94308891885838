import React from 'react'
import { OverviewStatus, PropertyPurchaseOverview } from 'src/types'
import ProgressStep from './ProgressStep'
import StepTask from './StepTask'

interface CompanyFormationStepProps {
  propertyPurchase?: PropertyPurchaseOverview
}

const CompanyFormationStep = ({ propertyPurchase }: CompanyFormationStepProps) => {
  const { onboarding, incorporation, signing } = propertyPurchase?.company_formation ?? {}
  const onboardingStatus = onboarding ?? OverviewStatus.IN_PROGRESS
  const incorporationStatus = incorporation ?? OverviewStatus.NOT_STARTED
  const signingStatus = signing ?? OverviewStatus.NOT_STARTED

  // derive step status from client approval step and subtask status
  const approval = propertyPurchase?.client_approval ?? OverviewStatus.TODO
  const status =
    signingStatus === OverviewStatus.COMPLETE
      ? OverviewStatus.COMPLETE
      : approval === OverviewStatus.COMPLETE
      ? OverviewStatus.IN_PROGRESS
      : OverviewStatus.NOT_STARTED

  return (
    <ProgressStep status={status} name="Company formation" position={4}>
      {status === OverviewStatus.IN_PROGRESS ? (
        <>
          <StepTask
            name="All shareholders onboarded"
            status={onboardingStatus}
            highlighted={onboardingStatus === OverviewStatus.IN_PROGRESS}
          />
          <StepTask
            name="Company incorporation"
            status={incorporationStatus}
            highlighted={incorporationStatus === OverviewStatus.IN_PROGRESS}
          />
          <StepTask
            name="Client to sign company documents"
            status={signingStatus}
            highlighted={signingStatus === OverviewStatus.IN_PROGRESS}
          />
        </>
      ) : null}
    </ProgressStep>
  )
}

export default CompanyFormationStep

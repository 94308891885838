import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  Typography,
  Box,
  makeStyles,
  MenuItem,
  RadioGroup,
  FormHelperText,
} from '@material-ui/core'
import { Input, Radio, Select, Checkbox } from '@gground/capcom.form'

import { validateWhitespace } from './utils'

const useStyles = makeStyles((theme) => ({
  subTitle: {
    color: theme.palette.info.main,
  },
}))

const PropertyAddressFormFragment = () => {
  const classes = useStyles()
  const { control, errors, register, watch, setValue, clearErrors } = useFormContext()

  const skipAddress = watch('skipped', false)
  const isNewBuild = watch('is_new_build', false)

  return (
    <Box component="section">
      <Box my={2}>
        <Typography variant="h2" className={classes.subTitle}>
          Property Address
        </Typography>
      </Box>
      <Box mb={2}>
        <Checkbox
          id="skipped"
          name="skipped"
          label="They don’t know which property they want to buy yet - we will provide this information to the 
GetGround platform when they do."
          ref={register()}
          checked={skipAddress}
          onChange={() => {
            setValue('skipped', !skipAddress)
            clearErrors()
          }}
        />
      </Box>
      <Controller
        name="country"
        control={control}
        render={({ onChange, value }) => (
          <Select
            name="country"
            label="Country"
            fullWidth
            onChange={(e) => onChange(e)}
            value={value ?? ''}
            disabled={skipAddress}
            error={Boolean(errors.country)}
          >
            <MenuItem value="United Kingdom of Great Britain and Northern Ireland">
              England
            </MenuItem>
            <MenuItem value="Wales">Wales</MenuItem>
            <MenuItem value="Scotland">Scotland</MenuItem>
          </Select>
        )}
      />
      <Box my={3}>
        <Typography>Is this property a new build?</Typography>

        <Controller
          control={control}
          name="is_new_build"
          defaultValue={isNewBuild}
          as={
            <RadioGroup>
              <Radio
                name="is_new_build"
                value="yes"
                disabled={skipAddress}
                label="Yes"
                error={Boolean(errors.is_new_build)}
              />
              <Radio
                name="is_new_build"
                value="no"
                disabled={skipAddress}
                label="No"
                error={Boolean(errors.is_new_build)}
              />
            </RadioGroup>
          }
        />
        {errors?.is_new_build && (
          <FormHelperText error>{errors.is_new_build?.message}</FormHelperText>
        )}
      </Box>
      <Input
        label="Unit number"
        name="premise"
        id="premise"
        fullWidth
        disabled={skipAddress}
        ref={register({
          validate: {
            validateWhitespace: skipAddress ? () => true : validateWhitespace,
          },
        })}
        error={Boolean(errors.premise)}
        helperText={errors.premise && errors.premise?.message}
      />
      <Box mt={2}>
        <Input
          label="Street"
          name="street"
          id="street"
          type="text"
          fullWidth
          disabled={skipAddress}
          ref={register({
            validate: {
              validateWhitespace: skipAddress ? () => true : validateWhitespace,
            },
          })}
          error={Boolean(errors.street)}
          helperText={errors.street && errors.street?.message}
        />
      </Box>
      <Box mt={2}>
        <Input
          label="City"
          name="posttown"
          id="posttown"
          fullWidth
          disabled={skipAddress}
          ref={register({
            validate: {
              validateWhitespace: skipAddress ? () => true : validateWhitespace,
            },
          })}
          error={Boolean(errors.posttown)}
          helperText={errors.posttown && errors.posttown?.message}
        />
      </Box>
      <Box mt={2}>
        <Input
          label="Postcode"
          name="postcode"
          id="postcode"
          type="text"
          fullWidth
          disabled={skipAddress}
          hint="Please enter only the postcode, not the country"
          ref={register({
            validate: {
              validateWhitespace: skipAddress ? () => true : validateWhitespace,
            },
          })}
          error={Boolean(errors.postcode)}
          helperText={errors.postcode && errors.postcode?.message}
        />
      </Box>
    </Box>
  )
}

export default PropertyAddressFormFragment

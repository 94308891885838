import React from 'react'
import { makeStyles, Box, Theme } from '@material-ui/core'

import Header from '../Header'

interface StyleProps {
  fullWidth?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  main: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    padding: ({ fullWidth }) => (fullWidth ? 0 : theme.spacing(0, 4)),
  },
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: ({ fullWidth }) => (fullWidth ? 'unset' : 1008),
  },
}))

interface LayoutProps {
  children: React.ReactNode
  fullWidth?: boolean
  saveAndExit?: () => void
}

const Layout: React.FC<LayoutProps> = ({ children, fullWidth, saveAndExit }) => {
  const classes = useStyles({ fullWidth })
  return (
    <>
      <Header saveAndExit={saveAndExit} />
      <main className={classes.main}>
        <Box className={classes.container}>{children}</Box>
      </main>
    </>
  )
}

export default Layout

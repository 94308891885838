import React from 'react'
import { Typography, Box, makeStyles } from '@material-ui/core'
import { Button } from '@gground/capcom.core'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    background: '#FFFFFF',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
    borderRadius: 8,
    padding: theme.spacing(3),

    '& > button': {
      marginTop: 'auto',
      width: '100%',
    },
  },
  title: {
    color: theme.palette.info.main,
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: 600,
  },
  top: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
  },
}))

interface Props {
  title: string
  cta: string
  image: string
  description: React.ReactElement
  onClick: () => void
}

const StructureOption = ({ title, cta, image, description, onClick }: Props) => {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Box className={classes.top}>
        <Typography className={classes.title}>{title}</Typography>
        <img alt={title} src={image} height={64} />
      </Box>
      {description}
      <Button onClick={onClick}>{cta}</Button>
    </Box>
  )
}

export default StructureOption

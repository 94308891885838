import JSZip from 'jszip'
import axios from 'axios'
import { API } from './base'

const downloadBlob = (blob: Blob, filename: string) => {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.setAttribute('download', filename)
  link.click()
}

export const downloadInternalFile = (path: string, filename: string): Promise<void> =>
  API.get(path, { responseType: 'blob' }).then((response) => downloadBlob(response.data, filename))

export const downloadExternalFile = (url: string, filename: string): Promise<void> =>
  axios.get(url, { responseType: 'blob' }).then((response) => downloadBlob(response.data, filename))

const createUniqueFilenameGenerator = () => {
  const filenameCounter: Record<string, number> = {}

  const getUniqueFilename = (filename: string): string => {
    if (!filenameCounter[filename]) {
      filenameCounter[filename] = 1
      return filename
    }
    const count = filenameCounter[filename]
    const [name = '', extension = ''] = filename.match(/^(.*?)(?:(\.\w+))?$/)?.slice(1, 3) ?? []
    filenameCounter[filename] = count + 1
    return getUniqueFilename(`${name} (${count})${extension}`)
  }

  return getUniqueFilename
}

export const downloadExternalFiles = (
  files: { name: string; url: string }[],
  output: string,
): Promise<void> => {
  const zip = new JSZip()
  const getUniqueName = createUniqueFilenameGenerator()

  return Promise.all(
    files.map((f) =>
      axios
        .get(f.url, { responseType: 'blob' })
        .then((r) => zip.file(getUniqueName(f.name), r.data)),
    ),
  )
    .then(() => zip.generateAsync({ type: 'blob' }))
    .then((blob) => downloadBlob(blob, output))
}

interface SignedUploadURL {
  filename: string
  headers: Record<string, string>
  signed_url: string
  object_name: string
}

export const getSignedUploadURL = (
  clientId: number,
  companyId: number,
  filename: string,
  mimeType: string,
) =>
  API.post(`partner_portal/clients/${clientId}/companies/${companyId}/files/signed_url_v4`, [
    { filename, mime_type: mimeType },
  ]).then((response) => {
    return response.data[0] as SignedUploadURL
  })

export const uploadFile = (file: File, url: string, headers: Record<string, string> = {}) => {
  const formData = new FormData()
  Object.entries(headers).forEach(([name, value]) => formData.append(name, value))
  formData.append('file', file)

  // setting "mode" is not supported by axios: https://github.com/axios/axios/issues/1358
  return fetch(url, {
    method: 'POST',
    body: formData,
    mode: 'no-cors',
  })
}

interface SourceOfFundsTypes {
  key: string
  label: string
}

export const getSourceOfFundsTypes = (): Promise<SourceOfFundsTypes[]> =>
  API.get(`constants/source_of_funds/invitation`).then((response) => response.data)

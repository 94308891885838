import React, { useEffect } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { Typography, Box, makeStyles } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { Input, Checkbox } from '@gground/capcom.form'
import { Button } from '@gground/capcom.core'

import Layout from 'src/components/Layout'
import PasswordInput from 'src/components/PasswordInput'
import { useAppDispatch } from 'src/store'
import { signUp, trackSnowplow } from 'src/store/slices/user.slice'
import { showErrorToast } from 'src/utils/toasts'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(3, 'auto', 0),
    maxWidth: 600,
    width: '100%',
  },
  passwordHint: {
    paddingLeft: 0,
    listStyle: 'none',
    margin: 0,
    '& li': {
      marginBottom: 4,
    },
  },
  policy: {
    color: '#000',
    textDecoration: 'underline',
  },
}))

interface FormSubmitParams {
  password: string
}

interface LocationState {
  signUpEmail: string
  signUpCode: string
}

const SignUp = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const {
    handleSubmit,
    errors,
    register,
    watch,
    clearErrors,
    formState: { isSubmitted },
  } = useForm({ shouldFocusError: false, reValidateMode: 'onSubmit' })

  const { signUpEmail, signUpCode } = (location.state ?? {}) as LocationState

  if (!signUpEmail || !signUpCode) {
    return <Navigate to="/" replace />
  }

  useEffect(() => {
    dispatch(trackSnowplow({ category: 'signup', action: 'signup_visit' }))
  }, [])

  const onSubmit = ({ password }: FormSubmitParams) => {
    dispatch(trackSnowplow({ category: 'signup', action: 'signup_register' }))
    dispatch(signUp({ email: signUpEmail, code: signUpCode, password }))
      .unwrap()
      .then(() => navigate('2fa'))
      .catch(() => showErrorToast('Sign up failed. Please try again.'))
  }

  return (
    <Layout>
      <Box className={classes.container}>
        <Typography variant="h1">
          Sign up to the GetGround
          <br />
          Accountant Portal
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={2}>
            <Input
              name="email"
              id="email"
              label="Email"
              hint="This is the address you will login with"
              type="text"
              disabled
              fullWidth
              value={signUpEmail}
            />
          </Box>
          <Box mt={2}>
            <PasswordInput
              withHints
              errors={errors}
              register={register}
              watch={watch}
              onFocus={() => clearErrors()}
              isFormSubmitted={isSubmitted}
            />
          </Box>
          <Box mt={2}>
            <Typography>
              <Checkbox
                name="acceptedTermsAndPolicy"
                ref={register({ required: 'Please provide your consent before signing up' })}
                label={
                  <span>
                    I understand that by signing up I accept and am bound by the{' '}
                    <a
                      className={classes.policy}
                      href="https://www.getground.co.uk/portal-terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Platform Terms and Conditions
                    </a>
                    <span> and </span>
                    <a
                      className={classes.policy}
                      href="https://www.getground.co.uk/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </span>
                }
                error={Boolean(errors.acceptedTermsAndPolicy)}
                errorText={errors.acceptedTermsAndPolicy && errors.acceptedTermsAndPolicy?.message}
              />
            </Typography>
          </Box>
          <Box my={3}>
            <Button type="submit">Sign up</Button>
          </Box>
        </form>
        <Typography>
          Already signed up to GetGround?{' '}
          <Link
            to="/sign-in"
            onClick={() =>
              dispatch(trackSnowplow({ category: 'signup', action: 'signup_to_login' }))
            }
          >
            Login instead
          </Link>
        </Typography>
      </Box>
    </Layout>
  )
}

export default SignUp

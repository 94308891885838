import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const CheckboxCheckedIcon = (props: SvgIconProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <rect x="2" y="2" width="20" height="20" rx="4" />
    <rect
      fill="white"
      transform="matrix(0.685888 -0.685886 0.685888 0.685886 7 12.364)"
      width="2.06187"
      height="5.15468"
    />
    <rect
      fill="white"
      transform="rotate(-45 9.12134 14.4853)"
      x="9.12134"
      y="14.4853"
      width="8.99999"
      height="2"
    />
  </SvgIcon>
)

export default CheckboxCheckedIcon

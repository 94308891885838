import React from 'react'
import dayjs from 'dayjs'
import { Typography, Box, makeStyles, Grid, MenuItem, Popover } from '@material-ui/core'
import { Chip } from '@gground/capcom.core'
import { Plus as PlusIcon } from '@gground/capcom.icons'

import { formatCurrencyToTwoDP } from 'src/utils/misc'
import { Transaction as TransactionType } from 'src/types'
import OtherCategoryAdditionalInfo from './OtherCategoryAdditionalInfo'
import useTransaction from './useTransaction'

const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    display: 'contents',
    '&:nth-child(even) .MuiGrid-item': {
      backgroundColor: '#F9F9FA',
    },
    '& .MuiGrid-item': {
      padding: theme.spacing(1),
    },
  },
  paidIn: {
    color: '#2E7F3A',
    fontWeight: 600,
  },
  categoryContainer: {
    '& :first-child': {
      cursor: 'pointer',
    },
  },
  category: {
    color: '#1C444E',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    '& > :first-child': {
      marginRight: theme.spacing(0.5),
    },
    '& svg': {
      fontSize: 19,
    },
  },
  details: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'baseline !important',
    '& > :first-child': {
      fontWeight: 500,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '100%',
    },
  },
  transactionStatus: {
    lineHeight: 0,
    fontSize: 12,
    transform: 'translateY(4px)',
  },
  contentToEnd: {
    flexDirection: 'column',
    alignItems: 'flex-end !important',
    minWidth: 30,
  },
  categoriesMenu: {
    '& .MuiPopover-paper': {
      backgroundColor: '#FFFFFF',
      maxHeight: 270,
      minWidth: 238,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    '& .MuiListItem-root': {
      backgroundColor: '#FFFFFF',
      minWidth: 238,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      height: 37,
      '& svg': {
        color: '#1C444E',
        marginRight: 8,
      },
    },
    '& .MuiListItem-button:hover, & .Mui-selected': {
      backgroundColor: '#E8F4F7',
    },
  },
}))

interface Props {
  tx: TransactionType
  clientId: number
  companyId: number
  showFees?: boolean
  showBalance?: boolean
}

const Transaction = ({ tx, clientId, companyId, showFees, showBalance }: Props) => {
  const classes = useStyles()

  const {
    anchorEl,
    txsIconsMap,
    isAdditionalInfoOpen,
    selectedCategory,
    handleCloseMenu,
    handleChangeCategory,
    handleOpenCategoriesMenu,
    openCategoriesMenu,
    submitAdditionalInfo,
  } = useTransaction({
    tx,
    clientId,
    companyId,
  })

  const transactionStatus = tx.status
  const isTransationPending = ['NONE', 'ATTEMPTED', 'ACKNOWLEDGED'].includes(transactionStatus)
  const isTransactionFailed = ['ERROR', 'FAILED', 'REJECTED'].includes(transactionStatus)
  const feeStatus = tx.fee?.status || ''
  const isFeePending = ['PENDING', 'UNKNOWN'].includes(feeStatus)
  const isFeeFailed = feeStatus === 'FAILED'
  const absFeeInCents = isTransactionFailed ? 0 : Math.abs(tx.fee?.amount_in_cents || 0)
  const absAmountInCents = Math.abs(tx.amount_in_cents || 0)
  const netAmountInCents =
    tx.direction === 'INBOUND' ? absAmountInCents - absFeeInCents : absAmountInCents + absFeeInCents

  const amountGridItemSize = showFees && showBalance ? 3 : showFees || showBalance ? 4 : 6

  return (
    <Box className={classes.itemWrapper} key={tx.date}>
      <Grid item xs={2}>
        <Typography>{dayjs(tx.date).format('DD MMM, YYYY')}</Typography>
      </Grid>
      <Grid item xs={3} className={classes.details}>
        <Typography>{tx.direction === 'INBOUND' ? tx.payer : tx.payee}</Typography>
        {tx.reference && <Typography variant="subtitle1">{tx.reference}</Typography>}
      </Grid>
      <Grid item xs={2}>
        <Box className={classes.categoryContainer} onClick={handleOpenCategoriesMenu}>
          {selectedCategory ? (
            <Typography className={classes.category}>
              {React.createElement(selectedCategory.icon)}
              <span>{selectedCategory.label}</span>
            </Typography>
          ) : (
            <Chip label="Add category" icon={<PlusIcon />} />
          )}
        </Box>
        <Popover
          id="categories-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          className={classes.categoriesMenu}
          transitionDuration={200}
          anchorOrigin={{ vertical: 30, horizontal: 0 }}
        >
          {isAdditionalInfoOpen ? (
            <OtherCategoryAdditionalInfo
              onClose={handleCloseMenu}
              onChangeCategory={openCategoriesMenu}
              onSubmit={submitAdditionalInfo}
              defaultValue={tx.category_additional_info}
            />
          ) : (
            Object.values(txsIconsMap).map(({ icon, label, category, subcategory }) => {
              const isSelected =
                selectedCategory?.category === category &&
                selectedCategory?.subcategory === subcategory
              const isPotTransaction = selectedCategory?.category === 'INVESTMENT_POT_TRANSFER'

              const disabled = isSelected || isPotTransaction
              return (!isPotTransaction && category !== 'INVESTMENT_POT_TRANSFER') ||
                isPotTransaction ? (
                <MenuItem
                  key={label}
                  onClick={disabled ? () => {} : () => handleChangeCategory(category, subcategory)}
                  selected={isSelected}
                  style={{ cursor: disabled ? 'default' : 'pointer' }}
                >
                  {React.createElement(icon)}
                  {label}
                </MenuItem>
              ) : null
            })
          )}
        </Popover>
      </Grid>
      <Grid container item xs={5}>
        <Grid item xs={amountGridItemSize} className={classes.contentToEnd}>
          {tx.direction === 'INBOUND' && (
            <>
              <Typography className={classes.paidIn}>
                {`+${formatCurrencyToTwoDP(isFeeFailed ? absAmountInCents : netAmountInCents)}`}
              </Typography>
              {isTransationPending ? (
                <Typography className={classes.transactionStatus} color="secondary">
                  pending
                </Typography>
              ) : isTransactionFailed ? (
                <Typography className={classes.transactionStatus} color="error">
                  failed
                </Typography>
              ) : null}
            </>
          )}
        </Grid>
        <Grid item xs={amountGridItemSize} className={classes.contentToEnd}>
          {tx.direction === 'OUTBOUND' && (
            <>
              <Typography>
                {formatCurrencyToTwoDP(isFeeFailed ? absAmountInCents : netAmountInCents)}
              </Typography>
              {isTransationPending ? (
                <Typography className={classes.transactionStatus} color="secondary">
                  pending
                </Typography>
              ) : isTransactionFailed ? (
                <Typography className={classes.transactionStatus} color="error">
                  failed
                </Typography>
              ) : null}
            </>
          )}
        </Grid>
        {showFees ? (
          <Grid item xs={amountGridItemSize} className={classes.contentToEnd}>
            {!isTransactionFailed && absFeeInCents ? (
              <>
                <Typography>
                  {!isFeeFailed ? formatCurrencyToTwoDP(absFeeInCents) : 'failed'}
                </Typography>
                {isFeePending ? <Typography variant="caption">pending</Typography> : null}
              </>
            ) : null}
          </Grid>
        ) : null}
        {showBalance ? (
          <Grid item xs={amountGridItemSize} className={classes.contentToEnd}>
            <Typography>{formatCurrencyToTwoDP(tx.balance_in_cents)}</Typography>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  )
}

export default Transaction

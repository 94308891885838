import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import PhoneNumberInput from 'src/components/PhoneNumberInput'
import type { ClientFormFields } from './types'

const PhoneNumberFormFragment = () => {
  const { clearErrors, control, errors } = useFormContext<ClientFormFields>()

  return (
    <Box mt={4} component="section">
      <Typography variant="h2" style={{ fontWeight: 500 }}>
        Phone number
      </Typography>
      <Typography>
        We do not share their phone number with anyone under any circumstances.
      </Typography>
      <Box mt={2}>
        <PhoneNumberInput
          control={control}
          name="phone_number"
          disabled={false}
          notRequired
          inputFullWidth
          error={errors.phone_number}
          clearErrors={clearErrors}
        />
      </Box>
    </Box>
  )
}

export default PhoneNumberFormFragment

import React from 'react'
import { Card, CardHeader, CardContent, Grid, Box, Typography, makeStyles } from '@material-ui/core'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { showSuccessToast } from 'src/utils/toasts'
import type { CompanyDetails } from 'src/types'

const useStyles = makeStyles({
  box: {
    background: '#F9F9FA',
    borderRadius: 4,
    padding: 8,
    cursor: 'pointer',
    height: 58,
  },
  name: {
    color: '#4E545F',
  },
  value: {
    fontWeight: 500,
  },
})

interface Props {
  solicitorDetails: CompanyDetails['solicitor_details']
}

const PropertyPurchaseDetailsSection = ({ solicitorDetails }: Props) => {
  const classes = useStyles()
  const firstName = solicitorDetails?.first_name || ''
  const lastName = solicitorDetails?.last_name || ''
  const email = solicitorDetails?.email || ''
  const phoneNumber = solicitorDetails?.phone_number || ''
  const caseReference = solicitorDetails?.case_reference || ''

  return (
    <Card>
      <CardHeader
        title="Solicitor details"
        action={
          <Box>
            <Typography variant="subtitle1" component="i">
              Hint: Click on a field to copy it
            </Typography>
          </Box>
        }
      />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <Box className={classes.box}>
              <Typography variant="body1" className={classes.name}>
                First name:
              </Typography>
              <CopyToClipboard text={firstName} onCopy={() => showSuccessToast('Copied')}>
                <Typography variant="body1" className={classes.value}>
                  {firstName}
                </Typography>
              </CopyToClipboard>
            </Box>
          </Grid>
          <Grid item sm={4}>
            <Box className={classes.box}>
              <Typography variant="body1" className={classes.name}>
                Last name:
              </Typography>
              <CopyToClipboard text={lastName} onCopy={() => showSuccessToast('Copied')}>
                <Typography variant="body1" className={classes.value}>
                  {lastName}
                </Typography>
              </CopyToClipboard>
            </Box>
          </Grid>
          <Grid item sm={4}>
            <Box className={classes.box}>
              <Typography variant="body1" className={classes.name}>
                Email:
              </Typography>
              <CopyToClipboard text={email} onCopy={() => showSuccessToast('Copied')}>
                <Typography variant="body1" className={classes.value}>
                  {email}
                </Typography>
              </CopyToClipboard>
            </Box>
          </Grid>
          <Grid item sm={4}>
            <Box className={classes.box}>
              <Typography variant="body1" className={classes.name}>
                Phone number:
              </Typography>
              <CopyToClipboard text={phoneNumber} onCopy={() => showSuccessToast('Copied')}>
                <Typography variant="body1" className={classes.value}>
                  {phoneNumber}
                </Typography>
              </CopyToClipboard>
            </Box>
          </Grid>
          <Grid item sm={4}>
            <Box className={classes.box}>
              <Typography variant="body1" className={classes.name}>
                Case reference:
              </Typography>
              <CopyToClipboard text={caseReference} onCopy={() => showSuccessToast('Copied')}>
                <Typography variant="body1" className={classes.value}>
                  {caseReference}
                </Typography>
              </CopyToClipboard>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default PropertyPurchaseDetailsSection

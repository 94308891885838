import React from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import { Button } from '@gground/capcom.core'
import { Textarea } from '@gground/capcom.form'
import { useForm } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1, 2),
    width: 400,
    '& .MuiInput-root': {
      marginTop: 0,
    },
  },
  categoryContainer: {
    padding: theme.spacing(1),
    backgroundColor: '#F9F9FA',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    marginBottom: theme.spacing(2),
  },
  categoryName: {
    paddingTop: 10,
    display: 'inline-block',
    fontWeight: 500,
  },
  changeLink: {
    color: '#006BC2',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: theme.spacing(2),
    '& > :first-child': {
      marginRight: theme.spacing(1),
    },
  },
}))

interface Props {
  onClose: (e: React.MouseEvent) => void
  onChangeCategory: () => void
  onSubmit: (values: { additionalInfo: string }) => void
  defaultValue: string | undefined
}

const OtherCategoryAdditionalInfo = ({
  onClose,
  onChangeCategory,
  onSubmit,
  defaultValue,
}: Props) => {
  const classes = useStyles()
  const { handleSubmit, register, errors } = useForm()

  return (
    <Box className={classes.container}>
      <Box className={classes.categoryContainer}>
        <Typography>
          Category: <br />
          <b className={classes.categoryName}>Other</b>
        </Typography>
        <span className={classes.changeLink} onClick={onChangeCategory}>
          Change
        </span>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Textarea
          label="Additional information"
          id="additionalInfo"
          name="additionalInfo"
          rows={3}
          fullWidth
          defaultValue={defaultValue || ''}
          ref={register({
            required: 'This field is required',
            maxLength: {
              value: 200,
              message: 'Maximum 200 characters',
            },
          })}
          error={Boolean(errors.additionalInfo)}
          helperText={errors.additionalInfo && errors.additionalInfo.message}
        />
        <Box className={classes.actionsContainer}>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </Box>
      </form>
    </Box>
  )
}

export default OtherCategoryAdditionalInfo

import dayjs from 'dayjs'
import { API } from './base'
import {
  downloadExternalFile,
  downloadExternalFiles,
  downloadInternalFile,
  getSignedUploadURL,
  uploadFile,
} from './utils'
import {
  CompanyTransactions,
  AvailableBalance,
  CompanyAccountDetails,
  TransactionCategoryUpdate,
  Document,
  DocumentUpload,
  StorageFileInfo,
  CompanyDetails,
  DocumentUpdate,
} from '../types'

export interface FetchBusinessAccountBalancePayload {
  clientId: number
  companyId: number
}

export const fetchBusinessAccountBalance = ({
  clientId,
  companyId,
}: FetchBusinessAccountBalancePayload): Promise<AvailableBalance> =>
  API.get(
    `partner_portal/clients/${clientId}/companies/${companyId}/business_account/available_balance`,
  ).then((response) => response.data)
export interface FetchBusinessAccountTransactionsPayload {
  clientId: number
  companyId: number
}

export const fetchBusinessAccountTransactions = ({
  clientId,
  companyId,
}: FetchBusinessAccountTransactionsPayload): Promise<CompanyTransactions> =>
  API.get(
    `partner_portal/clients/${clientId}/companies/${companyId}/business_account/transactions`,
  ).then((response) => response.data)

export interface FetchBusinessAccountDetailsPayload {
  clientId: number
  companyId: number
}

export const fetchBusinessAccountDetails = ({
  clientId,
  companyId,
}: FetchBusinessAccountDetailsPayload): Promise<CompanyAccountDetails> =>
  API.get(
    `partner_portal/clients/${clientId}/companies/${companyId}/business_account/details`,
  ).then((response) => response.data)
export interface DownloadBusinessAccountTransactionsPayload {
  clientId: number
  companyId: number
}

export const downloadBusinessAccountTransactions = ({
  clientId,
  companyId,
}: DownloadBusinessAccountTransactionsPayload) =>
  downloadInternalFile(
    `partner_portal/clients/${clientId}/companies/${companyId}/business_account/transactions/export?format=csv`,
    `transactions-${clientId}-${companyId}-${dayjs().format('YYYYMMDDHHmmss')}.csv`,
  )

export interface UpdateTransactionCategoryPayload {
  clientId: number
  companyId: number
  transactionId: number
  update: TransactionCategoryUpdate
}

export const updateTransactionCategory = ({
  clientId,
  companyId,
  transactionId,
  update,
}: UpdateTransactionCategoryPayload) =>
  API.patch(
    `partner_portal/clients/${clientId}/companies/${companyId}/business_account/transactions/${transactionId}`,
    update,
  ).then((response) => response.data)

export interface FetchDocumentsPayload {
  clientId: number
  companyId: number
}

export const fetchDocuments = ({
  clientId,
  companyId,
}: FetchDocumentsPayload): Promise<Document[]> =>
  API.get(`partner_portal/clients/${clientId}/companies/${companyId}/files`).then(
    (response) => response.data,
  )

export interface FetchDocumentLinksPayload {
  clientId: number
  companyId: number
  documents: Document['id'][]
}

export const fetchDocumentLinks = ({
  clientId,
  companyId,
  documents,
}: FetchDocumentLinksPayload): Promise<StorageFileInfo[]> => {
  const ids = documents.join(',')
  return API.get(
    `partner_portal/clients/${clientId}/companies/${companyId}/files/signed_url?id=${ids}`,
  ).then((response) => response.data)
}

export interface DownloadDocumentsPayload {
  clientId: number
  companyId: number
  files: StorageFileInfo[]
}

export const downloadDocuments = ({ clientId, companyId, files }: DownloadDocumentsPayload) => {
  if (files.length === 1) {
    return downloadExternalFile(files[0].signed_url, files[0].filename)
  }
  return downloadExternalFiles(
    files.map((f) => ({ name: f.filename, url: f.signed_url })),
    `documents-${clientId}-${companyId}-${dayjs().format('YYYYMMDDHHmmss')}.zip`,
  )
}
export interface FetchCompanyDetailsPayload {
  clientId: number
  companyId: number
}

export const fetchCompanyDetails = ({
  clientId,
  companyId,
}: FetchCompanyDetailsPayload): Promise<CompanyDetails> =>
  API.get(`partner_portal/clients/${clientId}/companies/${companyId}`).then(
    (response) => response.data,
  )
export interface DeleteDocumentsPayload {
  companyId: number
  clientId: number
  documentIds: Document['id'][]
}

export const deleteDocuments = ({ clientId, companyId, documentIds }: DeleteDocumentsPayload) =>
  API.delete(
    `partner_portal/clients/${clientId}/companies/${companyId}/files?id=${documentIds.join(',')}`,
  )
export interface UploadDocumentPayload {
  clientId: number
  companyId: number
  file: File
  document: DocumentUpload
}

export const uploadDocument = async ({
  clientId,
  companyId,
  file,
  document,
}: UploadDocumentPayload): Promise<Document> => {
  // get signed URL
  const signedURL = await getSignedUploadURL(clientId, companyId, file.name, file.type)

  // upload file
  await uploadFile(file, signedURL.signed_url, signedURL.headers)

  // record uploaded file and return document
  return API.post(`partner_portal/clients/${clientId}/companies/${companyId}/files`, [
    {
      type: document.type,
      document_date: document.document_date,
      name: document.name,
      file_size_in_bytes: file.size,
      location: signedURL.object_name,
      mime_type: file.type,
      expense_details: document.expense_category
        ? {
            amount_in_cents: document.amount_in_cents,
            category: document.expense_category,
            currency: document.currency,
            description: document.description,
            merchant_name: document.merchant_name,
          }
        : null,
    },
  ]).then((response) => response.data[0])
}

export interface EditDocumentsPayload {
  companyId: number
  clientId: number
  updates: DocumentUpdate[]
}

export const editDocuments = ({
  clientId,
  companyId,
  updates,
}: EditDocumentsPayload): Promise<Document[]> =>
  API.patch(`partner_portal/clients/${clientId}/companies/${companyId}/files`, updates).then(
    (response) => response.data,
  )

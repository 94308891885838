import { Box, makeStyles, Typography } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import React from 'react'
import BoxIcon from 'src/icons/BoxIcon'
import { ClientOverview } from 'src/types'
import CompanyCard from './CompanyCard'

const useStyles = makeStyles((theme) => ({
  list: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    margin: 0,
    padding: 0,
  },
  clientContainer: {
    listStyle: 'none',
    '&:not(:first-child)': {
      marginTop: theme.spacing(4),
    },
  },
  clientHeading: {
    fontSize: 18,
    fontWeight: 500,
    borderBottom: '1px solid #DEE0E3',
  },
  companyContainer: {
    listStyle: 'none',
  },
}))

const EmptyList = () => (
  <Box display="flex" flexDirection="column" alignItems="center" mt={6} mx={6}>
    <BoxIcon fontSize="inherit" style={{ fontSize: 40 }} />
    <Box my={1}>
      <Typography variant="h3">Nothing to show yet</Typography>
    </Box>
    <Typography variant="body1">When you add a company it will appear here.</Typography>
  </Box>
)

const LoadingSkeletons = () => {
  const client = (
    <Box>
      <Typography variant="h2" style={{ fontSize: 18 }}>
        <Skeleton variant="text" width={150} />
      </Typography>
      <Box mt={2}>
        <Skeleton variant="rect" width="100%" height={178} style={{ borderRadius: 8 }} />
      </Box>
    </Box>
  )

  return (
    <Box alignSelf="stretch">
      <Box>{client}</Box>
      <Box mt={4}>{client}</Box>
    </Box>
  )
}

interface ClientsListProps {
  clients: ClientOverview[]
  loading?: boolean
}

const ClientsList = ({ clients, loading }: ClientsListProps) => {
  const classes = useStyles()

  if (loading) return <LoadingSkeletons />

  // eslint-disable-next-line react/destructuring-assignment
  if (!(clients?.length > 0)) return <EmptyList />

  return (
    <ul className={classes.list}>
      {clients.map((client) => (
        <li key={client.id} className={classes.clientContainer}>
          <Typography variant="h2" className={classes.clientHeading}>
            {client.name || client.email}
          </Typography>
          <ul className={classes.list}>
            {client.property_purchases.length === 0 ? (
              <li className={classes.companyContainer}>
                <CompanyCard clientId={client.id} profileStatus={client.profile_status} />
              </li>
            ) : (
              <>
                {client.property_purchases.map((pp) => (
                  <li key={pp.id} className={classes.companyContainer}>
                    <CompanyCard
                      clientId={client.id}
                      profileStatus={client.profile_status}
                      propertyPurchase={pp}
                    />
                  </li>
                ))}
              </>
            )}
          </ul>
        </li>
      ))}
    </ul>
  )
}

export default ClientsList

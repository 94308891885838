import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { AppStore } from 'src/store'
import { signOut } from 'src/store/slices/user.slice'
import { API, APIv2 } from './base'

export const setupInterceptors = (store: AppStore) => {
  const handleRequest = (config: AxiosRequestConfig) => {
    const { token } = store.getState().user.auth
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    return config
  }

  const handleResponse = (response: AxiosResponse) => response

  const handleResponseError = (error: AxiosError) => {
    if (error?.response?.status === 401) {
      store.dispatch(signOut())
    }
    return Promise.reject(error)
  }

  API.interceptors.request.use(handleRequest)
  API.interceptors.response.use(handleResponse, handleResponseError)

  APIv2.interceptors.request.use(handleRequest)
  APIv2.interceptors.response.use(handleResponse, handleResponseError)
}

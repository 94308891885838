import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { ClientOverview, PropertyPurchaseOverview } from 'src/types'
import CreateProfileStep from './CreateProfileStep'
import CreateCompanyStep from './CreateCompanyStep'
import ClientApprovalStep from './ClientApprovalStep'
import CompanyFormationStep from './CompanyFormationStep'
import BusinessAccountStep from './BusinessAccountStep'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'flex-start',
    '& > *': {
      flexGrow: 1,
    },
    overflowX: 'auto',
  },
}))

interface ProgressBarProps {
  clientId: ClientOverview['id']
  profileStatus: ClientOverview['profile_status']
  propertyPurchase?: PropertyPurchaseOverview
}

const ProgressBar = ({ clientId, profileStatus, propertyPurchase }: ProgressBarProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <CreateProfileStep clientId={clientId} profileStatus={profileStatus} />
      <CreateCompanyStep clientId={clientId} propertyPurchase={propertyPurchase} />
      <ClientApprovalStep clientId={clientId} propertyPurchase={propertyPurchase} />
      <CompanyFormationStep propertyPurchase={propertyPurchase} />
      <BusinessAccountStep propertyPurchase={propertyPurchase} />
    </Box>
  )
}

export default ProgressBar

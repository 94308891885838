import React from 'react'
import { Box, makeStyles, Theme, Typography } from '@material-ui/core'
import { Check as CheckIcon } from '@gground/capcom.icons'
import { OverviewStatus } from 'src/types'

const colors: Record<OverviewStatus, { background: string; border: string }> = {
  TODO: { border: '#DEE0E3', background: '#F9F9FA' },
  NOT_STARTED: { border: '#DEE0E3', background: '#F9F9FA' },
  IN_PROGRESS: { border: '#96CCD9', background: '#E8F4F7' },
  PENDING_APPROVAL: { border: '#96CCD9', background: '#E8F4F7' },
  COMPLETE: { border: '#53C163', background: '#FFF' },
}

interface StyleProps {
  status: OverviewStatus
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {
    display: 'flex',
    minHeight: theme.spacing(6),
    flexGrow: 1,
    '&:first-child': {
      flex: 'none',
    },
    '&:not(:first-child)::before': {
      content: '""',
      display: 'block',
      height: theme.spacing(3),
      borderBottom: ({ status }) => `2px solid ${colors[status].border}`,
      minWidth: theme.spacing(2),
      flexGrow: 1,
    },
  },
  content: {
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: ({ status }) => colors[status].border,
    backgroundColor: ({ status }) => colors[status].background,
    minWidth: theme.spacing(10),
    overflow: 'hidden',
  },
  title: {
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    color: ({ status }) => (status === OverviewStatus.COMPLETE ? '#2B3725' : '#4E545F'),
  },
  checkIcon: {
    width: 16,
    height: 16,
    marginRight: theme.spacing(0.5),
    '& > rect': {
      fill: colors.COMPLETE.border,
    },
  },
}))

interface ProgressStepProps {
  children?: React.ReactNode
  status: OverviewStatus
  name: string
  position: number
}

const ProgressStep = ({ children, status, name, position }: ProgressStepProps) => {
  const classes = useStyles({ status })

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Typography variant="h4" className={classes.title}>
          {status === OverviewStatus.COMPLETE ? (
            <CheckIcon className={classes.checkIcon} />
          ) : (
            <span>{position}.&nbsp;</span>
          )}
          <span>{name}</span>
        </Typography>
        {children}
      </Box>
    </Box>
  )
}

export default ProgressStep

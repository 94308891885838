import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { Typography, Box, makeStyles } from '@material-ui/core'
import { Input, Checkbox } from '@gground/capcom.form'

import { validateEmail } from './utils'

const useStyles = makeStyles((theme) => ({
  subTitle: {
    color: theme.palette.info.main,
  },
}))

const PropertyAgentFormFragment = () => {
  const classes = useStyles()
  const { control, errors, register, watch, clearErrors, setValue } = useFormContext()

  const noPropertyAgent = watch('no_property_agent', false)

  return (
    <Box component="section">
      <Box mt={4}>
        <Typography variant="h2" className={classes.subTitle}>
          Property agent
        </Typography>
        <Box mt={2}>
          <Input
            label="Property agents email address"
            name="email_address"
            id="email_address"
            fullWidth
            ref={register({
              validate: {
                validateEmail: (value) => {
                  if (noPropertyAgent) return true
                  return validateEmail(value)
                },
              },
            })}
            disabled={noPropertyAgent}
            error={Boolean(errors.email_address)}
            helperText={errors.email_address && errors.email_address?.message}
          />
        </Box>
        <Box mt={2}>
          <Controller
            name="no_property_agent"
            control={control}
            defaultValue={null}
            render={({ value }) => (
              <Checkbox
                name="no_property_agent"
                label="They haven’t used a property agent"
                onChange={() => {
                  setValue('no_property_agent', !value)
                  clearErrors()
                }}
                checked={noPropertyAgent}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default PropertyAgentFormFragment

import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import {
  fetchPropertyPurchaseDetails,
  updatePropertyPurchaseDetails,
} from 'src/store/slices/propertyPurchases.slice'
import { showErrorToast } from 'src/utils/toasts'
import { useAppDispatch } from 'src/store'
import type { PropertyPurchaseUpdate } from 'src/types'
import { trackSnowplow } from 'src/store/slices/user.slice'
import { propertyPurchaseToForm, formToPropertyPurchaseUpdate } from './utils'

type RouteParams = {
  clientId: string
  companyId: string
}

const useCompanyDetails = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const form = useForm<PropertyPurchaseUpdate>()

  const params = useParams<RouteParams>()
  const clientId = parseInt(params.clientId ?? '', 10)
  const companyId = parseInt(params.companyId ?? '', 10)

  const [isHoldCo, setIsHoldCo] = useState(false)
  const [parentCompanyId, setParentCompanyId] = useState(null)

  useEffect(() => {
    if (!clientId || !companyId) {
      // navigate('/dashboard')
      return
    }

    dispatch(
      trackSnowplow({
        category: 'company_details',
        action: 'company_details_visit',
        ppId: companyId,
      }),
    )

    dispatch(fetchPropertyPurchaseDetails({ clientId, propertyPurchaseId: companyId }))
      .unwrap()
      .then((propertyPurchase) => {
        setIsHoldCo(propertyPurchase?.is_holding_co)
        setParentCompanyId(propertyPurchase?.parent_company_id)
        form.reset(propertyPurchaseToForm(propertyPurchase))
      })
      .catch(() => {
        showErrorToast('Failed to get company data')
        // navigate('/dashboard')
      })
  }, [companyId])

  const updateCompany = (fields: PropertyPurchaseUpdate) =>
    dispatch(
      updatePropertyPurchaseDetails({ clientId, propertyPurchaseId: companyId, payload: fields }),
    ).unwrap()

  const saveAndExit = form.handleSubmit((fields) =>
    updateCompany(formToPropertyPurchaseUpdate(fields))
      .then(() => navigate('/dashboard'))
      .catch(() => showErrorToast('Failed to update company details')),
  )

  const saveAndContinue = form.handleSubmit((fields) => {
    dispatch(
      trackSnowplow({
        category: 'company_details',
        action: 'company_details_next',
        ppId: companyId,
      }),
    )
    updateCompany(formToPropertyPurchaseUpdate(fields))
      .then(() => navigate(`/clients/${clientId}/companies/${companyId}/review`))
      .catch(() => showErrorToast('Failed to update company details'))
  })

  return { form, saveAndExit, saveAndContinue, isHoldCo, parentCompanyId, clientId, companyId }
}

export default useCompanyDetails

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography, makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'

import { countries } from 'src/constants'
import { useAppDispatch } from 'src/store'
import { fetchClientDetails } from 'src/store/slices/clients.slice'
import { fetchPropertyPurchaseDetails } from 'src/store/slices/propertyPurchases.slice'
import { formatCurrency, penniesToPounds } from 'src/utils/misc'
import type { Client, PropertyPurchase, Solicitor } from 'src/types'
import { trackSnowplow } from 'src/store/slices/user.slice'
import useSourceOfFundsTypes from 'src/hooks/useSourceOfFundsTypes'
import { fetchPartnerOverview } from 'src/store/slices/partner.slice'

const useStyles = makeStyles((theme) => ({
  notAdded: {
    color: theme.palette.error.main,
  },
}))

const NotAdded = () => {
  const classes = useStyles()
  return (
    <Typography component="span" className={classes.notAdded}>
      [Not added]
    </Typography>
  )
}

const solicitorDetails = (solicitor_details: Solicitor) => {
  const { deferred, find_solicitor, first_name, last_name, email, phone_number, case_ref } =
    solicitor_details
  if (deferred) return <b>Client does not have these details yet</b>
  if (find_solicitor) return <b>Refer solicitor to a client</b>
  if ([first_name, last_name, email, phone_number].some((item) => item !== null)) {
    return [
      <div>
        <strong>
          {first_name} {last_name} {email && ` - ${email}`}
        </strong>
      </div>,
      <div>
        <strong>{phone_number}</strong>
      </div>,
      '',
      case_ref && (
        <div>
          Case reference <strong>{case_ref}</strong>
        </div>
      ),
    ]
  }
  return null
}

const otherBuyToLetProperties = (client: Client) => {
  const { first_time_landlord, first_time_buyer, global_properties_count, uk_properties_count } =
    client

  if (first_time_landlord === null) {
    return null
  }

  if (first_time_landlord) {
    return (
      <>
        <span>Client is first time landlord</span>
        <br />
        {first_time_buyer === null ? (
          <NotAdded />
        ) : first_time_buyer ? (
          <span>Client is first time buyer</span>
        ) : (
          <span>Client is not a first time buyer</span>
        )}
      </>
    )
  }
  return (
    <>
      <span>Client is not a first time landlord</span>
      <br />
      {global_properties_count ? (
        <span>
          Client has <strong>{global_properties_count}</strong> other buy-to-let properties globally{' '}
        </span>
      ) : (
        <NotAdded />
      )}
      <br />
      {uk_properties_count ? (
        <span>
          <strong>{uk_properties_count}</strong> of my buy-to-let properties are in the UK{' '}
        </span>
      ) : (
        <NotAdded />
      )}
    </>
  )
}

const propertyAgentField = (propertyAgent: PropertyPurchase['property_agent_details']) => {
  if (propertyAgent?.email_address && propertyAgent?.has_property_agent) {
    return propertyAgent?.email_address
  }
  if (!propertyAgent?.has_property_agent) {
    return 'Client hasn’t used a property agent'
  }
  return <NotAdded />
}

const useCompanyReview = (clientId: number, companyId: number) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { sourceOfFundsTypes } = useSourceOfFundsTypes()

  const [client, setClient] = useState<Client | null>(null)
  const [clientStatus, setClientStatus] = useState<string>('')
  const [propertyPurchase, setPropertyPurchase] = useState<PropertyPurchase | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (!clientId || !companyId) {
      navigate('/dashboard')
    }

    dispatch(
      trackSnowplow({
        category: 'review_screen',
        action: 'review_screen_visit',
        ppId: companyId,
      }),
    )

    setLoading(true)
    setError(false)

    Promise.all([
      dispatch(fetchClientDetails({ id: clientId })).unwrap(),
      dispatch(
        fetchPropertyPurchaseDetails({
          clientId,
          propertyPurchaseId: companyId,
        }),
      ).unwrap(),
      dispatch(fetchPartnerOverview()).unwrap(),
    ]).then(([c, pp, overview]) => {
      setClient(c)
      setPropertyPurchase(pp)
      setClientStatus(overview?.clients?.find((client) => client.id === c.id)?.profile_status)
    })
  }, [clientId, companyId, dispatch, navigate])

  const {
    shareholder_details,
    is_holding_co,
    parent_company_id,
    property_address,
    purchase_details,
    tax_questions,
    solicitor_details,
    property_agent_details,
  } = propertyPurchase ?? {}

  const isHoldingCo = is_holding_co === true
  const isSubsidiary = is_holding_co === false && parent_company_id !== null

  const clientName = client ? `${client.first_name} ${client.last_name}` : ''
  const propertyPrice =
    purchase_details?.price?.amount_in_cents &&
    formatCurrency(penniesToPounds(purchase_details.price.amount_in_cents))
  const rent = purchase_details?.estimated_monthly_rent
    ? formatCurrency(penniesToPounds(purchase_details.estimated_monthly_rent))
    : null
  const isUsingMortgage =
    purchase_details?.using_mortgage !== null
      ? purchase_details?.using_mortgage
        ? 'is'
        : 'is not'
      : null
  const completionDate = purchase_details?.completion_date
    ? dayjs(purchase_details.completion_date).format('Do MMM, YYYY')
    : null
  const sourceOfFunds = sourceOfFundsTypes.find(
    (type) => type.value === purchase_details?.source_of_funds,
  )?.text
  const ownsOtherCompanies = tax_questions?.is_owner_of_other_companies ? 'does' : 'does not'
  const moreThan50Employees = tax_questions?.more_than_50_employees ? 'do' : 'do not'
  const assetsLessThan10m = tax_questions?.assets_less_than_10m ? 'do' : 'do not'
  const turnoverLessThan10m = tax_questions?.turnover_less_than_10m ? 'do' : 'do not'

  const addressDetails = () => {
    const hasSkippedAddress = property_address?.skipped

    if (hasSkippedAddress) {
      return `Client don't have a property yet`
    }

    if (!property_address || !purchase_details) {
      return null
    }

    const { premise, street, post_town, post_code } = property_address.address

    return (
      <>
        Country{' '}
        {property_address?.address?.country ? (
          <strong>{property_address.address.country}</strong>
        ) : (
          <NotAdded />
        )}
        <br />
        New build{' '}
        {property_address?.is_new_build !== null ? (
          <strong>{property_address.is_new_build ? 'Yes' : 'No'}</strong>
        ) : (
          <NotAdded />
        )}
        <br />
        {`${premise || ''} ${street || ''}, ${post_town || ''} ${post_code || ''}`}
      </>
    )
  }

  const clientProfileRows = client
    ? [
        {
          name: 'Name',
          value: `${client.first_name} ${client.last_name}`,
        },
        {
          name: 'Date of birth',
          value: client.date_of_birth ? dayjs(client.date_of_birth).format('DD MMMM YYYY') : null,
        },
        {
          name: 'Nationality',
          value: client.nationality ? countries[client.nationality]?.nationality : null,
        },
        {
          name: 'Passport number',
          value: client.passport_number,
        },
        {
          name: 'Profession',
          value: client.occupation,
        },
        {
          name: 'Other buy-to-let properties',
          value: otherBuyToLetProperties(client),
        },
        {
          name: 'Address',
          value: (
            <>
              {client?.premise ? client.premise : <NotAdded />}
              <br />
              {client?.street ? client.street : <NotAdded />}
              {client?.thoroughfare && (
                <>
                  <br />
                  {client.thoroughfare}
                </>
              )}
              <br />
              {client?.posttown ? client.posttown : <NotAdded />}
              <br />
              {client?.postcode ? client.postcode : <NotAdded />}
              <br />
              {client?.country ? <>{countries[client.country].country_name}</> : <NotAdded />}
            </>
          ),
        },
        {
          name: 'Phone number',
          value: client.phone_number,
        },
      ]
    : []

  const shareholderDetailsRows = shareholder_details
    ? [
        {
          name: 'Shareholder details',
          value: shareholder_details.shareholders.map(
            ({ first_name, last_name, allocated_shares, is_director, email }) => (
              <div key={email}>
                <strong>
                  {first_name} {last_name}
                </strong>{' '}
                has{' '}
                <strong>
                  {allocated_shares}
                  {!is_holding_co && '%'} shares
                </strong>{' '}
                and <strong>is {!is_director && 'not '}a director</strong>
              </div>
            ),
          ),
        },
      ]
    : []

  const companyDetailsRows =
    tax_questions && solicitor_details
      ? [
          {
            name: 'Property address',
            value: <span>{addressDetails()}</span>,
          },
          {
            name: 'Purchase details',
            value: (
              <>
                {propertyPrice ? (
                  <>
                    The property price is <strong>£{propertyPrice}</strong>
                  </>
                ) : (
                  <NotAdded />
                )}
                <br />
                {rent ? (
                  <>
                    The rent is <strong>£{rent}</strong> per month
                  </>
                ) : (
                  <NotAdded />
                )}
                <br />
                {isUsingMortgage ? (
                  <>
                    Client <strong>{isUsingMortgage}</strong> using a mortgage
                  </>
                ) : (
                  <NotAdded />
                )}
                <br />
                {sourceOfFunds ? (
                  <>
                    Source of funds is <strong>{sourceOfFunds}</strong>
                  </>
                ) : (
                  <NotAdded />
                )}
                <br />
                {completionDate ? (
                  <>
                    The completion date is <strong>{completionDate}</strong>
                  </>
                ) : (
                  <NotAdded />
                )}
                <br />
              </>
            ),
          },
          {
            name: 'Tax questions',
            value: (
              <>
                {tax_questions.is_owner_of_other_companies !== null ? (
                  <>
                    Client <strong>{ownsOtherCompanies} </strong> have ownership or voting rights of
                    25% or more in any companies, partnerships or other enterprises
                  </>
                ) : (
                  <NotAdded />
                )}
                <br />
                {tax_questions?.is_owner_of_other_companies && (
                  <>
                    {tax_questions.more_than_50_employees !== null ? (
                      <>
                        The companies <strong>{moreThan50Employees}</strong> employ more than 50
                        employees combined
                      </>
                    ) : (
                      <NotAdded />
                    )}
                    <br />
                    {tax_questions.assets_less_than_10m !== null ? (
                      <>
                        The companies <strong>{assetsLessThan10m}</strong> have assets of €10m or
                        less combined
                      </>
                    ) : (
                      <NotAdded />
                    )}
                    <br />
                    {tax_questions.turnover_less_than_10m !== null ? (
                      <>
                        The companies <strong>{turnoverLessThan10m}</strong> have a turnover of €10m
                        or less combined
                      </>
                    ) : (
                      <NotAdded />
                    )}
                  </>
                )}
              </>
            ),
          },
          {
            name: 'Solicitor details',
            value: solicitorDetails(solicitor_details),
          },
          {
            name: 'Property agent',
            value: propertyAgentField(property_agent_details),
          },
        ]
      : []

  return {
    isHoldingCo,
    isSubsidiary,
    clientProfileRows,
    shareholderDetailsRows,
    companyDetailsRows,
    clientName,
    loading,
    error,
    clientStatus,
  }
}

export default useCompanyReview

import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { Input } from '@gground/capcom.form'
import { useFormContext } from 'react-hook-form'
import { validateNoSpecialCharacter } from './utils'
import type { ClientFormFields } from './types'

const PassportNumberFormFragment = () => {
  const { register, clearErrors, errors } = useFormContext<ClientFormFields>()

  return (
    <Box mt={4} component="section">
      <Typography variant="h2" style={{ fontWeight: 500 }}>
        Passport number
      </Typography>
      <Box mt={2}>
        <Input
          type="text"
          id="passport_number"
          name="passport_number"
          label="Passport number"
          helperText={errors.passport_number && errors.passport_number?.message}
          error={!!errors.passport_number}
          onFocus={() => clearErrors()}
          ref={register({ validate: validateNoSpecialCharacter })}
          fullWidth
        />
      </Box>
    </Box>
  )
}

export default PassportNumberFormFragment

import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Typography, Box, makeStyles, MenuItem } from '@material-ui/core'
import { Input, DatePicker, Select, CurrencyInput } from '@gground/capcom.form'
import dayjs from 'dayjs'

import useSourceOfFundsTypes from 'src/hooks/useSourceOfFundsTypes'
import { validateWhitespace, validateGreaterThanZero } from './utils'

const useStyles = makeStyles((theme) => ({
  subTitle: {
    color: theme.palette.info.main,
  },
}))

const PurchaseDetailsFormFragment = () => {
  const classes = useStyles()
  const { control, errors, register, watch } = useFormContext()
  const { sourceOfFundsTypes } = useSourceOfFundsTypes()

  const showReason = watch('source_of_funds') === 'other'

  return (
    <Box component="section">
      <Box mt={4}>
        <Typography variant="h2" className={classes.subTitle}>
          Purchase Details
        </Typography>
        <Box mt={2}>
          <Controller
            name="price_of_property"
            defaultValue=""
            control={control}
            rules={{
              validate: {
                zero: (val: string) =>
                  validateGreaterThanZero(val) || 'Property price cannot be £0',
              },
            }}
            render={({ onChange, value }) => (
              <CurrencyInput
                id="price_of_property"
                label="Price of property"
                name="price_of_property"
                symbol="£"
                value={value}
                onChange={(e: React.MouseEvent) => onChange(e.target.value)}
                error={Boolean(errors.price_of_property)}
                helperText={errors.price_of_property && errors.price_of_property.message}
                fullWidth
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            name="estimated_monthly_rent"
            defaultValue=""
            control={control}
            rules={{
              validate: {
                zero: (val: string) =>
                  validateGreaterThanZero(val) || 'Estimated monthly rent cannot be £0',
              },
            }}
            render={({ onChange, value }) => (
              <CurrencyInput
                id="estimated_monthly_rent"
                label="What is the property’s estimated monthly rent?"
                name="estimated_monthly_rent"
                symbol="£"
                value={value}
                onChange={(e: React.MouseEvent) => onChange(e.target.value)}
                error={Boolean(errors.estimated_monthly_rent)}
                helperText={errors.estimated_monthly_rent && errors.estimated_monthly_rent.message}
                fullWidth
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            name="using_mortgage"
            control={control}
            defaultValue=""
            render={({ onChange, value }) => {
              return (
                <Select
                  name="using_mortgage"
                  label="Are they using a mortgage to fund this property?"
                  error={Boolean(errors.using_mortgage)}
                  onChange={(e: React.MouseEvent) => onChange(e)}
                  value={value ?? ''}
                  fullWidth
                >
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              )
            }}
          />
        </Box>
        <Box mt={2}>
          <Controller
            name="source_of_funds"
            control={control}
            defaultValue=""
            render={({ onChange, value }) => {
              return (
                <Select
                  name="source_of_funds"
                  label="What is the source of funds for the portion they are providing?"
                  error={Boolean(errors.source_of_funds)}
                  onChange={(e: React.MouseEvent) => onChange(e)}
                  value={value ?? ''}
                  fullWidth
                >
                  {sourceOfFundsTypes.map(({ value, text }) => (
                    <MenuItem key={value} value={value}>
                      {text}
                    </MenuItem>
                  ))}
                </Select>
              )
            }}
          />
        </Box>
        {showReason && (
          <Box mt={2}>
            <Input
              id="source_of_funds_other_reason"
              fullWidth
              name="source_of_funds_other_reason"
              hint="Maximum 100 characters"
              label="Please explain what the source of funds will be"
              ref={register({
                maxLength: {
                  value: 100,
                  message: 'Maximum 100 characters',
                },
                minLength: {
                  value: 3,
                  message: 'Minimum 3 characters',
                },
                validate: {
                  validateWhitespace,
                },
              })}
              error={Boolean(errors.source_of_funds_other_reason)}
              helperText={
                errors.source_of_funds_other_reason && errors.source_of_funds_other_reason?.message
              }
            />
          </Box>
        )}
        <Box mt={2}>
          <Controller
            name="completion_date"
            control={control}
            defaultValue=""
            render={({ value, onChange }) => {
              return (
                <DatePicker
                  disablePast
                  label="When is the expected completion date?"
                  name="completion_date"
                  error={Boolean(errors.completion_date)}
                  helperText={errors.completion_date && errors.completion_date?.message}
                  onChange={(e) => onChange(e)}
                  format="YYYY-MM-DD"
                  value={value}
                  inputProps={{
                    value: value ? dayjs(value).format('YYYY-MM-DD') : undefined,
                  }}
                  fullWidth
                />
              )
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default PurchaseDetailsFormFragment

import React from 'react'
import { Box, MenuItem, Typography } from '@material-ui/core'
import { Input, Select } from '@gground/capcom.form'
import { Controller, useFormContext } from 'react-hook-form'
import { countries } from 'src/constants'
import { validateAddressLine, validateLettersOnly, validateNoSpecialCharacter } from './utils'
import type { ClientFormFields } from './types'

const AddressFormFragment = () => {
  const { register, clearErrors, control, errors } = useFormContext<ClientFormFields>()

  return (
    <Box mt={4} component="section">
      <Typography variant="h2" style={{ fontWeight: 500 }}>
        Address
      </Typography>
      <Box mt={2}>
        <Controller
          name="country"
          control={control}
          rules={{
            required: 'This field is required',
          }}
          defaultValue=""
          render={({ onChange, value }) => (
            <Select
              id="country"
              name="country"
              label="Country of residence"
              value={value}
              onChange={onChange}
              fullWidth
              error={errors.country}
            >
              {Object.values(countries).map(({ country_name, id }) => (
                <MenuItem key={id} value={id}>
                  {country_name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Box>
      <Box mt={2}>
        <Input
          type="text"
          id="premise"
          name="premise"
          label="Flat number / Building number / House name"
          hint="This can also be a floor number, street number, house number, or a combination of any of these"
          helperText={errors.premise && errors.premise?.message}
          error={!!errors.premise}
          onFocus={() => clearErrors()}
          ref={register({ validate: validateAddressLine })}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <Input
          type="text"
          id="street"
          name="street"
          label="Address line 1"
          helperText={errors.street && errors.street?.message}
          error={!!errors.street}
          onFocus={() => clearErrors()}
          ref={register({ validate: validateAddressLine })}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <Input
          type="text"
          id="thoroughfare"
          name="thoroughfare"
          label="Address line 2"
          helperText={errors.thoroughfare && errors.thoroughfare?.message}
          error={!!errors.thoroughfare}
          onFocus={() => clearErrors()}
          ref={register({ validate: validateAddressLine })}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <Input
          type="text"
          id="posttown"
          name="posttown"
          label="City"
          helperText={errors.posttown && errors.posttown?.message}
          error={!!errors.posttown}
          onFocus={() => clearErrors()}
          ref={register({ validate: validateLettersOnly })}
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <Input
          type="text"
          id="postcode"
          name="postcode"
          label="Postcode"
          helperText={errors.postcode && errors.postcode?.message}
          error={!!errors.postcode}
          onFocus={() => clearErrors()}
          ref={register({ validate: validateNoSpecialCharacter })}
          fullWidth
        />
      </Box>
    </Box>
  )
}

export default AddressFormFragment

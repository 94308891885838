import { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { fetchPropertyPurchaseDetails } from 'src/store/slices/propertyPurchases.slice'
import { fetchClientDetails } from 'src/store/slices/clients.slice'
import { useAppDispatch, useAppSelector } from 'src/store/index'
import { trackSnowplow } from 'src/store/slices/user.slice'

type RouteParams = {
  clientId: string
  companyId: string
}

const tabs = [
  {
    label: 'Business account',
    value: 'business-account',
  },
  {
    label: 'Documents',
    value: 'documents',
  },
  {
    label: 'Company details',
    value: 'company-details',
  },
]

const useCompany = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [tabValue, setTabValue] = useState('business-account')

  const location = useLocation()

  const params = useParams<RouteParams>()

  const propertyPurchaseId = parseInt(params.companyId ?? '', 10)
  const propertyPurchase = useAppSelector((state) => state.propertyPurchases[propertyPurchaseId])
  const companyId = propertyPurchase?.company_id
  const ppId = propertyPurchase?.id

  const clientId = parseInt(params.clientId ?? '', 10)
  const client = useAppSelector((state) => state.clients[clientId])

  useEffect(() => {
    if (clientId && propertyPurchaseId) {
      setIsLoading(true)
      Promise.all([
        dispatch(fetchClientDetails({ id: clientId })).unwrap(),
        dispatch(
          fetchPropertyPurchaseDetails({
            clientId,
            propertyPurchaseId,
          }),
        ).unwrap(),
      ]).then(() => setIsLoading(false))
    }
  }, [clientId, propertyPurchaseId, dispatch])

  useEffect(() => {
    const arr = location.pathname.split('/')
    const tabValues = tabs.map(({ value }) => value)
    const activeTabValue = arr.filter((element) => tabValues.includes(element))[0]
    setTabValue(activeTabValue)
  }, [])

  const handleClickBack = () => navigate('/dashboard')

  const handleChangeDefaultTabs = (_: any, newValue: string) => {
    // // Temporary disabling of non existing routes
    // if (['company-details'].includes(newValue)) {
    //   return
    // }

    setTabValue(newValue)
    navigate(newValue)

    dispatch(
      trackSnowplow({
        category: 'company_page',
        action: newValue.replace('-', '_'),
        ppId,
        companyId,
      }),
    )
  }

  return {
    isLoading,
    tabValue,
    tabs,
    handleClickBack,
    handleChangeDefaultTabs,
    client,
    propertyPurchase,
    clientId,
    companyId,
  }
}

export default useCompany

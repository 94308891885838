import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { ChevronDown } from '@gground/capcom.icons'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'inline-flex',
    padding: theme.spacing(1, 0),
    alignItems: 'center',
    textDecoration: 'none',
    fontWeight: 500,
    color: theme.palette.info.main,
  },
  chevronIcon: {
    width: 18,
    height: 18,
    transform: 'rotate(90deg)',
    marginRight: theme.spacing(0.5),
  },
}))

interface BackButtonProps {
  to: LinkProps['to']
}

const BackButton = ({ to }: BackButtonProps) => {
  const classes = useStyles()

  return (
    <Link to={to} className={classes.link}>
      <ChevronDown className={classes.chevronIcon} />
      Back
    </Link>
  )
}

export default BackButton

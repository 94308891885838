import React, { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { trackPageView, enableActivityTracking } from '@snowplow/browser-tracker'
import { withLDProvider } from 'launchdarkly-react-client-sdk'

import {
  Invite,
  SignUp,
  SignIn,
  NotFound,
  SignIn2FA,
  SignUp2FA,
  CompanyStructure,
  CompanyDetailsForm,
  ClientDetails,
  CompanyReview,
  ShareholdingStructure,
  Dashboard,
  ControllingHoldco,
  Company,
  BusinessAccount,
  TransactionsList,
  AccountDetails,
  Documents,
  CompanyDetails,
} from './pages'

import { useAppSelector } from './store'

const AuthenticatedRoute = ({ children }: { children: JSX.Element }) => {
  const { status } = useAppSelector((state) => state.user.auth)
  if (status === 'AUTHENTICATED' || status === 'AUTHORIZED') {
    return children
  }
  return <Navigate to="/sign-in" />
}

const AuthorizedRoute = ({ children }: { children: JSX.Element }) => {
  const { status } = useAppSelector((state) => state.user.auth)
  if (status === 'AUTHORIZED') {
    return children
  }
  return <Navigate to="/sign-in" />
}

const App = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    enableActivityTracking({
      minimumVisitLength: 30,
      heartbeatDelay: 10,
    })
    trackPageView()
  }, [pathname])

  return (
    <Routes>
      <Route index element={<Navigate to="dashboard" replace />} />

      {/* PUBLIC */}
      <Route path="invite" element={<Invite />} />
      <Route path="sign-up" element={<SignUp />} />
      <Route path="sign-in" element={<SignIn />} />

      {/* AUTHENTICATED */}
      <Route
        path="sign-up/2fa"
        element={
          <AuthenticatedRoute>
            <SignUp2FA />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="sign-in/2fa"
        element={
          <AuthenticatedRoute>
            <SignIn2FA />
          </AuthenticatedRoute>
        }
      />

      {/* AUTHORISED */}
      <Route
        path="dashboard"
        element={
          <AuthorizedRoute>
            <Dashboard />
          </AuthorizedRoute>
        }
      />
      <Route
        path="clients/:clientId"
        element={
          <AuthorizedRoute>
            <ClientDetails />
          </AuthorizedRoute>
        }
      />
      <Route
        path="clients/:clientId/companies"
        element={
          <AuthorizedRoute>
            <CompanyStructure />
          </AuthorizedRoute>
        }
      />
      <Route
        path="clients/:clientId/companies/shareholding"
        element={
          <AuthorizedRoute>
            <ShareholdingStructure />
          </AuthorizedRoute>
        }
      />
      <Route
        path="clients/:clientId/companies/:companyId/shareholding"
        element={
          <AuthorizedRoute>
            <ShareholdingStructure />
          </AuthorizedRoute>
        }
      />
      <Route
        path="clients/:clientId/companies/:companyId/details"
        element={
          <AuthorizedRoute>
            <CompanyDetailsForm />
          </AuthorizedRoute>
        }
      />
      <Route
        path="clients/:clientId/companies/:companyId/review"
        element={
          <AuthorizedRoute>
            <CompanyReview />
          </AuthorizedRoute>
        }
      />
      <Route
        path="clients/:clientId/companies/controlling-holdco"
        element={
          <AuthorizedRoute>
            <ControllingHoldco />
          </AuthorizedRoute>
        }
      />
      <Route
        path="clients/:clientId/companies/:companyId"
        element={
          <AuthorizedRoute>
            <Company />
          </AuthorizedRoute>
        }
      >
        <Route path="business-account" element={<BusinessAccount />}>
          <Route index element={<TransactionsList />} />
          <Route path="account-details" element={<AccountDetails />} />
        </Route>
        <Route path="documents" element={<Documents />} />
        <Route path="company-details" element={<CompanyDetails />} />
      </Route>

      {/* CATCH ALL */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default withLDProvider({
  clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_ID,
})(App)

import type { Auth2FAMethod } from 'src/types'
import { API, APIv2 } from './base'

export interface SignUpOptions {
  email: string
  password: string
  code: string
}

export interface SignUpResponse {
  token: string
}

export const signUp = async ({ email, password, code }: SignUpOptions): Promise<SignUpResponse> =>
  API.post('partner_portal/sign_up', {
    email,
    password,
    sign_up_code: code,
  }).then((response) => response.data)

export interface SignUp2FAInitOptions {
  method: Auth2FAMethod
  phone?: string
}

export interface SignUp2FAInitResponse {
  key_uri?: string
}

export const signUp2FAInit = async ({
  method,
  phone,
}: SignUp2FAInitOptions): Promise<SignUp2FAInitResponse> =>
  API.post('partner_portal/sign_up/initiate_2fa_setup', {
    '2fa_method': method,
    phone_number: phone,
  }).then((response) => response.data)

export interface SignUp2FAValidateOptions {
  method: Auth2FAMethod
  otp: string
  phone?: string
}

export interface SignUp2FAValidateResponse {
  token: string
}

export const signUp2FAValidate = async ({
  method,
  otp,
  phone,
}: SignUp2FAValidateOptions): Promise<SignUp2FAValidateResponse> =>
  API.post('partner_portal/sign_up/validate_2fa_setup', {
    otp,
    '2fa_method': method,
    phone_number: phone,
  }).then((response) => response.data)

export interface SignInOptions {
  email: string
  password: string
}

export interface SignInResponse {
  token: string
  '2fa_method': Auth2FAMethod
  phone_number?: string
}

export const signIn = async ({ email, password }: SignInOptions): Promise<SignInResponse> =>
  API.post('partner_portal/sign_in', {
    email,
    password,
  }).then((response) => response.data)

export const resendSMSCode = async (): Promise<void> =>
  API.post('partner_portal/sign_in/resend_otp').then((response) => response.data)

export interface Verify2FAOptions {
  otp: string
}

export interface Verify2FAResponse {
  token: string
}

export const verify2FA = async ({ otp }: Verify2FAOptions): Promise<Verify2FAResponse> =>
  API.post('partner_portal/sign_in/2fa', {
    otp,
  }).then((response) => response.data)

export interface RefreshTokenResponse {
  jwt_token: string
}

export const refreshToken = async (): Promise<RefreshTokenResponse> =>
  APIv2.post('auth_reset').then((response) => response.data)

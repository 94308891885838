import axios from 'axios'

function getApiUrl() {
  if (
    import.meta.env.MODE === 'staging' &&
    window._env_ !== undefined &&
    window._env_.VITE_API_URL !== ''
  ) {
    return `${window._env_.VITE_API_URL}/api`
  }
  return `${import.meta.env.VITE_API_URL}/api`
}

export const API = axios.create({
  baseURL: `${getApiUrl()}/v3`,
})

export const APIv2 = axios.create({
  baseURL: `${getApiUrl()}/v2`,
})

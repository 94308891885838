import React from 'react'
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from '@material-ui/core'
import { Checkbox } from '@gground/capcom.form'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { showSuccessToast } from 'src/utils/toasts'
import { Shareholder } from 'src/types'

const useStyles = makeStyles({
  container: {
    padding: 0,
    paddingBottom: '0 !important',
  },
  table: {
    '& .MuiTableCell-root': {
      paddingTop: 0,
      paddingBottom: 0,
      height: 40,
      '&:nth-last-child(-n+2)': {
        maxWidth: 32,
        paddingLeft: 0,
        paddingRight: 0,
        '&:not(.MuiTableCell-head)': {
          backgroundColor: '#E8F4F7',
        },
      },
      '&:first-child': {
        maxWidth: 100,
      },
    },
  },
  sharesTitle: {
    direction: 'rtl',
    overflow: 'visible',
  },
  name: {
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
})

interface Props {
  shareholders: Shareholder[]
  companyType: string
}

const ShareholdersFragment = ({ shareholders, companyType }: Props) => {
  const classes = useStyles()

  return (
    <Card>
      <CardHeader
        title="Shareholders"
        action={
          <Box>
            <Typography variant="subtitle1" component="i">
              Hint: Click on a field to copy it
            </Typography>
          </Box>
        }
      />

      <CardContent className={classes.container}>
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="center" className={classes.sharesTitle}>
                  {companyType === 'SPV' ? '% Shares' : 'Shares (1000 total)'}
                </TableCell>
                <TableCell align="center">Director</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shareholders &&
                shareholders.map((shareholder) => (
                  <TableRow key={shareholder?.email}>
                    <TableCell>
                      <CopyToClipboard
                        text={`${shareholder?.first_name} ${shareholder?.last_name}`}
                        onCopy={() => showSuccessToast('Copied')}
                      >
                        <Typography className={classes.name}>
                          {shareholder?.first_name} {shareholder?.last_name}
                        </Typography>
                      </CopyToClipboard>
                    </TableCell>
                    <TableCell>
                      <CopyToClipboard
                        text={shareholder?.email}
                        onCopy={() => showSuccessToast('Copied')}
                      >
                        <Typography>{shareholder?.email}</Typography>
                      </CopyToClipboard>
                    </TableCell>
                    <TableCell align="center">
                      <CopyToClipboard
                        text={shareholder?.email}
                        onCopy={() => showSuccessToast('Copied')}
                      >
                        <Typography>{shareholder?.allocated_shares}</Typography>
                      </CopyToClipboard>
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox name="isDirector" disabled checked={shareholder?.is_director} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

export default ShareholdersFragment

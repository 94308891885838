import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { FormHelperText, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PhoneInput from 'react-phone-number-input'
import { noLetters } from 'src/utils/validate'

import 'react-phone-number-input/style.css'

const useStyles = makeStyles((theme) => ({
  input: {
    '& .PhoneInputCountry': {
      backgroundColor: '#FFFFFF !important',
      marginRight: '8px !important',
      borderRadius: '4px !important',
      border: '1px solid #cacaca',
      padding: theme.spacing(0, 1),
      '& .PhoneInputCountrySelect': {
        paddingLeft: 12,
      },
    },
    '& .PhoneInputInput': {
      paddingLeft: '8px !important',
      height: '32px !important',
      borderRadius: 5,
      maxWidth: ({ inputFullWidth }) => (inputFullWidth ? '100%' : 300),
      border: ({ error }) => `1px solid ${error ? 'red' : '#cacaca'}`,
      '&:focus-visible': {
        outline: 'none !important',
      },
    },
  },
  label: {
    marginBottom: theme.spacing(0.5),
  },
}))

const PhoneNumberInput = ({
  control,
  name,
  error,
  disabled,
  notRequired,
  clearErrors,
  inputFullWidth,
}) => {
  const classes = useStyles({ error, inputFullWidth })
  const [value, setValue] = useState()

  return (
    <>
      <InputLabel className={classes.label} htmlFor="phoneNumber">
        Phone number
        {!notRequired && <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk"> *</span>}
      </InputLabel>
      <Controller
        as={
          <PhoneInput
            defaultCountry="GB"
            international
            placeholder="Enter phone number"
            value={value}
            onChange={setValue}
            className={classes.input}
            disabled={disabled}
            numberInputProps={{
              'data-testid': 'phoneNumber',
            }}
            onFocus={() => clearErrors()}
            id="phoneNumber"
          />
        }
        defaultValue={value || ''}
        name={name}
        control={control}
        rules={{
          required: !notRequired && 'This field is required',
          validate: {
            noLetters: (val) => noLetters(val ?? '') || 'Remove invalid characters',
          },
        }}
      />
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </>
  )
}

export default PhoneNumberInput

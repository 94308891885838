import React from 'react'
import { Box, Card, makeStyles, Theme, Typography } from '@material-ui/core'
import { Button } from '@gground/capcom.core'
import { Arrow as ArrowIcon } from '@gground/capcom.icons'
import { useNavigate } from 'react-router-dom'
import type { ClientOverview, PropertyPurchaseOverview } from 'src/types'
import { trackSnowplow } from 'src/store/slices/user.slice'
import { useAppDispatch } from 'src/store'
import SubContainer from './SubContainer'
import ProgressBar from './ProgressBar'

interface StyleProps {
  isSubsidiary?: boolean
  hasBusinessAccount?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    padding: theme.spacing(2),
    borderRadius: 8,
    margin: ({ isSubsidiary }) => (isSubsidiary ? 0 : theme.spacing(2, 0, 0)),
  },
  cardHeading: {
    fontSize: 14,
    whiteSpace: 'nowrap',
  },
  companyId: {
    color: '#4E545F',
    fontWeight: 400,
    marginRight: theme.spacing(1),
  },
  pendingText: {
    color: theme.palette.secondary.main,
    fontWeight: 400,
  },
  separator: {
    color: theme.palette.secondary.main,
    fontWeight: 400,
    margin: theme.spacing(0, 1),
  },
}))

interface CompanyCardProps {
  clientId: ClientOverview['id']
  profileStatus: ClientOverview['profile_status']
  propertyPurchase?: PropertyPurchaseOverview
  isSubsidiary?: boolean
}

const CompanyCard = ({
  clientId,
  profileStatus,
  propertyPurchase,
  isSubsidiary,
}: CompanyCardProps) => {
  const hasBusinessAccount =
    !!propertyPurchase?.has_business_account && !propertyPurchase?.is_holding_co
  const classes = useStyles({ isSubsidiary, hasBusinessAccount })
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const subsidiaries = propertyPurchase?.subsidiaries ?? []
  const isHoldCo = !!propertyPurchase?.is_holding_co
  const propertyAddress = propertyPurchase?.address ?? null
  const status = propertyPurchase?.design_status ?? 'TODO'
  const companyType = isSubsidiary
    ? 'SUBSIDIARY'
    : status === 'TODO'
    ? null
    : !isHoldCo
    ? 'STANDALONE'
    : subsidiaries.length > 0
    ? 'HOLDING COMPANY'
    : 'HOLDING COMPANY AND SUBSIDIARY'
  const showAddress = companyType !== 'HOLDING COMPANY'
  const companyId = propertyPurchase?.id
  const goToCompany = () => {
    navigate(`/clients/${clientId}/companies/${companyId}/business-account`)
    dispatch(trackSnowplow({ category: 'dashboard', action: 'go_to_company', ppId: companyId }))
  }

  return (
    <Box>
      <Card variant="elevation" tabIndex={0} className={classes.card}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h3" className={classes.cardHeading}>
            {companyId ? <span className={classes.companyId}>{`#${companyId}`}</span> : null}
            {companyType ? (
              <span>{companyType}</span>
            ) : (
              <span className={classes.pendingText}>Company type pending</span>
            )}
            {showAddress ? (
              <>
                <span className={classes.separator}>|</span>
                {propertyAddress ? (
                  <span>{propertyAddress}</span>
                ) : (
                  <span className={classes.pendingText}>Property address pending</span>
                )}
              </>
            ) : null}
          </Typography>
          {hasBusinessAccount && (
            <Button onClick={goToCompany} startIcon={<ArrowIcon />} variant="outlined">
              Go to company
            </Button>
          )}
        </Box>
        <ProgressBar
          clientId={clientId}
          profileStatus={profileStatus}
          propertyPurchase={propertyPurchase}
        />
      </Card>
      {subsidiaries.length > 0 ? (
        <SubContainer>
          {subsidiaries.map((s) => (
            <CompanyCard
              key={s.id}
              clientId={clientId}
              profileStatus={profileStatus}
              propertyPurchase={s}
              isSubsidiary
            />
          ))}
        </SubContainer>
      ) : null}
    </Box>
  )
}
export default CompanyCard

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import { ThemeProvider, CssBaseline, Typography } from '@material-ui/core'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import jwt_decode, { JwtPayload } from 'jwt-decode'

import dayjs from 'dayjs'
import SnackbarProvider from './components/SnackbarProvider'
import GGTheme from './config/theme'
import { createStore } from './store'
import { setupInterceptors } from './api/interceptors'
import App from './App'

import './main.css'
import { checkToken, signOut } from './store/slices/user.slice'
import { setupSentry } from './utils/sentry'
import { onWakeUp } from './utils/activity'

const store = createStore()
const persistor = persistStore(store)

// https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
setupInterceptors(store)

if (import.meta.env.MODE === 'production') {
  setupSentry()
}

const authCheck = async () => {
  const { dispatch } = store
  const token: string | null = store.getState().user?.auth?.token
  if (token) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const isTokenStillValid = dayjs(jwt_decode<JwtPayload>(token).exp! * 1000).isAfter(dayjs())
    if (isTokenStillValid) {
      dispatch(checkToken())
    } else {
      dispatch(signOut())
    }
  } else {
    dispatch(signOut())
  }
}

// check auth when device wakes up from idle state
onWakeUp(authCheck)

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <PersistGate
        loading={<Typography>Loading</Typography>}
        onBeforeLift={authCheck}
        persistor={persistor}
      >
        <ThemeProvider theme={GGTheme}>
          <SnackbarProvider>
            <CssBaseline />
            <Outlet />
          </SnackbarProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<AppWrapper />}>
          <Route path="*" element={<App />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,

  document.getElementById('root'),
)

import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useAppDispatch } from 'src/store'
import { trackSnowplow } from 'src/store/slices/user.slice'
import SignUP2FASelect from './SignUp2FASelect'
import SignUp2FAOTP from './SignUp2FAOTP'
import SignUp2FASMS from './SignUp2FASMS'
import useSignUp2FA from './useSignUp2FA'

const SignUp2FA = () => {
  const {
    auth,
    selectedMethod,
    otpKeyUri,
    phone,
    selectSMS,
    selectOTP,
    switchMethod,
    addPhone,
    clearPhone,
    validateOTP,
    validateSMS,
  } = useSignUp2FA()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(trackSnowplow({ category: '2fa', action: '2fa_visit' }))
  }, [])

  if (!auth.token) {
    return <Navigate to="/sign-in" replace />
  }

  if (auth.method !== null) {
    return <Navigate to="/" replace />
  }

  if (selectedMethod === 'totp') {
    return (
      <SignUp2FAOTP otpKeyUri={otpKeyUri!} onSwitchMethod={switchMethod} onValidate={validateOTP} />
    )
  }

  if (selectedMethod === 'sms') {
    return (
      <SignUp2FASMS
        phone={phone}
        onSwitchMethod={switchMethod}
        onAddPhone={addPhone}
        onClearPhone={clearPhone}
        onValidate={validateSMS}
      />
    )
  }

  return <SignUP2FASelect onSelectSMS={selectSMS} onSelectOTP={selectOTP} />
}

export default SignUp2FA

import React from 'react'
import { Avatar, Box, Chip, makeStyles, Typography, CardContent } from '@material-ui/core'
import { AvatarGroup } from '@material-ui/lab'
import { Button, Callout } from '@gground/capcom.core'
import { FlagGB, FlagUS, FlagSG, FlagHK, FlagAU } from '@gground/capcom.icons.flags'
import { Arrow as ArrowIcon, ExternalLink } from '@gground/capcom.icons'

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    minWidth: 'fit-content',
    marginLeft: theme.spacing(2),
    '& .MuiButton-iconSizeMedium > *:first-child': {
      fontSize: '12px',
    },
  },
  emptyAccountTitle: {
    color: '#1C444E',
    fontWeight: 500,
  },
  roundedFlag: {
    width: 28,
    height: 28,
    boxShadow: '0px 0px 2px rgba(97, 97, 97, 0.2), 0px 1px 1px rgba(97, 97, 97, 0.2)',
    '&.MuiAvatar-root': {
      border: '2px solid #FFFFFF',
    },
  },
  moreCurrencies: {
    backgroundColor: '#EFF3ED',
    color: '#4D6242',
    border: '2px solid #FFFFFF',
    boxShadow: '0px 0px 2px rgba(97, 97, 97, 0.2), 0px 1px 1px rgba(97, 97, 97, 0.2)',
  },
  avatarGroup: {
    alignItems: 'center',
  },
  currenciesContainer: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    alignItems: 'center',
  },
  arrow: {
    margin: theme.spacing(0, 1),
    color: '#9CA7B4',
  },
}))

const AvailableCurrencies = () => {
  const classes = useStyles()

  return (
    <>
      <CardContent>
        <Box className={classes.currenciesContainer}>
          <AvatarGroup className={classes.avatarGroup}>
            <Avatar className={classes.roundedFlag}>
              <FlagAU />
            </Avatar>
            <Avatar className={classes.roundedFlag}>
              <FlagUS />
            </Avatar>
            <Avatar className={classes.roundedFlag}>
              <FlagSG />
            </Avatar>
            <Avatar className={classes.roundedFlag}>
              <FlagHK />
            </Avatar>
            <Chip label="+ 27 more" className={classes.moreCurrencies} />
          </AvatarGroup>
          <ArrowIcon className={classes.arrow} />
          <Avatar className={classes.roundedFlag}>
            <FlagGB />
          </Avatar>
        </Box>

        <Typography className={classes.emptyAccountTitle}>
          Deposit and exchange 31 different currencies
        </Typography>
        <Box mt={1} mb={2}>
          <Typography variant="subtitle1">
            Transfer any of our 21 supported currencies from outside of the UK.
          </Typography>
        </Box>
        <Callout severity="info">
          <Box display="flex" alignItems="center">
            <span>
              GetGround can open an international Currency Cloud account for your client. If you
              want to tell your client how they can do this, use this guide to learn how. It&apos;s
              easy to set up, and it&apos;s totally free.
            </span>
            <Box className={classes.buttonContainer}>
              <Button
                startIcon={<ExternalLink />}
                variant="outlined"
                customColors={{ background: '#FFFFFF' }}
                onClick={() => {
                  window.open(
                    'https://www.getground.co.uk/en/help/open-an-international-account-1',
                    '_blank',
                  )
                }}
              >
                Learn more
              </Button>
            </Box>
          </Box>
        </Callout>
      </CardContent>
    </>
  )
}

export default AvailableCurrencies

import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Box, DialogActions, makeStyles, Typography } from '@material-ui/core'
import pluralize from 'pluralize'
import { Button } from '@gground/capcom.core'
import { Download as DownloadIcon } from '@gground/capcom.icons'
import { useAppDispatch, useAppSelector } from 'src/store'
import {
  fetchDocumentLinks,
  fetchDocuments,
  downloadDocuments,
  deleteDocuments,
  editDocuments,
} from 'src/store/slices/company.slice'
import { trackSnowplow } from 'src/store/slices/user.slice'
import { showErrorToast, showSuccessToast } from 'src/utils/toasts'
import { Document, DocumentUpdate } from 'src/types'
import ModalDialog from 'src/components/ModalDialog/ModalDialog'
import type { CompanyOutletContext } from '../types'
import ConfirmDeleteModal from './ConfirmDeleteModal'
import DocumentsList from './DocumentsList'
import DocumentsUpload from './DocumentsUpload'
import DocumentsEditModal from './DocumentsEditModal'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    borderLeft: '1px solid #DEE0E3',
    borderRight: '1px solid #DEE0E3',
    backgroundColor: 'white',
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderBottom: '1px solid #DEE0E3',
    padding: theme.spacing(2, 3),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
    '& > button:disabled': {
      border: '1px solid transparent',
    },
    '& > *': {
      margin: theme.spacing(0, 1, 1, 0),
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  upload: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
}))

interface NoDocumentsSelectedModalProps {
  open: boolean
  onClose: () => void
}

const NoDocumentsSelectedModal = ({ open, onClose }: NoDocumentsSelectedModalProps) => (
  <ModalDialog open={open} onClose={onClose} title="No documents selected">
    <Box mt={1}>
      <Typography>You must select at least 1 document to download.</Typography>
    </Box>
    <DialogActions>
      <Button variant="contained" onClick={onClose}>
        OK
      </Button>
    </DialogActions>
  </ModalDialog>
)

const Documents = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const [showNoDocumentsSelected, setShowNoDocumentsSelected] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const { clientId, companyId } = useOutletContext<CompanyOutletContext>()
  const documents = useAppSelector((state) => state.company.documents)
  const [selected, setSelected] = useState<Record<Document['id'], boolean>>({})
  const [selectedUserDocuments, setSelectedUserDocuments] = useState<Document['id'][]>([])
  const [documentsToDelete, setDocumentsToDelete] = useState<Document['id'][]>([])
  const [documentsToEdit, setDocumentsToEdit] = useState<Document[]>([])

  useEffect(() => {
    setSelectedUserDocuments(
      documents
        .filter((doc) => selected[doc.id] && ['user', 'partner'].includes(doc.creator_type))
        .map((doc) => doc.id),
    )
  }, [documents, selected])

  useEffect(() => {
    if (clientId && companyId) {
      setLoading(true)
      dispatch(fetchDocuments({ clientId, companyId }))
        .unwrap()
        .then(() => setLoading(false))
        .catch(() => showErrorToast('Failed to fetch documents'))
    }
  }, [clientId, companyId, dispatch])

  useEffect(() => {
    setSelected(documents.reduce((ac, doc) => ({ ...ac, [doc.id]: false }), {}))
  }, [documents])

  const onToggle = (id: Document['id']) => {
    setSelected({ ...selected, [id]: !selected[id] })
  }

  const onToggleAll = () => {
    // select all if none selected, deselect all otherwise
    const value = !Object.values(selected).some((s) => !!s)
    setSelected(documents.reduce((ac, doc) => ({ ...ac, [doc.id]: value }), {}))
  }

  const onView = (id: Document['id']) => {
    dispatch(fetchDocumentLinks({ clientId, companyId, documents: [id] }))
      .unwrap()
      .then((result) => {
        window.open(result[0]?.signed_url, '_blank')
        dispatch(trackSnowplow({ category: 'documents', action: 'view_document' }))
      })
      .catch(() => showErrorToast('Failed to open document'))
  }

  const onDownload = (id: Document['id']) => {
    dispatch(fetchDocumentLinks({ clientId, companyId, documents: [id] }))
      .unwrap()
      .then((files) => dispatch(downloadDocuments({ clientId, companyId, files })).unwrap())
      .then(() => dispatch(trackSnowplow({ category: 'documents', action: 'download_document' })))
      .catch(() => showErrorToast('Failed to download document'))
  }

  const onDownloadSelected = () => {
    const ids = documents.filter((doc) => selected[doc.id]).map((doc) => doc.id)

    if (ids.length === 0) {
      setShowNoDocumentsSelected(true)
      return
    }

    dispatch(fetchDocumentLinks({ clientId, companyId, documents: ids }))
      .unwrap()
      .then((files) => dispatch(downloadDocuments({ clientId, companyId, files })).unwrap())
      .then(() => dispatch(trackSnowplow({ category: 'documents', action: 'bulk_download_docs' })))
      .catch(() => showErrorToast('Failed to download documents'))
  }

  const onDelete = (documentIds: Document['id'][]) => {
    setDocumentsToDelete(documentIds)
    setIsDeleteModalOpen(true)
  }

  const onDeleteCancel = () => {
    setDocumentsToDelete([])
    setIsDeleteModalOpen(false)
  }

  const onDeleteConfirm = () => {
    dispatch(deleteDocuments({ clientId, companyId, documentIds: documentsToDelete }))
      .unwrap()
      .then(() => {
        showSuccessToast(`${pluralize('Document', documentsToDelete.length)} deleted`)
        dispatch(trackSnowplow({ category: 'documents', action: 'delete_document' }))
      })
      .catch(() => showErrorToast('Failed to delete. Try again'))
      .finally(() => setDocumentsToDelete([]))

    setIsDeleteModalOpen(false)
  }

  const onEdit = (documentIds: Document['id'][]) => {
    setDocumentsToEdit(documents.filter((doc) => documentIds.includes(doc.id)))
    setEditModalOpen(true)
  }

  const onEditCancel = () => {
    setDocumentsToEdit([])
    setEditModalOpen(false)
  }

  const onEditSave = (updates: DocumentUpdate[]) => {
    dispatch(editDocuments({ clientId, companyId, updates }))
      .unwrap()
      .then(() => {
        showSuccessToast(`${pluralize('Document', documentsToEdit.length)} edited`)
        dispatch(trackSnowplow({ category: 'documents', action: 'edit_document' }))
      })
      .catch(() => showErrorToast('Failed to edit. Try again'))
      .finally(() => setDocumentsToEdit([]))

    setEditModalOpen(false)
  }

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.header}>
        <Box className={classes.actions}>
          {selectedUserDocuments.length > 0 && (
            <>
              <Button variant="text" onClick={() => onEdit(selectedUserDocuments)}>
                Edit {selectedUserDocuments.length}{' '}
                {pluralize('document', selectedUserDocuments.length)}
              </Button>

              <Button
                variant="text"
                palette="danger"
                onClick={() => onDelete(selectedUserDocuments)}
              >
                Delete {selectedUserDocuments.length}{' '}
                {pluralize('document', selectedUserDocuments.length)}
              </Button>
            </>
          )}
          <Button
            onClick={onDownloadSelected}
            startIcon={<DownloadIcon />}
            variant="outlined"
            disabled={loading}
          >
            Download documents
          </Button>
        </Box>
        <DocumentsUpload disabled={loading} className={classes.upload} />
      </Box>
      <DocumentsList
        documents={documents}
        loading={loading}
        selected={selected}
        onToggle={onToggle}
        onToggleAll={onToggleAll}
        onView={onView}
        onDownload={onDownload}
        onDelete={(id) => onDelete([id])}
        onEdit={(id) => onEdit([id])}
      />
      <ConfirmDeleteModal
        open={isDeleteModalOpen}
        onCancel={onDeleteCancel}
        onConfirm={onDeleteConfirm}
      />
      <DocumentsEditModal
        documents={documentsToEdit}
        open={editModalOpen}
        onCancel={onEditCancel}
        onSave={onEditSave}
      />
      <NoDocumentsSelectedModal
        open={showNoDocumentsSelected}
        onClose={() => setShowNoDocumentsSelected(false)}
      />
    </Box>
  )
}

export default Documents

import React from 'react'
import { Box, Card, CardHeader, CardContent, Grid, Typography, makeStyles } from '@material-ui/core'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { getOneLineAddress } from 'src/utils/misc'
import { showSuccessToast } from 'src/utils/toasts'
import type { CompanyDetails } from 'src/types'

const useStyles = makeStyles((theme) => ({
  box: {
    background: '#F9F9FA',
    borderRadius: 4,
    padding: theme.spacing(1),
    cursor: 'pointer',
    height: 58,
  },
  name: {
    color: '#4E545F',
  },
  value: {
    fontWeight: 500,
  },
  loadingContainer: {
    '& .MuiSkeleton-root': {
      transform: 'none',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
    },
  },
}))

interface Props {
  parentCompanyDetails: CompanyDetails['parent_company_details']
}

const HoldcoDetailsSection = ({ parentCompanyDetails }: Props) => {
  const classes = useStyles()
  return (
    <Card>
      <CardHeader
        title="Holding Company details"
        action={
          <Box>
            <Typography variant="subtitle1" component="i">
              Hint: Click on a field to copy it
            </Typography>
          </Box>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <Box className={classes.box}>
              <Typography variant="body1" className={classes.name}>
                Company name:
              </Typography>
              <CopyToClipboard
                text={parentCompanyDetails?.company_name || ''}
                onCopy={() => showSuccessToast('Copied')}
              >
                <Typography variant="body1" className={classes.value}>
                  {parentCompanyDetails?.company_name}
                </Typography>
              </CopyToClipboard>
            </Box>
          </Grid>
          <Grid item sm={4}>
            <Box className={classes.box}>
              <Typography variant="body1" className={classes.name}>
                Company number:
              </Typography>
              <CopyToClipboard
                text={parentCompanyDetails?.registration_number || ''}
                onCopy={() => showSuccessToast('Copied')}
              >
                <Typography variant="body1" className={classes.value}>
                  {parentCompanyDetails?.registration_number}
                </Typography>
              </CopyToClipboard>
            </Box>
          </Grid>
          <Grid item sm={4}>
            <Box className={classes.box}>
              <Typography variant="body1" className={classes.name}>
                UTR code:
              </Typography>
              <CopyToClipboard
                text={parentCompanyDetails?.utr_code || ''}
                onCopy={() => showSuccessToast('Copied')}
              >
                <Typography variant="body1" className={classes.value}>
                  {parentCompanyDetails?.utr_code}
                </Typography>
              </CopyToClipboard>
            </Box>
          </Grid>
          <Grid item sm={12}>
            <Box className={classes.box}>
              <Typography variant="body1" className={classes.name}>
                Full address:
              </Typography>
              <CopyToClipboard
                text={getOneLineAddress(parentCompanyDetails?.registered_address) || ''}
                onCopy={() => showSuccessToast('Copied')}
              >
                <Typography variant="body1" className={classes.value}>
                  {getOneLineAddress(parentCompanyDetails?.registered_address)}
                </Typography>
              </CopyToClipboard>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default HoldcoDetailsSection

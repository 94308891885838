import React from 'react'
import { Link } from 'react-router-dom'
import { Typography, Box, makeStyles, Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    '& > h2': {
      color: '#1C444E',
      fontWeight: 500,
      marginRight: theme.spacing(1),
    },
  },
  row: {
    borderBottom: '1px solid #DEE0E3',
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  notAdded: {
    color: theme.palette.error.main,
  },
}))

interface SectionRow {
  name: string
  value: string | React.ReactNode
}

interface Props {
  title: string
  link?: string
  rows: SectionRow[]
  onLinkClick?: () => void
}

const Section = ({ title, link, rows, onLinkClick }: Props) => {
  const classes = useStyles()
  return (
    <Box component="section">
      <Box className={classes.sectionHeader}>
        <Typography variant="h2">{title}</Typography>
        {link ? (
          <Link to={link} onClick={onLinkClick}>
            Change
          </Link>
        ) : null}
      </Box>
      {rows.map((row) => (
        <Grid container className={classes.row} key={row.name}>
          <Grid item xs={3}>
            <Typography variant="h3">{row.name}</Typography>
          </Grid>
          <Grid item xs={8}>
            {row.value ? (
              <Typography>{row.value} </Typography>
            ) : (
              <Typography className={classes.notAdded}>[Not added]</Typography>
            )}
          </Grid>
        </Grid>
      ))}
    </Box>
  )
}
export default Section

import React, { useState } from 'react'
import {
  Box,
  DialogActions,
  FormHelperText,
  MenuItem,
  RadioGroup,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Button, ButtonOpeningModal } from '@gground/capcom.core'
import { Plus as PlusIcon } from '@gground/capcom.icons'
import { Input, Select, Radio } from '@gground/capcom.form'
import { Controller, useForm } from 'react-hook-form'
import { isEmail } from 'src/utils/validate'
import { useAppDispatch } from 'src/store'
import { Client, ClientOverview, OverviewStatus } from 'src/types'
import { countries } from 'src/constants'
import { CreateClientOptions } from 'src/api/clients'
import { trackSnowplow } from 'src/store/slices/user.slice'
import { validateLettersOnly } from './utils'

const useStyles = makeStyles({
  selectContainer: {
    '& .MuiSvgIcon-root': {
      width: 'auto',
    },
  },
})

interface ClientSelectionFormProps {
  clients: ClientOverview[]
  onSubmit: (data: CreateClientOptions) => void
  onCancel: () => void
}

const ClientSelectionForm = ({ onSubmit, onCancel, clients }: ClientSelectionFormProps) => {
  const { handleSubmit, register, clearErrors, control, errors } = useForm<CreateClientOptions>({
    shouldFocusError: false,
    reValidateMode: 'onSubmit',
  })
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const [newClient, setNewClient] = useState<boolean>(true)
  const firstClient = clients.length === 0

  const validateNewEmail = (val: string) => {
    const email = val.trim()

    if (!isEmail(email)) {
      return 'Invalid email address'
    }
    if (!firstClient && clients.find((c) => c.email === email)) {
      return 'This email is already associated with an existing client'
    }
    return true
  }

  return (
    <form onSubmit={handleSubmit((formData) => onSubmit(formData))} noValidate>
      <Typography variant="body1">Who is this company for?</Typography>

      <RadioGroup>
        {!firstClient && (
          <Box mt={2}>
            <Radio
              name="newClient"
              label="New client"
              checked={newClient === true}
              onChange={() => setNewClient(true)}
            />
          </Box>
        )}

        {newClient === true || firstClient ? (
          <Box mb={firstClient ? 0 : 2}>
            <Input
              required
              fullWidth
              id="email"
              name="email"
              label="Client email"
              ref={register({
                required: 'This field is required',
                validate: validateNewEmail,
              })}
              onFocus={() => clearErrors()}
              error={Boolean(errors.email)}
              helperText={errors?.email && errors.email?.message}
            />
            <Box mt={2}>
              <Input
                required
                type="text"
                id="first_name"
                name="first_name"
                label="First name"
                helperText={errors.first_name && errors.first_name?.message}
                error={!!errors.first_name}
                onFocus={() => clearErrors()}
                ref={register({
                  validate: validateLettersOnly,
                  required: 'This field is required',
                })}
                fullWidth
              />
            </Box>
            <Box mt={2}>
              <Input
                required
                type="text"
                id="last_name"
                name="last_name"
                label="Last name"
                helperText={errors.last_name && errors.last_name?.message}
                error={!!errors.last_name}
                onFocus={() => clearErrors()}
                ref={register({
                  validate: validateLettersOnly,
                  required: 'This field is required',
                })}
                fullWidth
              />
            </Box>
            <Box mt={2} className={classes.selectContainer}>
              <Controller
                name="country"
                control={control}
                rules={{
                  required: 'This field is required',
                }}
                defaultValue=""
                render={({ onChange, value }) => (
                  <Select
                    id="country"
                    name="country"
                    label="Country of residence"
                    value={value}
                    onChange={onChange}
                    fullWidth
                    error={errors.country}
                  >
                    {Object.values(countries).map(({ country_name, id }) => (
                      <MenuItem key={id} value={id}>
                        {country_name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>
          </Box>
        ) : null}

        {!firstClient && (
          <Box mt={1}>
            <Radio
              name="existingClient"
              label="Existing client (details already on this platform)"
              checked={newClient === false}
              onChange={() => setNewClient(false)}
            />
          </Box>
        )}

        {newClient === false && (
          <Box mt={1} className={classes.selectContainer}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: 'This field is required',
              }}
              render={({ onChange, value }) => (
                <Select
                  id="email"
                  name="email"
                  label="Name"
                  value={value}
                  onChange={(e) => {
                    dispatch(
                      trackSnowplow({
                        category: 'dashboard',
                        action: 'existing_client_chosen',
                      }),
                    )
                    clearErrors()
                    onChange(e)
                  }}
                  fullWidth
                >
                  {Object.values(clients).map(({ name, email }) => (
                    <MenuItem key={email} value={email}>
                      {name || email}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors?.email ? <FormHelperText error>{errors?.email?.message}</FormHelperText> : null}
          </Box>
        )}
      </RadioGroup>
      <Box mt={2}>
        <DialogActions>
          <Button onClick={onCancel} variant="text">
            Cancel
          </Button>
          <Box ml={1}>
            <Button type="submit" color="primary" variant="contained">
              Continue
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </form>
  )
}

interface PendingApprovalProps {
  client: ClientOverview
  onDismiss: () => void
}

const PendingApproval = ({ onDismiss, client }: PendingApprovalProps) => (
  <Box>
    <Typography>
      <span>You must send </span>
      <strong>{client.name || client.email}</strong>
      <span>&apos;s </span>
      <span>first company to them for approval before adding another company for them.</span>
    </Typography>
    <DialogActions>
      <Button color="primary" variant="contained" onClick={onDismiss}>
        OK
      </Button>
    </DialogActions>
  </Box>
)

interface AddCompanyButtonProps {
  clients: ClientOverview[]
  disabled?: boolean
  onAddToClient: (number: Client['id']) => void
  onCreateClient: (options: CreateClientOptions) => void
}

const AddCompanyButton = ({
  clients,
  disabled,
  onAddToClient,
  onCreateClient,
}: AddCompanyButtonProps) => {
  const [clientPendingApproval, setClientPendingApproval] = useState<ClientOverview | null>(null)
  const dispatch = useAppDispatch()

  const handleSubmit = (data: CreateClientOptions) => {
    dispatch(trackSnowplow({ category: 'dashboard', action: 'add_company_popup_continue' }))
    const client = clients.find((c) => c.email === data.email)
    const companies = client?.property_purchases ?? []
    const approval = companies.some((c) => c.client_approval !== OverviewStatus.TODO)

    if (!client) {
      onCreateClient(data)
      return
    }

    if (!approval) {
      setClientPendingApproval(client)
      return
    }

    onAddToClient(client.id)
  }

  return (
    <ButtonOpeningModal
      title={
        clientPendingApproval
          ? 'You cannot yet create another company for this client'
          : 'Add company'
      }
      button={
        <Button disabled={disabled} startIcon={<PlusIcon style={{ fontSize: '16px' }} />}>
          Add company
        </Button>
      }
      dialogProps={{
        maxWidth: 'sm',
        fullWidth: true,
      }}
      handleClick={() => {
        setClientPendingApproval(null)
        dispatch(trackSnowplow({ category: 'dashboard', action: 'add_company' }))
      }}
    >
      {(closeModal) => (
        <Box width="100%">
          {clientPendingApproval ? (
            <PendingApproval
              client={clientPendingApproval}
              onDismiss={() => setClientPendingApproval(null)}
            />
          ) : (
            <ClientSelectionForm clients={clients} onCancel={closeModal} onSubmit={handleSubmit} />
          )}
        </Box>
      )}
    </ButtonOpeningModal>
  )
}

export default AddCompanyButton

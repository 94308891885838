import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Grid,
  CardActions,
} from '@material-ui/core'
import { Button } from '@gground/capcom.core'
import { Copy as CopyIcon } from '@gground/capcom.icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useAppDispatch, useAppSelector } from 'src/store'
import { fetchBusinessAccountDetails } from 'src/store/slices/company.slice'
import { showSuccessToast } from 'src/utils/toasts'
import AccountTableRow from './AccountTableRow'
import AvailableCurrencies from './AvailableCurrencies'
import type { CompanyOutletContext } from '../../types'

const useStyles = makeStyles((theme) => ({
  card: {
    height: 'fit-content',
    margin: theme.spacing(3),
  },
  title: {
    '& span': {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  actions: {
    backgroundColor: '#FFF',
    justifyContent: 'end',
    padding: 16,
    paddingTop: 0,
  },
  container: {
    '& .MuiGrid-item': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}))

const AccountDetails = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { clientId, companyId } = useOutletContext<CompanyOutletContext>()
  const {
    institution_name = '',
    institution_address = '',
    account_name = '',
    registered_address = '',
    sort_code = '',
    account_number = '',
    international_details,
  } = useAppSelector((state) => state.company.wallet?.details) || {}

  const { bank_name = '', bank_address = '', iban = '', bic = '' } = international_details || {}

  useEffect(() => {
    if (clientId && companyId) {
      dispatch(fetchBusinessAccountDetails({ companyId, clientId }))
    }
  }, [clientId, companyId, dispatch])

  const UKTableRows = [
    { label: 'Institution name', value: institution_name },
    {
      label: 'Institution address',
      value: institution_address,
    },
    { label: 'Account name', value: account_name },
    { label: 'Address', value: registered_address },
    { label: 'Sort code', value: sort_code },
    { label: 'Account number', value: account_number },
  ]
  const allBankDetails = `${institution_name}\n${institution_address}\n${account_name}\n${registered_address}\n${sort_code}\n${account_number}`

  const IntTableRows = [
    { label: 'Institution name', value: bank_name },
    {
      label: 'Institution address',
      value: bank_address,
    },
    { label: 'Account name', value: account_name },
    { label: 'Address', value: registered_address },
    { label: 'IBAN', value: iban },
    { label: 'SWIFT/BIC', value: bic },
  ]
  const intAccountDetails = `${bank_name}\n${bank_address}\n${account_name}\n${registered_address}\n${iban}\n${bic}`

  return (
    <Box className={classes.container}>
      <Card className={classes.card}>
        <CardHeader title="UK Account" className={classes.title} />
        <CardContent>
          <Box>
            <Grid container className={classes.container}>
              {UKTableRows.map(({ label, value }) => (
                <AccountTableRow label={label} value={value} key={label} />
              ))}
            </Grid>
          </Box>
        </CardContent>
        <CardActions className={classes.actions}>
          <CopyToClipboard
            text={allBankDetails}
            onCopy={() => showSuccessToast('Bank details copied')}
          >
            <Button startIcon={<CopyIcon />}>Copy all</Button>
          </CopyToClipboard>
        </CardActions>
      </Card>
      <Card className={classes.card}>
        <CardHeader title="International Account" className={classes.title} />
        {international_details ? (
          <>
            <CardContent>
              <Box>
                <Grid container className={classes.container}>
                  {IntTableRows.map(({ label, value }) => (
                    <AccountTableRow label={label} value={value} key={label} />
                  ))}
                </Grid>
              </Box>
            </CardContent>
            <CardActions className={classes.actions}>
              <CopyToClipboard
                text={intAccountDetails}
                onCopy={() => showSuccessToast('International account details copied')}
              >
                <Button startIcon={<CopyIcon />}>Copy all</Button>
              </CopyToClipboard>
            </CardActions>
          </>
        ) : (
          <AvailableCurrencies />
        )}
      </Card>
    </Box>
  )
}

export default AccountDetails

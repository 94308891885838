import { Transaction } from 'src/types'
import { transactionOutboundIconsMap, transactionInboundIconsMap } from './transactionsIconsMap'

interface Props {
  direction: Transaction['direction']
}

const useCategory = ({ direction }: Props) => {
  const txsIconsMap =
    direction === 'OUTBOUND'
      ? transactionOutboundIconsMap
      : direction === 'INBOUND'
      ? transactionInboundIconsMap
      : []

  return { txsIconsMap }
}

export default useCategory

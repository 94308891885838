import { useSnackbar, ProviderContext } from 'notistack'

let snackbar: ProviderContext

export const SnackbarConfigurator = () => {
  snackbar = useSnackbar()
  return null
}

export const showInfoToast = (msg: string) => snackbar.enqueueSnackbar(msg, { variant: 'info' })
export const showSuccessToast = (msg: string) =>
  snackbar.enqueueSnackbar(msg, { variant: 'success' })
export const showWarningToast = (msg: string) =>
  snackbar.enqueueSnackbar(msg, { variant: 'warning' })
export const showErrorToast = (msg: string) => snackbar.enqueueSnackbar(msg, { variant: 'error' })

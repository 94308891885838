export const DOCUMENT_TYPES = [
  {
    type: 'Completion statement',
    label: 'Completion statement',
    hasDate: true,
    isExpense: false,
  },
  {
    type: 'Deposit',
    label: 'Deposit',
    hasDate: true,
    isExpense: false,
  },
  {
    type: 'End of year accounts',
    label: 'End of year accounts',
    hasDate: false,
    isExpense: false,
  },
  {
    type: 'End of year tax returns',
    label: 'End of year tax returns',
    hasDate: false,
    isExpense: false,
  },
  {
    type: 'Evidence of property purchase funds',
    label: 'Evidence of property purchase funds',
    hasDate: true,
    isExpense: false,
  },
  {
    type: 'EXPENSES',
    label: 'Expenses',
    hasDate: false,
    isExpense: true,
  },
  {
    type: 'GetGround receipts',
    label: 'GetGround receipts',
    hasDate: false,
    isExpense: false,
  },
  {
    type: 'Incorporation documents',
    label: 'Incorporation documents',
    hasDate: false,
    isExpense: false,
  },
  {
    type: 'Leaving GetGround',
    label: 'Leaving GetGround',
    hasDate: false,
    isExpense: false,
  },
  {
    type: 'Management letting agreement',
    label: 'Management letting agreement',
    hasDate: true,
    isExpense: false,
  },
  {
    type: 'Miscellaneous',
    label: 'Miscellaneous',
    hasDate: false,
    isExpense: false,
  },
  {
    type: 'Mortgage statement',
    label: 'Mortgage statement',
    hasDate: true,
    isExpense: false,
  },
  {
    type: 'Mortgages',
    label: 'Mortgages',
    hasDate: false,
    isExpense: false,
  },
  {
    type: 'Offer letter',
    label: 'Offer letter',
    hasDate: true,
    isExpense: false,
  },
  {
    type: 'Post',
    label: 'Post',
    hasDate: false,
    isExpense: false,
  },
  {
    type: 'Property mortgage documents',
    label: 'Property mortgage documents',
    hasDate: false,
    isExpense: false,
  },
  {
    type: 'Property purchase contract',
    label: 'Property purchase contract',
    hasDate: true,
    isExpense: false,
  },
  {
    type: 'Property purchase documents',
    label: 'Property purchase documents',
    hasDate: false,
    isExpense: false,
  },
  {
    type: 'Property rental documents',
    label: 'Property rental documents',
    hasDate: false,
    isExpense: false,
  },
  {
    type: 'Refinance mortgage statements',
    label: 'Refinance mortgage statements',
    hasDate: true,
    isExpense: false,
  },
  {
    type: 'Rental statement',
    label: 'Rental statement',
    hasDate: true,
    isExpense: false,
  },
  {
    type: 'Reservation fee',
    label: 'Reservation fee',
    hasDate: true,
    isExpense: false,
  },
  {
    type: 'Secretarial documents',
    label: 'Secretarial documents',
    hasDate: false,
    isExpense: false,
  },
] as const

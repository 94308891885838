import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/store'
import { Auth2FAMethod } from 'src/types'
import { signUp2FAInit, signUp2FAValidate, trackSnowplow } from 'src/store/slices/user.slice'
import { showErrorToast } from 'src/utils/toasts'

const useSignUp2FA = () => {
  const auth = useAppSelector((state) => state.user.auth)
  const [selectedMethod, setSelectedMethod] = useState<Auth2FAMethod>(null)
  const [otpKeyUri, setOtpKeyUri] = useState<string | null>(null)
  const [phone, setPhone] = useState<string | null>(null)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const selectSMS = () => setSelectedMethod('sms')

  const selectOTP = () =>
    dispatch(signUp2FAInit({ method: 'totp' }))
      .unwrap()
      .then((payload) => {
        setSelectedMethod('totp')
        setOtpKeyUri(payload.key_uri!)
      })
      .catch(() => showErrorToast('Failed to initiate 2FA setup'))

  const switchMethod = () => {
    setSelectedMethod(null)
    setOtpKeyUri(null)
    setPhone(null)
  }

  const addPhone = (number: string) => {
    dispatch(signUp2FAInit({ method: 'sms', phone: number }))
      .unwrap()
      .then(() => {
        setSelectedMethod('sms')
        setPhone(number)
      })
      .catch(() => showErrorToast('Failed to initiate 2FA setup'))
  }

  const clearPhone = () => setPhone(null)

  const validateOTP = (otp: string) => {
    dispatch(signUp2FAValidate({ method: 'totp', otp }))
      .unwrap()
      .then(() => {
        dispatch(trackSnowplow({ category: '2fa', action: 'google_otp_set' }))
        navigate('/dashboard')
      })
      .catch(() => showErrorToast('Failed to validate 2FA'))
  }

  const validateSMS = (otp: string) => {
    dispatch(signUp2FAValidate({ method: 'sms', phone: phone!, otp }))
      .unwrap()
      .then(() => {
        dispatch(trackSnowplow({ category: '2fa', action: 'sms_otp_set' }))
        navigate('/dashboard')
      })
      .catch(() => showErrorToast('Failed to validate 2FA'))
  }

  return {
    auth,
    otpKeyUri,
    phone,
    selectedMethod,
    selectSMS,
    selectOTP,
    switchMethod,
    addPhone,
    clearPhone,
    validateOTP,
    validateSMS,
  }
}

export default useSignUp2FA

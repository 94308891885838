import { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useForm, useFieldArray } from 'react-hook-form'

import { useAppDispatch } from 'src/store'
import {
  createPropertyPurchase,
  fetchPropertyPurchaseDetails,
  updatePropertyPurchaseDetails,
} from 'src/store/slices/propertyPurchases.slice'
import { fetchClientDetails } from 'src/store/slices/clients.slice'
import { hasDuplicates } from 'src/utils/misc'
import { showErrorToast } from 'src/utils/toasts'
import { Client, Shareholder } from 'src/types'
import { trackSnowplow } from 'src/store/slices/user.slice'

type RouteParams = {
  clientId: string
  companyId: string
}

type LocationState = {
  isHoldCo: boolean
}

const useShareholdingStructure = () => {
  const dispatch = useAppDispatch()
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [client, setClient] = useState<Client>()

  const params = useParams<RouteParams>()
  const location = useLocation()
  const navigate = useNavigate()
  const { isHoldCo } = (location.state ?? {}) as LocationState
  const clientId = parseInt(params.clientId ?? '', 10)
  const companyId = parseInt(params.companyId ?? '', 10)

  const [_isHoldCo, _setIsHoldCo] = useState(isHoldCo)

  const originalShareholder: Shareholder = {
    first_name: client?.first_name ?? '',
    last_name: client?.last_name ?? '',
    email: client?.email ?? '',
    allocated_shares: _isHoldCo ? 1000 : 100,
    is_director: true,
    is_existing_user: true,
    user_id: client?.id ?? null,
  }

  const form = useForm({
    reValidateMode: 'onSubmit',
  })

  const fieldArray = useFieldArray({
    control: form.control,
    name: 'shareholders',
  })

  useEffect(() => {
    if (!clientId) {
      navigate('/dashboard')
      return
    }

    dispatch(trackSnowplow({ category: 'shareholding', action: 'shareholding_visit' }))
    setIsLoading(true)
    dispatch(fetchClientDetails({ id: clientId }))
      .unwrap()
      .then((c) => {
        setClient(c)
        setIsLoading(false)
      })
      .catch(() => showErrorToast('Failed to load client data'))
  }, [])

  useEffect(() => {
    const fetchShareHolders = () => {
      if (!companyId) return Promise.resolve([originalShareholder])

      return dispatch(fetchPropertyPurchaseDetails({ clientId, propertyPurchaseId: companyId }))
        .unwrap()
        .then((pp) => {
          // TODO: rework this logic so we don't have to redirect once on
          // this page, but redirect from the Resume CTA
          if (pp.parent_company_id && !pp.is_holding_co) {
            navigate(`/clients/${clientId}/companies/${pp.id}/details`)
          }
          _setIsHoldCo(pp.is_holding_co)
          return pp.shareholder_details.shareholders
        })
    }

    if (client) {
      setIsLoading(true)
      fetchShareHolders()
        .then((shareholders) => {
          const sorted = [...shareholders].sort((s1) => (s1.email === client.email ? -1 : 0))
          fieldArray.prepend(sorted)
          setIsLoading(false)
        })
        .catch(() => showErrorToast('Failed to load company data'))
    }
  }, [client])

  const createCompany = (shareholders: Shareholder[]) =>
    dispatch(
      createPropertyPurchase({
        clientId,
        payload: {
          is_holding_co: _isHoldCo,
          shareholder_details: {
            shareholders,
            is_complete: false,
          },
        },
      }),
    )
      .unwrap()
      .catch((e) => {
        showErrorToast('Failed to update company data')
        navigate('/dashboard')
        throw e
      })

  const updateCompany = (shareholders: Shareholder[]) =>
    dispatch(
      updatePropertyPurchaseDetails({
        clientId,
        propertyPurchaseId: companyId,
        payload: {
          shareholder_details: {
            shareholders,
            is_complete: false,
          },
        },
      }),
    )
      .unwrap()
      .catch((e) => {
        showErrorToast('Failed to update company data')
        navigate('/dashboard')
        throw e
      })

  const saveAndExit = form.handleSubmit(({ shareholders }) => {
    if (shareholders) {
      shareholders[0] = { ...originalShareholder, ...shareholders[0] }

      const savePromise = companyId ? updateCompany(shareholders) : createCompany(shareholders)

      savePromise.then(() => navigate('/dashboard'))
    }
  })

  const saveAndContinue = form.handleSubmit(({ shareholders }) => {
    dispatch(trackSnowplow({ category: 'shareholding', action: 'shareholding_next' }))
    if (shareholders) {
      shareholders[0] = { ...originalShareholder, ...shareholders[0] }
      const totalShares = shareholders.reduce((acc: number, shareholder: Shareholder) => {
        return acc + shareholder.allocated_shares
      }, 0)
      if (totalShares !== (_isHoldCo ? 1000 : 100)) {
        setErrorMessage(
          _isHoldCo
            ? 'Company shares must add up to 1000 in total'
            : 'Company shares must add up to 100% in total',
        )
        return
      }
      const hasDirector = shareholders.some((item: Shareholder) => item.is_director)
      if (!hasDirector) {
        setErrorMessage('At least one shareholder is required to be a director')
        return
      }
      const emailCollection = shareholders.map((item: Shareholder) => item.email)
      if (hasDuplicates(emailCollection)) {
        setErrorMessage(
          'Email used by another shareholder in this company. Please use unique emails.',
        )
        return
      }

      const savePromise = companyId ? updateCompany(shareholders) : createCompany(shareholders)

      savePromise.then(({ id }) => navigate(`/clients/${clientId}/companies/${id}/details`))
    }
  })

  const getError = (index: number, type: string) => !!form.errors?.shareholders?.[index]?.[type]

  return {
    form,
    getError,
    saveAndContinue,
    saveAndExit,
    errorMessage,
    isHoldCo: _isHoldCo,
    setErrorMessage,
    fieldArray,
    isLoading,
    companyId,
  }
}

export default useShareholdingStructure

import React from 'react'
import { FormProvider } from 'react-hook-form'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { Button } from '@gground/capcom.core'
import BackButton from 'src/components/BackButton'
import Layout from 'src/components/Layout'
import useClientDetails from './useClientDetails'
import ClientNameFormFragment from './ClientNameFormFragment'
import DateOfBirthFormFragment from './DateOfBirthFormFragment'
import NationalityFormFragment from './NationalityFormFragment'
import PassportNumberFormFragment from './PassportNumberFormFragment'
import ProfessionFormFragment from './ProfessionFormFragment'
import OtherPropertiesFormFragment from './OtherPropertiesFormFragment'
import AddressFormFragment from './AddressFormFragment'
import PhoneNumberFormFragment from './PhoneNumberFormFragment'

const useStyles = makeStyles({
  form: {
    maxWidth: 600,
  },
})

const ClientDetails = () => {
  const classes = useStyles()
  const { form, saveAndContinue, saveAndExit } = useClientDetails()

  return (
    <Layout saveAndExit={saveAndExit}>
      <Box mt={2}>
        <BackButton to="/dashboard" />
      </Box>

      <Box mt={2}>
        <Typography variant="h1">Client profile</Typography>
      </Box>

      <FormProvider {...form}>
        <form className={classes.form} onSubmit={saveAndContinue}>
          <ClientNameFormFragment />
          <DateOfBirthFormFragment />
          <NationalityFormFragment />
          <PassportNumberFormFragment />
          <ProfessionFormFragment />
          <OtherPropertiesFormFragment />
          <AddressFormFragment />
          <PhoneNumberFormFragment />
          <Box mt={5} mb={2}>
            <Button type="submit">Save and continue</Button>
          </Box>
        </form>
      </FormProvider>
    </Layout>
  )
}

export default ClientDetails

import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core/styles'

const CheckboxIcon = (props: SvgIconProps) => {
  const theme = useTheme()
  const { stroke } = props
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <rect
        x="2.5"
        y="2.5"
        width="19"
        height="19"
        rx="3.5"
        fill="white"
        stroke={stroke ?? theme.palette.primary.main}
      />
    </SvgIcon>
  )
}

export default CheckboxIcon

import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Bin = (props: SvgIconProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.42871 6.85714C3.42871 6.38376 3.81247 6 4.28585 6H19.7144C20.1878 6 20.5716 6.38376 20.5716 6.85714C20.5716 7.33053 20.1878 7.71429 19.7144 7.71429H4.28585C3.81247 7.71429 3.42871 7.33053 3.42871 6.85714Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2859 4.28571C10.0586 4.28571 9.84058 4.37602 9.67983 4.53676C9.51909 4.69751 9.42878 4.91553 9.42878 5.14286V6H14.5716V5.14286C14.5716 4.91553 14.4813 4.69751 14.3206 4.53676C14.1598 4.37602 13.9418 4.28571 13.7145 4.28571H10.2859ZM16.2859 6V5.14286C16.2859 4.46087 16.015 3.80682 15.5328 3.32458C15.0505 2.84234 14.3965 2.57143 13.7145 2.57143H10.2859C9.60394 2.57143 8.94989 2.84234 8.46765 3.32458C7.98541 3.80682 7.71449 4.46087 7.71449 5.14286V6H6.00021C5.52682 6 5.14307 6.38375 5.14307 6.85714V18.8571C5.14307 19.5391 5.41398 20.1932 5.89622 20.6754C6.37846 21.1577 7.03251 21.4286 7.71449 21.4286H16.2859C16.9679 21.4286 17.622 21.1577 18.1042 20.6754C18.5864 20.1932 18.8574 19.5391 18.8574 18.8571V6.85714C18.8574 6.38375 18.4736 6 18.0002 6H16.2859ZM6.85735 7.71428V18.8571C6.85735 19.0845 6.94766 19.3025 7.1084 19.4632C7.26915 19.624 7.48717 19.7143 7.71449 19.7143H16.2859C16.5133 19.7143 16.7313 19.624 16.892 19.4632C17.0528 19.3025 17.1431 19.0845 17.1431 18.8571V7.71428H6.85735Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2859 10.2857C10.7592 10.2857 11.143 10.6695 11.143 11.1428V16.2857C11.143 16.7591 10.7592 17.1428 10.2859 17.1428C9.81247 17.1428 9.42871 16.7591 9.42871 16.2857V11.1428C9.42871 10.6695 9.81247 10.2857 10.2859 10.2857Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7146 10.2857C14.188 10.2857 14.5717 10.6695 14.5717 11.1428V16.2857C14.5717 16.7591 14.188 17.1428 13.7146 17.1428C13.2412 17.1428 12.8574 16.7591 12.8574 16.2857V11.1428C12.8574 10.6695 13.2412 10.2857 13.7146 10.2857Z"
    />
  </SvgIcon>
)

export default Bin

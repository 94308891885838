import React, { useCallback, useEffect, useState } from 'react'
import { DialogActions } from '@material-ui/core'
import { Button } from '@gground/capcom.core'
import ModalDialog from 'src/components/ModalDialog/ModalDialog'
import { FormProvider, useForm } from 'react-hook-form'
import pluralize from 'pluralize'
import { Document, DocumentUpdate } from 'src/types'
import DocumentsFormFragment from './DocumentsFormFragment'
import { DocumentsForm, FormDoc } from './types'

interface DocumentsEditModalProps {
  open: boolean
  documents: Document[]
  onCancel: () => void
  onSave: (updates: DocumentUpdate[]) => void
}

const DocumentsEditModal = ({ open, documents, onCancel, onSave }: DocumentsEditModalProps) => {
  const form = useForm<DocumentsForm>({
    shouldFocusError: false,
    reValidateMode: 'onSubmit',
  })
  const [docs, setDocs] = useState<FormDoc[]>([])

  useEffect(() => {
    setDocs(open ? documents : [])
  }, [documents, open])

  const removeDocument = useCallback(
    (index: number) => {
      const updated = [...docs.slice(0, index), ...docs.slice(index + 1)]
      setDocs(updated)

      if (updated.length === 0) {
        onCancel()
      }
    },
    [docs, setDocs, onCancel],
  )

  const handleSubmit = useCallback(
    ({ list }: DocumentsForm) =>
      onSave(
        list.map(
          (formDoc, index) =>
            ({
              document_date: null,
              expense_category: null,
              currency: null,
              description: null,
              merchant_name: null,
              amount_in_cents: null,
              ...formDoc,
              id: docs[index].id,
            } as DocumentUpdate),
        ),
      ),
    [onSave, docs],
  )

  return (
    <ModalDialog
      open={open}
      onClose={onCancel}
      title={`Edit ${pluralize('document', docs.length)}`}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <DocumentsFormFragment documents={docs} onRemove={removeDocument} />
          <DialogActions>
            <Button onClick={onCancel} variant="text">
              Cancel
            </Button>
            <Button type="submit" variant="contained" data-testid="edit-documents-button">
              Save
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </ModalDialog>
  )
}

export default DocumentsEditModal

import React from 'react'
import { Box, Card, CardActions, CardContent, makeStyles, Typography } from '@material-ui/core'
import CircledArrowIcon from 'src/icons/CircledArrowIcon'

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: `
        0px 0px 2px rgba(0, 0, 0, 0.2),
        0px 1px 1px rgba(0, 0, 0, 0.2),
        0px 2px 6px rgba(97, 97, 97, 0.2)
      `,
    border: 0,
    margin: 0,
    background: 'white',
    cursor: 'pointer',
  },
  content: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    flex: '1 1 auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.info.main,
    fontWeight: 500,
    marginRight: theme.spacing(1),
  },
  body: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: theme.spacing(3),
    '& > :first-child': {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(-0.5),
    },
    '& > :last-child': {
      marginRight: theme.spacing(1),
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    height: 50,
    borderTop: '1px solid #10272D',
    padding: theme.spacing(1, 2),
  },
  cta: {
    color: 'white',
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  arrowIcon: {
    color: 'transparent',
  },
}))

interface SignUp2FACardProps {
  title: string
  cta: string
  text: JSX.Element
  icon: JSX.Element
  image: JSX.Element
  onClick: () => void
}

export const SignUp2FACard = ({ title, cta, text, icon, image, onClick }: SignUp2FACardProps) => {
  const classes = useStyles()
  return (
    <Card variant="outlined" onClick={onClick} tabIndex={0} className={classes.card}>
      <CardContent className={classes.content}>
        <Box className={classes.header}>
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
          {icon}
        </Box>
        <Box className={classes.body}>
          {image}
          <Typography>{text}</Typography>
        </Box>
      </CardContent>
      <CardActions className={classes.footer}>
        <Typography className={classes.cta}>{cta}</Typography>
        <CircledArrowIcon className={classes.arrowIcon} />
      </CardActions>
    </Card>
  )
}

import React from 'react'
import { OverviewStatus, PropertyPurchaseOverview } from 'src/types'
import ProgressStep from './ProgressStep'
import StepTask from './StepTask'

interface BusinessAccountStepProps {
  propertyPurchase?: PropertyPurchaseOverview
}

const BusinessAccountStep = ({ propertyPurchase }: BusinessAccountStepProps) => {
  const { signing } = propertyPurchase?.company_formation ?? {}
  const hasBusinessAccount = !!propertyPurchase?.has_business_account

  // derive step status from company formation signing business account flag
  const status = hasBusinessAccount
    ? OverviewStatus.COMPLETE
    : signing === OverviewStatus.COMPLETE
    ? OverviewStatus.IN_PROGRESS
    : OverviewStatus.NOT_STARTED

  return (
    <ProgressStep status={status} name="Business account setup" position={5}>
      {status === OverviewStatus.IN_PROGRESS ? (
        <StepTask
          name="Waiting for business account to open (1-2 days)"
          status={status}
          highlighted
        />
      ) : null}
    </ProgressStep>
  )
}

export default BusinessAccountStep

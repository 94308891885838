import Big from 'big.js'
import { Address } from 'src/types'

export const stringToBoolean = (value: string) => Boolean(value === 'yes')

export const booleanToString = (value: boolean) => (value ? 'yes' : value === null ? null : 'no')

export const hasDuplicates = (arr: string[]) =>
  arr.filter((item, index) => arr.indexOf(item) !== index)?.length > 0

export const getOneLineAddress = (address: Address, long = false) => {
  if (!address?.street) {
    return null
  }

  const { premise, street, post_town, post_code } = address
  return premise
    ? `${premise} ${street}, ${post_town}${long && post_code ? `, ${post_code}` : ''}`
    : `${street}, ${post_town}${long && post_code ? `, ${post_code}` : ''}`
}

export const stripCurrency = (amount: string) => amount.replace(/[,.]/g, '')

export const formatCurrency = (amount: number, inCents = false, thousands = ',') => {
  if (!amount) {
    return null
  }

  let amountToFormat = amount.toString()
  if (inCents) {
    amountToFormat = amountToFormat.substring(0, amountToFormat.length - 2)
  }
  const i = parseInt(
    (amountToFormat = Math.abs(Number(stripCurrency(amountToFormat)) || 0)),
  ).toString()
  const j = i.length > 3 ? i.length % 3 : 0

  return (
    (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`)
  )
}
export const formatCurrencyWithComma = (value: number | string) =>
  Number.parseFloat(value).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

export const formatCurrencyToTwoDP = (value: number) => {
  return formatCurrencyWithComma(
    Big(value || 0)
      .div(100)
      .toFixed(2),
  )
}

export const poundsToPennies = (amount: number) => {
  return Number(Big(Number(amount) || 0).times(100))
}

export const penniesToPounds = (amount: number) => {
  return Number(Big(Number(amount) || 0).div(100))
}

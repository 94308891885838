import React from 'react'
import type { CompanyDetails } from 'src/types'
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from '@material-ui/core'
import { Button } from '@gground/capcom.core'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { showSuccessToast } from 'src/utils/toasts'
import { getOneLineAddress } from 'src/utils/misc'

const useStyles = makeStyles({
  container: {
    padding: 0,
    paddingBottom: '0 !important',
  },
  table: {
    '& .MuiTableCell-root': {
      paddingTop: 0,
      paddingBottom: 0,
      height: 40,
      '&:first-child': {
        maxWidth: 100,
      },
    },
  },
})

interface Props {
  subsidiaryCompanies: CompanyDetails['subsidiary_companies']
}

const SubsidiaryCompaniesFragment = ({ subsidiaryCompanies = [] }: Props) => {
  const classes = useStyles()
  return (
    <Card>
      <CardHeader
        title="Subsidiary companies"
        action={
          <Box>
            <Button variant="text">Add a new subsidiary company</Button>
          </Box>
        }
      />
      <CardContent className={classes.container}>
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Company number</TableCell>
                <TableCell>Property address</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subsidiaryCompanies &&
                subsidiaryCompanies.map((company) => (
                  <TableRow key={company.id}>
                    <TableCell>
                      <CopyToClipboard
                        text={company.name}
                        onCopy={() => showSuccessToast('Copied')}
                      >
                        <Typography>{company.name}</Typography>
                      </CopyToClipboard>
                    </TableCell>
                    <TableCell>
                      <CopyToClipboard text={company.id} onCopy={() => showSuccessToast('Copied')}>
                        <Typography>{company.id}</Typography>
                      </CopyToClipboard>
                    </TableCell>
                    <TableCell>
                      <CopyToClipboard
                        text={getOneLineAddress(company.property_purchase_details.address) || ''}
                        onCopy={() => showSuccessToast('Copied')}
                      >
                        <Typography>
                          {getOneLineAddress(company.property_purchase_details.address)}
                        </Typography>
                      </CopyToClipboard>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

export default SubsidiaryCompaniesFragment

import React, { useEffect, useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { useOutletContext } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/store'
import { fetchCompanyDetails } from 'src/store/slices/company.slice'
import { showErrorToast } from 'src/utils/toasts'
import type { CompanyOutletContext } from '../types'
import CompanyDetailsSection from './CompanyDetailsSection'
import ShareholdersSection from './ShareholdersSection'
import PropertyPurchaseDetailsSection from './PropertyPurchaseDetailsSection'
import SolicitorDetailsSection from './SolicitorDetailsSection'
import SubsidiaryCompaniesSection from './SubsidiaryCompaniesSection'
import HoldcoDetailsSection from './HoldcoDetailsSection'
import LoadingSection from './LoadingSection'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    borderLeft: '1px solid #DEE0E3',
    borderRight: '1px solid #DEE0E3',
    backgroundColor: 'white',
    padding: theme.spacing(3),

    '& .MuiCardHeader-title': {
      fontSize: 16,
      fontWeight: 500,
      color: '#1C444E',
    },
    '& .MuiCardContent-root:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  shareholdersCard: {
    padding: 0,
  },
  name: {
    color: '#4E545F',
  },
  value: {
    fontWeight: 500,
  },
}))

const CompanyDetails = () => {
  const classes = useStyles()
  const { clientId, companyId } = useOutletContext<CompanyOutletContext>()
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const {
    company_details,
    property_purchase_details,
    shareholders,
    solicitor_details,
    parent_company_details,
    subsidiary_companies,
  } = useAppSelector((state) => state.company.details || {})

  useEffect(() => {
    if (clientId && companyId) {
      setIsLoading(true)
      dispatch(fetchCompanyDetails({ clientId, companyId }))
        .unwrap()
        .then(() => setIsLoading(false))
        .catch(() => showErrorToast('Failed to fetch company details'))
    }
  }, [clientId, companyId, dispatch])

  return (
    <Box className={classes.wrapper}>
      <CompanyDetailsSection companyDetails={company_details} isLoading={isLoading} />
      {isLoading && <LoadingSection />}
      {['HOLDCO', 'SPV'].includes(company_details?.company_type) && (
        <ShareholdersSection
          shareholders={shareholders}
          companyType={company_details?.company_type}
        />
      )}
      {company_details?.company_type === 'HOLDCO' && (
        <SubsidiaryCompaniesSection subsidiaryCompanies={subsidiary_companies} />
      )}
      {company_details?.company_type === 'SUBSIDIARY' && (
        <HoldcoDetailsSection parentCompanyDetails={parent_company_details} />
      )}
      {['SUBSIDIARY', 'SPV'].includes(company_details?.company_type) && (
        <>
          <PropertyPurchaseDetailsSection propertyPurchaseDetails={property_purchase_details} />
          <SolicitorDetailsSection solicitorDetails={solicitor_details} />
        </>
      )}
    </Box>
  )
}

export default CompanyDetails

interface Country {
  id: number
  num_code: number
  alpha_2_code: string
  alpha_3_code: string
  country_name: string
  nationality: string
  has_postcodes: boolean
  has_posttowns: boolean
}

export const countries: Record<Country['id'], Country> = {
  '1': {
    id: 1,
    num_code: 4,
    alpha_2_code: 'AF',
    alpha_3_code: 'AFG',
    country_name: 'Afghanistan',
    nationality: 'Afghan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '2': {
    id: 2,
    num_code: 248,
    alpha_2_code: 'AX',
    alpha_3_code: 'ALA',
    country_name: 'Åland Islands',
    nationality: 'Åland Island',
    has_postcodes: false,
    has_posttowns: true,
  },
  '3': {
    id: 3,
    num_code: 8,
    alpha_2_code: 'AL',
    alpha_3_code: 'ALB',
    country_name: 'Albania',
    nationality: 'Albanian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '4': {
    id: 4,
    num_code: 12,
    alpha_2_code: 'DZ',
    alpha_3_code: 'DZA',
    country_name: 'Algeria',
    nationality: 'Algerian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '5': {
    id: 5,
    num_code: 16,
    alpha_2_code: 'AS',
    alpha_3_code: 'ASM',
    country_name: 'American Samoa',
    nationality: 'American Samoan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '6': {
    id: 6,
    num_code: 20,
    alpha_2_code: 'AD',
    alpha_3_code: 'AND',
    country_name: 'Andorra',
    nationality: 'Andorran',
    has_postcodes: false,
    has_posttowns: true,
  },
  '7': {
    id: 7,
    num_code: 24,
    alpha_2_code: 'AO',
    alpha_3_code: 'AGO',
    country_name: 'Angola',
    nationality: 'Angolan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '8': {
    id: 8,
    num_code: 660,
    alpha_2_code: 'AI',
    alpha_3_code: 'AIA',
    country_name: 'Anguilla',
    nationality: 'Anguillan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '9': {
    id: 9,
    num_code: 10,
    alpha_2_code: 'AQ',
    alpha_3_code: 'ATA',
    country_name: 'Antarctica',
    nationality: 'Antarctic',
    has_postcodes: false,
    has_posttowns: true,
  },
  '10': {
    id: 10,
    num_code: 28,
    alpha_2_code: 'AG',
    alpha_3_code: 'ATG',
    country_name: 'Antigua and Barbuda',
    nationality: 'Antiguan or Barbudan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '11': {
    id: 11,
    num_code: 32,
    alpha_2_code: 'AR',
    alpha_3_code: 'ARG',
    country_name: 'Argentina',
    nationality: 'Argentine',
    has_postcodes: true,
    has_posttowns: true,
  },
  '12': {
    id: 12,
    num_code: 51,
    alpha_2_code: 'AM',
    alpha_3_code: 'ARM',
    country_name: 'Armenia',
    nationality: 'Armenian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '13': {
    id: 13,
    num_code: 533,
    alpha_2_code: 'AW',
    alpha_3_code: 'ABW',
    country_name: 'Aruba',
    nationality: 'Aruban',
    has_postcodes: false,
    has_posttowns: true,
  },
  '14': {
    id: 14,
    num_code: 36,
    alpha_2_code: 'AU',
    alpha_3_code: 'AUS',
    country_name: 'Australia',
    nationality: 'Australian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '15': {
    id: 15,
    num_code: 40,
    alpha_2_code: 'AT',
    alpha_3_code: 'AUT',
    country_name: 'Austria',
    nationality: 'Austrian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '16': {
    id: 16,
    num_code: 31,
    alpha_2_code: 'AZ',
    alpha_3_code: 'AZE',
    country_name: 'Azerbaijan',
    nationality: 'Azerbaijani, Azeri',
    has_postcodes: true,
    has_posttowns: true,
  },
  '17': {
    id: 17,
    num_code: 44,
    alpha_2_code: 'BS',
    alpha_3_code: 'BHS',
    country_name: 'Bahamas',
    nationality: 'Bahamian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '18': {
    id: 18,
    num_code: 48,
    alpha_2_code: 'BH',
    alpha_3_code: 'BHR',
    country_name: 'Bahrain',
    nationality: 'Bahraini',
    has_postcodes: false,
    has_posttowns: true,
  },
  '19': {
    id: 19,
    num_code: 50,
    alpha_2_code: 'BD',
    alpha_3_code: 'BGD',
    country_name: 'Bangladesh',
    nationality: 'Bangladeshi',
    has_postcodes: true,
    has_posttowns: true,
  },
  '20': {
    id: 20,
    num_code: 52,
    alpha_2_code: 'BB',
    alpha_3_code: 'BRB',
    country_name: 'Barbados',
    nationality: 'Barbadian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '21': {
    id: 21,
    num_code: 112,
    alpha_2_code: 'BY',
    alpha_3_code: 'BLR',
    country_name: 'Belarus',
    nationality: 'Belarusian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '22': {
    id: 22,
    num_code: 56,
    alpha_2_code: 'BE',
    alpha_3_code: 'BEL',
    country_name: 'Belgium',
    nationality: 'Belgian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '23': {
    id: 23,
    num_code: 84,
    alpha_2_code: 'BZ',
    alpha_3_code: 'BLZ',
    country_name: 'Belize',
    nationality: 'Belizean',
    has_postcodes: false,
    has_posttowns: true,
  },
  '24': {
    id: 24,
    num_code: 204,
    alpha_2_code: 'BJ',
    alpha_3_code: 'BEN',
    country_name: 'Benin',
    nationality: 'Beninese, Beninois',
    has_postcodes: false,
    has_posttowns: true,
  },
  '25': {
    id: 25,
    num_code: 60,
    alpha_2_code: 'BM',
    alpha_3_code: 'BMU',
    country_name: 'Bermuda',
    nationality: 'Bermudian, Bermudan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '26': {
    id: 26,
    num_code: 64,
    alpha_2_code: 'BT',
    alpha_3_code: 'BTN',
    country_name: 'Bhutan',
    nationality: 'Bhutanese',
    has_postcodes: false,
    has_posttowns: true,
  },
  '27': {
    id: 27,
    num_code: 68,
    alpha_2_code: 'BO',
    alpha_3_code: 'BOL',
    country_name: 'Bolivia (Plurinational State of)',
    nationality: 'Bolivian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '28': {
    id: 28,
    num_code: 535,
    alpha_2_code: 'BQ',
    alpha_3_code: 'BES',
    country_name: 'Bonaire, Sint Eustatius and Saba',
    nationality: 'Bonaire',
    has_postcodes: false,
    has_posttowns: true,
  },
  '29': {
    id: 29,
    num_code: 70,
    alpha_2_code: 'BA',
    alpha_3_code: 'BIH',
    country_name: 'Bosnia and Herzegovina',
    nationality: 'Bosnian or Herzegovinian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '30': {
    id: 30,
    num_code: 72,
    alpha_2_code: 'BW',
    alpha_3_code: 'BWA',
    country_name: 'Botswana',
    nationality: 'Motswana, Botswanan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '31': {
    id: 31,
    num_code: 74,
    alpha_2_code: 'BV',
    alpha_3_code: 'BVT',
    country_name: 'Bouvet Island',
    nationality: 'Bouvet Island',
    has_postcodes: true,
    has_posttowns: true,
  },
  '32': {
    id: 32,
    num_code: 76,
    alpha_2_code: 'BR',
    alpha_3_code: 'BRA',
    country_name: 'Brazil',
    nationality: 'Brazilian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '33': {
    id: 33,
    num_code: 86,
    alpha_2_code: 'IO',
    alpha_3_code: 'IOT',
    country_name: 'British Indian Ocean Territory',
    nationality: 'BIOT',
    has_postcodes: false,
    has_posttowns: true,
  },
  '34': {
    id: 34,
    num_code: 96,
    alpha_2_code: 'BN',
    alpha_3_code: 'BRN',
    country_name: 'Brunei Darussalam',
    nationality: 'Bruneian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '35': {
    id: 35,
    num_code: 100,
    alpha_2_code: 'BG',
    alpha_3_code: 'BGR',
    country_name: 'Bulgaria',
    nationality: 'Bulgarian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '36': {
    id: 36,
    num_code: 854,
    alpha_2_code: 'BF',
    alpha_3_code: 'BFA',
    country_name: 'Burkina Faso',
    nationality: 'Burkinabé',
    has_postcodes: false,
    has_posttowns: true,
  },
  '37': {
    id: 37,
    num_code: 108,
    alpha_2_code: 'BI',
    alpha_3_code: 'BDI',
    country_name: 'Burundi',
    nationality: 'Burundian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '38': {
    id: 38,
    num_code: 132,
    alpha_2_code: 'CV',
    alpha_3_code: 'CPV',
    country_name: 'Cabo Verde',
    nationality: 'Cabo Verdean',
    has_postcodes: false,
    has_posttowns: true,
  },
  '39': {
    id: 39,
    num_code: 116,
    alpha_2_code: 'KH',
    alpha_3_code: 'KHM',
    country_name: 'Cambodia',
    nationality: 'Cambodian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '40': {
    id: 40,
    num_code: 120,
    alpha_2_code: 'CM',
    alpha_3_code: 'CMR',
    country_name: 'Cameroon',
    nationality: 'Cameroonian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '41': {
    id: 41,
    num_code: 124,
    alpha_2_code: 'CA',
    alpha_3_code: 'CAN',
    country_name: 'Canada',
    nationality: 'Canadian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '42': {
    id: 42,
    num_code: 136,
    alpha_2_code: 'KY',
    alpha_3_code: 'CYM',
    country_name: 'Cayman Islands',
    nationality: 'Caymanian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '43': {
    id: 43,
    num_code: 140,
    alpha_2_code: 'CF',
    alpha_3_code: 'CAF',
    country_name: 'Central African Republic',
    nationality: 'Central African',
    has_postcodes: false,
    has_posttowns: true,
  },
  '44': {
    id: 44,
    num_code: 148,
    alpha_2_code: 'TD',
    alpha_3_code: 'TCD',
    country_name: 'Chad',
    nationality: 'Chadian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '45': {
    id: 45,
    num_code: 152,
    alpha_2_code: 'CL',
    alpha_3_code: 'CHL',
    country_name: 'Chile',
    nationality: 'Chilean',
    has_postcodes: false,
    has_posttowns: true,
  },
  '46': {
    id: 46,
    num_code: 156,
    alpha_2_code: 'CN',
    alpha_3_code: 'CHN',
    country_name: 'China',
    nationality: 'Chinese',
    has_postcodes: true,
    has_posttowns: true,
  },
  '47': {
    id: 47,
    num_code: 162,
    alpha_2_code: 'CX',
    alpha_3_code: 'CXR',
    country_name: 'Christmas Island',
    nationality: 'Christmas Island',
    has_postcodes: false,
    has_posttowns: true,
  },
  '48': {
    id: 48,
    num_code: 166,
    alpha_2_code: 'CC',
    alpha_3_code: 'CCK',
    country_name: 'Cocos (Keeling) Islands',
    nationality: 'Cocos Island',
    has_postcodes: false,
    has_posttowns: true,
  },
  '49': {
    id: 49,
    num_code: 170,
    alpha_2_code: 'CO',
    alpha_3_code: 'COL',
    country_name: 'Colombia',
    nationality: 'Colombian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '50': {
    id: 50,
    num_code: 174,
    alpha_2_code: 'KM',
    alpha_3_code: 'COM',
    country_name: 'Comoros',
    nationality: 'Comoran, Comorian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '51': {
    id: 51,
    num_code: 178,
    alpha_2_code: 'CG',
    alpha_3_code: 'COG',
    country_name: 'Congo (Republic of the)',
    nationality: 'Congolese',
    has_postcodes: false,
    has_posttowns: true,
  },
  '52': {
    id: 52,
    num_code: 180,
    alpha_2_code: 'CD',
    alpha_3_code: 'COD',
    country_name: 'Congo (Democratic Republic of the)',
    nationality: 'Congolese',
    has_postcodes: false,
    has_posttowns: true,
  },
  '53': {
    id: 53,
    num_code: 184,
    alpha_2_code: 'CK',
    alpha_3_code: 'COK',
    country_name: 'Cook Islands',
    nationality: 'Cook Island',
    has_postcodes: false,
    has_posttowns: true,
  },
  '54': {
    id: 54,
    num_code: 188,
    alpha_2_code: 'CR',
    alpha_3_code: 'CRI',
    country_name: 'Costa Rica',
    nationality: 'Costa Rican',
    has_postcodes: false,
    has_posttowns: true,
  },
  '55': {
    id: 55,
    num_code: 384,
    alpha_2_code: 'CI',
    alpha_3_code: 'CIV',
    country_name: "Côte d'Ivoire",
    nationality: 'Ivorian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '56': {
    id: 56,
    num_code: 191,
    alpha_2_code: 'HR',
    alpha_3_code: 'HRV',
    country_name: 'Croatia',
    nationality: 'Croatian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '57': {
    id: 57,
    num_code: 192,
    alpha_2_code: 'CU',
    alpha_3_code: 'CUB',
    country_name: 'Cuba',
    nationality: 'Cuban',
    has_postcodes: false,
    has_posttowns: true,
  },
  '58': {
    id: 58,
    num_code: 531,
    alpha_2_code: 'CW',
    alpha_3_code: 'CUW',
    country_name: 'Curaçao',
    nationality: 'Curaçaoan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '59': {
    id: 59,
    num_code: 196,
    alpha_2_code: 'CY',
    alpha_3_code: 'CYP',
    country_name: 'Cyprus',
    nationality: 'Cypriot',
    has_postcodes: true,
    has_posttowns: true,
  },
  '60': {
    id: 60,
    num_code: 203,
    alpha_2_code: 'CZ',
    alpha_3_code: 'CZE',
    country_name: 'Czech Republic',
    nationality: 'Czech',
    has_postcodes: true,
    has_posttowns: true,
  },
  '61': {
    id: 61,
    num_code: 208,
    alpha_2_code: 'DK',
    alpha_3_code: 'DNK',
    country_name: 'Denmark',
    nationality: 'Danish',
    has_postcodes: true,
    has_posttowns: true,
  },
  '62': {
    id: 62,
    num_code: 262,
    alpha_2_code: 'DJ',
    alpha_3_code: 'DJI',
    country_name: 'Djibouti',
    nationality: 'Djiboutian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '63': {
    id: 63,
    num_code: 212,
    alpha_2_code: 'DM',
    alpha_3_code: 'DMA',
    country_name: 'Dominica',
    nationality: 'Dominican',
    has_postcodes: false,
    has_posttowns: true,
  },
  '64': {
    id: 64,
    num_code: 214,
    alpha_2_code: 'DO',
    alpha_3_code: 'DOM',
    country_name: 'Dominican Republic',
    nationality: 'Dominican',
    has_postcodes: false,
    has_posttowns: true,
  },
  '65': {
    id: 65,
    num_code: 218,
    alpha_2_code: 'EC',
    alpha_3_code: 'ECU',
    country_name: 'Ecuador',
    nationality: 'Ecuadorian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '66': {
    id: 66,
    num_code: 818,
    alpha_2_code: 'EG',
    alpha_3_code: 'EGY',
    country_name: 'Egypt',
    nationality: 'Egyptian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '67': {
    id: 67,
    num_code: 222,
    alpha_2_code: 'SV',
    alpha_3_code: 'SLV',
    country_name: 'El Salvador',
    nationality: 'Salvadoran',
    has_postcodes: false,
    has_posttowns: true,
  },
  '68': {
    id: 68,
    num_code: 226,
    alpha_2_code: 'GQ',
    alpha_3_code: 'GNQ',
    country_name: 'Equatorial Guinea',
    nationality: 'Equatorial Guinean, Equatoguinean',
    has_postcodes: false,
    has_posttowns: true,
  },
  '69': {
    id: 69,
    num_code: 232,
    alpha_2_code: 'ER',
    alpha_3_code: 'ERI',
    country_name: 'Eritrea',
    nationality: 'Eritrean',
    has_postcodes: false,
    has_posttowns: true,
  },
  '70': {
    id: 70,
    num_code: 233,
    alpha_2_code: 'EE',
    alpha_3_code: 'EST',
    country_name: 'Estonia',
    nationality: 'Estonian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '71': {
    id: 71,
    num_code: 231,
    alpha_2_code: 'ET',
    alpha_3_code: 'ETH',
    country_name: 'Ethiopia',
    nationality: 'Ethiopian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '72': {
    id: 72,
    num_code: 238,
    alpha_2_code: 'FK',
    alpha_3_code: 'FLK',
    country_name: 'Falkland Islands (Malvinas)',
    nationality: 'Falkland Island',
    has_postcodes: false,
    has_posttowns: true,
  },
  '73': {
    id: 73,
    num_code: 234,
    alpha_2_code: 'FO',
    alpha_3_code: 'FRO',
    country_name: 'Faroe Islands',
    nationality: 'Faroese',
    has_postcodes: true,
    has_posttowns: true,
  },
  '74': {
    id: 74,
    num_code: 242,
    alpha_2_code: 'FJ',
    alpha_3_code: 'FJI',
    country_name: 'Fiji',
    nationality: 'Fijian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '75': {
    id: 75,
    num_code: 246,
    alpha_2_code: 'FI',
    alpha_3_code: 'FIN',
    country_name: 'Finland',
    nationality: 'Finnish',
    has_postcodes: true,
    has_posttowns: true,
  },
  '76': {
    id: 76,
    num_code: 250,
    alpha_2_code: 'FR',
    alpha_3_code: 'FRA',
    country_name: 'France',
    nationality: 'French',
    has_postcodes: true,
    has_posttowns: true,
  },
  '77': {
    id: 77,
    num_code: 254,
    alpha_2_code: 'GF',
    alpha_3_code: 'GUF',
    country_name: 'French Guiana',
    nationality: 'French Guianese',
    has_postcodes: false,
    has_posttowns: true,
  },
  '78': {
    id: 78,
    num_code: 258,
    alpha_2_code: 'PF',
    alpha_3_code: 'PYF',
    country_name: 'French Polynesia',
    nationality: 'French Polynesian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '79': {
    id: 79,
    num_code: 260,
    alpha_2_code: 'TF',
    alpha_3_code: 'ATF',
    country_name: 'French Southern Territories',
    nationality: 'French Southern Territories',
    has_postcodes: false,
    has_posttowns: true,
  },
  '80': {
    id: 80,
    num_code: 266,
    alpha_2_code: 'GA',
    alpha_3_code: 'GAB',
    country_name: 'Gabon',
    nationality: 'Gabonese',
    has_postcodes: false,
    has_posttowns: true,
  },
  '81': {
    id: 81,
    num_code: 270,
    alpha_2_code: 'GM',
    alpha_3_code: 'GMB',
    country_name: 'Gambia',
    nationality: 'Gambian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '82': {
    id: 82,
    num_code: 268,
    alpha_2_code: 'GE',
    alpha_3_code: 'GEO',
    country_name: 'Georgia',
    nationality: 'Georgian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '83': {
    id: 83,
    num_code: 276,
    alpha_2_code: 'DE',
    alpha_3_code: 'DEU',
    country_name: 'Germany',
    nationality: 'German',
    has_postcodes: true,
    has_posttowns: true,
  },
  '84': {
    id: 84,
    num_code: 288,
    alpha_2_code: 'GH',
    alpha_3_code: 'GHA',
    country_name: 'Ghana',
    nationality: 'Ghanaian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '85': {
    id: 85,
    num_code: 292,
    alpha_2_code: 'GI',
    alpha_3_code: 'GIB',
    country_name: 'Gibraltar',
    nationality: 'Gibraltar',
    has_postcodes: false,
    has_posttowns: true,
  },
  '86': {
    id: 86,
    num_code: 300,
    alpha_2_code: 'GR',
    alpha_3_code: 'GRC',
    country_name: 'Greece',
    nationality: 'Greek, Hellenic',
    has_postcodes: true,
    has_posttowns: true,
  },
  '87': {
    id: 87,
    num_code: 304,
    alpha_2_code: 'GL',
    alpha_3_code: 'GRL',
    country_name: 'Greenland',
    nationality: 'Greenlandic',
    has_postcodes: true,
    has_posttowns: true,
  },
  '88': {
    id: 88,
    num_code: 308,
    alpha_2_code: 'GD',
    alpha_3_code: 'GRD',
    country_name: 'Grenada',
    nationality: 'Grenadian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '89': {
    id: 89,
    num_code: 312,
    alpha_2_code: 'GP',
    alpha_3_code: 'GLP',
    country_name: 'Guadeloupe',
    nationality: 'Guadeloupe',
    has_postcodes: false,
    has_posttowns: true,
  },
  '90': {
    id: 90,
    num_code: 316,
    alpha_2_code: 'GU',
    alpha_3_code: 'GUM',
    country_name: 'Guam',
    nationality: 'Guamanian, Guambat',
    has_postcodes: true,
    has_posttowns: true,
  },
  '91': {
    id: 91,
    num_code: 320,
    alpha_2_code: 'GT',
    alpha_3_code: 'GTM',
    country_name: 'Guatemala',
    nationality: 'Guatemalan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '92': {
    id: 92,
    num_code: 831,
    alpha_2_code: 'GG',
    alpha_3_code: 'GGY',
    country_name: 'Guernsey',
    nationality: 'Channel Island',
    has_postcodes: true,
    has_posttowns: true,
  },
  '93': {
    id: 93,
    num_code: 324,
    alpha_2_code: 'GN',
    alpha_3_code: 'GIN',
    country_name: 'Guinea',
    nationality: 'Guinean',
    has_postcodes: false,
    has_posttowns: true,
  },
  '94': {
    id: 94,
    num_code: 624,
    alpha_2_code: 'GW',
    alpha_3_code: 'GNB',
    country_name: 'Guinea-Bissau',
    nationality: 'Bissau-Guinean',
    has_postcodes: false,
    has_posttowns: true,
  },
  '95': {
    id: 95,
    num_code: 328,
    alpha_2_code: 'GY',
    alpha_3_code: 'GUY',
    country_name: 'Guyana',
    nationality: 'Guyanese',
    has_postcodes: false,
    has_posttowns: true,
  },
  '96': {
    id: 96,
    num_code: 332,
    alpha_2_code: 'HT',
    alpha_3_code: 'HTI',
    country_name: 'Haiti',
    nationality: 'Haitian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '97': {
    id: 97,
    num_code: 334,
    alpha_2_code: 'HM',
    alpha_3_code: 'HMD',
    country_name: 'Heard Island and McDonald Islands',
    nationality: 'Heard Island or McDonald Islands',
    has_postcodes: false,
    has_posttowns: true,
  },
  '98': {
    id: 98,
    num_code: 336,
    alpha_2_code: 'VA',
    alpha_3_code: 'VAT',
    country_name: 'Vatican City State',
    nationality: 'Vatican',
    has_postcodes: true,
    has_posttowns: false,
  },
  '99': {
    id: 99,
    num_code: 340,
    alpha_2_code: 'HN',
    alpha_3_code: 'HND',
    country_name: 'Honduras',
    nationality: 'Honduran',
    has_postcodes: false,
    has_posttowns: true,
  },
  '100': {
    id: 100,
    num_code: 344,
    alpha_2_code: 'HK',
    alpha_3_code: 'HKG',
    country_name: 'Hong Kong',
    nationality: 'Hong Kong, Hong Kongese',
    has_postcodes: false,
    has_posttowns: false,
  },
  '101': {
    id: 101,
    num_code: 348,
    alpha_2_code: 'HU',
    alpha_3_code: 'HUN',
    country_name: 'Hungary',
    nationality: 'Hungarian, Magyar',
    has_postcodes: true,
    has_posttowns: true,
  },
  '102': {
    id: 102,
    num_code: 352,
    alpha_2_code: 'IS',
    alpha_3_code: 'ISL',
    country_name: 'Iceland',
    nationality: 'Icelandic',
    has_postcodes: false,
    has_posttowns: true,
  },
  '103': {
    id: 103,
    num_code: 356,
    alpha_2_code: 'IN',
    alpha_3_code: 'IND',
    country_name: 'India',
    nationality: 'Indian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '104': {
    id: 104,
    num_code: 360,
    alpha_2_code: 'ID',
    alpha_3_code: 'IDN',
    country_name: 'Indonesia',
    nationality: 'Indonesian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '105': {
    id: 105,
    num_code: 364,
    alpha_2_code: 'IR',
    alpha_3_code: 'IRN',
    country_name: 'Iran',
    nationality: 'Iranian, Persian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '106': {
    id: 106,
    num_code: 368,
    alpha_2_code: 'IQ',
    alpha_3_code: 'IRQ',
    country_name: 'Iraq',
    nationality: 'Iraqi',
    has_postcodes: false,
    has_posttowns: true,
  },
  '107': {
    id: 107,
    num_code: 372,
    alpha_2_code: 'IE',
    alpha_3_code: 'IRL',
    country_name: 'Ireland',
    nationality: 'Irish',
    has_postcodes: false,
    has_posttowns: true,
  },
  '108': {
    id: 108,
    num_code: 833,
    alpha_2_code: 'IM',
    alpha_3_code: 'IMN',
    country_name: 'Isle of Man',
    nationality: 'Manx',
    has_postcodes: false,
    has_posttowns: true,
  },
  '109': {
    id: 109,
    num_code: 376,
    alpha_2_code: 'IL',
    alpha_3_code: 'ISR',
    country_name: 'Israel',
    nationality: 'Israeli',
    has_postcodes: true,
    has_posttowns: true,
  },
  '110': {
    id: 110,
    num_code: 380,
    alpha_2_code: 'IT',
    alpha_3_code: 'ITA',
    country_name: 'Italy',
    nationality: 'Italian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '111': {
    id: 111,
    num_code: 388,
    alpha_2_code: 'JM',
    alpha_3_code: 'JAM',
    country_name: 'Jamaica',
    nationality: 'Jamaican',
    has_postcodes: false,
    has_posttowns: true,
  },
  '112': {
    id: 112,
    num_code: 392,
    alpha_2_code: 'JP',
    alpha_3_code: 'JPN',
    country_name: 'Japan',
    nationality: 'Japanese',
    has_postcodes: true,
    has_posttowns: true,
  },
  '113': {
    id: 113,
    num_code: 832,
    alpha_2_code: 'JE',
    alpha_3_code: 'JEY',
    country_name: 'Jersey',
    nationality: 'Channel Island',
    has_postcodes: true,
    has_posttowns: true,
  },
  '114': {
    id: 114,
    num_code: 400,
    alpha_2_code: 'JO',
    alpha_3_code: 'JOR',
    country_name: 'Jordan',
    nationality: 'Jordanian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '115': {
    id: 115,
    num_code: 398,
    alpha_2_code: 'KZ',
    alpha_3_code: 'KAZ',
    country_name: 'Kazakhstan',
    nationality: 'Kazakhstani, Kazakh',
    has_postcodes: true,
    has_posttowns: true,
  },
  '116': {
    id: 116,
    num_code: 404,
    alpha_2_code: 'KE',
    alpha_3_code: 'KEN',
    country_name: 'Kenya',
    nationality: 'Kenyan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '117': {
    id: 117,
    num_code: 296,
    alpha_2_code: 'KI',
    alpha_3_code: 'KIR',
    country_name: 'Kiribati',
    nationality: 'I-Kiribati',
    has_postcodes: false,
    has_posttowns: true,
  },
  '118': {
    id: 118,
    num_code: 408,
    alpha_2_code: 'KP',
    alpha_3_code: 'PRK',
    country_name: "Korea (Democratic People's Republic of)",
    nationality: 'North Korean',
    has_postcodes: false,
    has_posttowns: true,
  },
  '119': {
    id: 119,
    num_code: 410,
    alpha_2_code: 'KR',
    alpha_3_code: 'KOR',
    country_name: 'Korea (Republic of)',
    nationality: 'South Korean',
    has_postcodes: true,
    has_posttowns: true,
  },
  '120': {
    id: 120,
    num_code: 414,
    alpha_2_code: 'KW',
    alpha_3_code: 'KWT',
    country_name: 'Kuwait',
    nationality: 'Kuwaiti',
    has_postcodes: false,
    has_posttowns: true,
  },
  '121': {
    id: 121,
    num_code: 417,
    alpha_2_code: 'KG',
    alpha_3_code: 'KGZ',
    country_name: 'Kyrgyzstan',
    nationality: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz',
    has_postcodes: true,
    has_posttowns: true,
  },
  '122': {
    id: 122,
    num_code: 418,
    alpha_2_code: 'LA',
    alpha_3_code: 'LAO',
    country_name: "Lao People's Democratic Republic",
    nationality: 'Lao, Laotian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '123': {
    id: 123,
    num_code: 428,
    alpha_2_code: 'LV',
    alpha_3_code: 'LVA',
    country_name: 'Latvia',
    nationality: 'Latvian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '124': {
    id: 124,
    num_code: 422,
    alpha_2_code: 'LB',
    alpha_3_code: 'LBN',
    country_name: 'Lebanon',
    nationality: 'Lebanese',
    has_postcodes: false,
    has_posttowns: true,
  },
  '125': {
    id: 125,
    num_code: 426,
    alpha_2_code: 'LS',
    alpha_3_code: 'LSO',
    country_name: 'Lesotho',
    nationality: 'Basotho',
    has_postcodes: false,
    has_posttowns: true,
  },
  '126': {
    id: 126,
    num_code: 430,
    alpha_2_code: 'LR',
    alpha_3_code: 'LBR',
    country_name: 'Liberia',
    nationality: 'Liberian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '127': {
    id: 127,
    num_code: 434,
    alpha_2_code: 'LY',
    alpha_3_code: 'LBY',
    country_name: 'Libya',
    nationality: 'Libyan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '128': {
    id: 128,
    num_code: 438,
    alpha_2_code: 'LI',
    alpha_3_code: 'LIE',
    country_name: 'Liechtenstein',
    nationality: 'Liechtenstein',
    has_postcodes: true,
    has_posttowns: true,
  },
  '129': {
    id: 129,
    num_code: 440,
    alpha_2_code: 'LT',
    alpha_3_code: 'LTU',
    country_name: 'Lithuania',
    nationality: 'Lithuanian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '130': {
    id: 130,
    num_code: 442,
    alpha_2_code: 'LU',
    alpha_3_code: 'LUX',
    country_name: 'Luxembourg',
    nationality: 'Luxembourg, Luxembourgish',
    has_postcodes: true,
    has_posttowns: true,
  },
  '131': {
    id: 131,
    num_code: 446,
    alpha_2_code: 'MO',
    alpha_3_code: 'MAC',
    country_name: 'Macao',
    nationality: 'Macanese, Chinese',
    has_postcodes: false,
    has_posttowns: false,
  },
  '132': {
    id: 132,
    num_code: 807,
    alpha_2_code: 'MK',
    alpha_3_code: 'MKD',
    country_name: 'Macedonia (the former Yugoslav Republic of)',
    nationality: 'Macedonian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '133': {
    id: 133,
    num_code: 450,
    alpha_2_code: 'MG',
    alpha_3_code: 'MDG',
    country_name: 'Madagascar',
    nationality: 'Malagasy',
    has_postcodes: true,
    has_posttowns: true,
  },
  '134': {
    id: 134,
    num_code: 454,
    alpha_2_code: 'MW',
    alpha_3_code: 'MWI',
    country_name: 'Malawi',
    nationality: 'Malawian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '135': {
    id: 135,
    num_code: 458,
    alpha_2_code: 'MY',
    alpha_3_code: 'MYS',
    country_name: 'Malaysia',
    nationality: 'Malaysian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '136': {
    id: 136,
    num_code: 462,
    alpha_2_code: 'MV',
    alpha_3_code: 'MDV',
    country_name: 'Maldives',
    nationality: 'Maldivian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '137': {
    id: 137,
    num_code: 466,
    alpha_2_code: 'ML',
    alpha_3_code: 'MLI',
    country_name: 'Mali',
    nationality: 'Malian, Malinese',
    has_postcodes: false,
    has_posttowns: true,
  },
  '138': {
    id: 138,
    num_code: 470,
    alpha_2_code: 'MT',
    alpha_3_code: 'MLT',
    country_name: 'Malta',
    nationality: 'Maltese',
    has_postcodes: false,
    has_posttowns: true,
  },
  '139': {
    id: 139,
    num_code: 584,
    alpha_2_code: 'MH',
    alpha_3_code: 'MHL',
    country_name: 'Marshall Islands',
    nationality: 'Marshallese',
    has_postcodes: true,
    has_posttowns: true,
  },
  '140': {
    id: 140,
    num_code: 474,
    alpha_2_code: 'MQ',
    alpha_3_code: 'MTQ',
    country_name: 'Martinique',
    nationality: 'Martiniquais, Martinican',
    has_postcodes: true,
    has_posttowns: true,
  },
  '141': {
    id: 141,
    num_code: 478,
    alpha_2_code: 'MR',
    alpha_3_code: 'MRT',
    country_name: 'Mauritania',
    nationality: 'Mauritanian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '142': {
    id: 142,
    num_code: 480,
    alpha_2_code: 'MU',
    alpha_3_code: 'MUS',
    country_name: 'Mauritius',
    nationality: 'Mauritian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '143': {
    id: 143,
    num_code: 175,
    alpha_2_code: 'YT',
    alpha_3_code: 'MYT',
    country_name: 'Mayotte',
    nationality: 'Mahoran',
    has_postcodes: true,
    has_posttowns: true,
  },
  '144': {
    id: 144,
    num_code: 484,
    alpha_2_code: 'MX',
    alpha_3_code: 'MEX',
    country_name: 'Mexico',
    nationality: 'Mexican',
    has_postcodes: true,
    has_posttowns: true,
  },
  '145': {
    id: 145,
    num_code: 583,
    alpha_2_code: 'FM',
    alpha_3_code: 'FSM',
    country_name: 'Micronesia (Federated States of)',
    nationality: 'Micronesian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '146': {
    id: 146,
    num_code: 498,
    alpha_2_code: 'MD',
    alpha_3_code: 'MDA',
    country_name: 'Moldova (Republic of)',
    nationality: 'Moldovan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '147': {
    id: 147,
    num_code: 492,
    alpha_2_code: 'MC',
    alpha_3_code: 'MCO',
    country_name: 'Monaco',
    nationality: 'Monégasque, Monacan',
    has_postcodes: false,
    has_posttowns: false,
  },
  '148': {
    id: 148,
    num_code: 496,
    alpha_2_code: 'MN',
    alpha_3_code: 'MNG',
    country_name: 'Mongolia',
    nationality: 'Mongolian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '149': {
    id: 149,
    num_code: 499,
    alpha_2_code: 'ME',
    alpha_3_code: 'MNE',
    country_name: 'Montenegro',
    nationality: 'Montenegrin',
    has_postcodes: true,
    has_posttowns: true,
  },
  '150': {
    id: 150,
    num_code: 500,
    alpha_2_code: 'MS',
    alpha_3_code: 'MSR',
    country_name: 'Montserrat',
    nationality: 'Montserratian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '151': {
    id: 151,
    num_code: 504,
    alpha_2_code: 'MA',
    alpha_3_code: 'MAR',
    country_name: 'Morocco',
    nationality: 'Moroccan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '152': {
    id: 152,
    num_code: 508,
    alpha_2_code: 'MZ',
    alpha_3_code: 'MOZ',
    country_name: 'Mozambique',
    nationality: 'Mozambican',
    has_postcodes: false,
    has_posttowns: true,
  },
  '153': {
    id: 153,
    num_code: 104,
    alpha_2_code: 'MM',
    alpha_3_code: 'MMR',
    country_name: 'Myanmar',
    nationality: 'Burmese',
    has_postcodes: false,
    has_posttowns: true,
  },
  '154': {
    id: 154,
    num_code: 516,
    alpha_2_code: 'NA',
    alpha_3_code: 'NAM',
    country_name: 'Namibia',
    nationality: 'Namibian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '155': {
    id: 155,
    num_code: 520,
    alpha_2_code: 'NR',
    alpha_3_code: 'NRU',
    country_name: 'Nauru',
    nationality: 'Nauruan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '156': {
    id: 156,
    num_code: 524,
    alpha_2_code: 'NP',
    alpha_3_code: 'NPL',
    country_name: 'Nepal',
    nationality: 'Nepali, Nepalese',
    has_postcodes: false,
    has_posttowns: true,
  },
  '157': {
    id: 157,
    num_code: 528,
    alpha_2_code: 'NL',
    alpha_3_code: 'NLD',
    country_name: 'Netherlands',
    nationality: 'Dutch, Netherlandic',
    has_postcodes: true,
    has_posttowns: true,
  },
  '158': {
    id: 158,
    num_code: 540,
    alpha_2_code: 'NC',
    alpha_3_code: 'NCL',
    country_name: 'New Caledonia',
    nationality: 'New Caledonian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '159': {
    id: 159,
    num_code: 554,
    alpha_2_code: 'NZ',
    alpha_3_code: 'NZL',
    country_name: 'New Zealand',
    nationality: 'New Zealand, NZ',
    has_postcodes: true,
    has_posttowns: true,
  },
  '160': {
    id: 160,
    num_code: 558,
    alpha_2_code: 'NI',
    alpha_3_code: 'NIC',
    country_name: 'Nicaragua',
    nationality: 'Nicaraguan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '161': {
    id: 161,
    num_code: 562,
    alpha_2_code: 'NE',
    alpha_3_code: 'NER',
    country_name: 'Niger',
    nationality: 'Nigerien',
    has_postcodes: false,
    has_posttowns: true,
  },
  '162': {
    id: 162,
    num_code: 566,
    alpha_2_code: 'NG',
    alpha_3_code: 'NGA',
    country_name: 'Nigeria',
    nationality: 'Nigerian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '163': {
    id: 163,
    num_code: 570,
    alpha_2_code: 'NU',
    alpha_3_code: 'NIU',
    country_name: 'Niue',
    nationality: 'Niuean',
    has_postcodes: false,
    has_posttowns: true,
  },
  '164': {
    id: 164,
    num_code: 574,
    alpha_2_code: 'NF',
    alpha_3_code: 'NFK',
    country_name: 'Norfolk Island',
    nationality: 'Norfolk Island',
    has_postcodes: false,
    has_posttowns: true,
  },
  '165': {
    id: 165,
    num_code: 580,
    alpha_2_code: 'MP',
    alpha_3_code: 'MNP',
    country_name: 'Northern Mariana Islands',
    nationality: 'Northern Marianan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '166': {
    id: 166,
    num_code: 578,
    alpha_2_code: 'NO',
    alpha_3_code: 'NOR',
    country_name: 'Norway',
    nationality: 'Norwegian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '167': {
    id: 167,
    num_code: 512,
    alpha_2_code: 'OM',
    alpha_3_code: 'OMN',
    country_name: 'Oman',
    nationality: 'Omani',
    has_postcodes: false,
    has_posttowns: true,
  },
  '168': {
    id: 168,
    num_code: 586,
    alpha_2_code: 'PK',
    alpha_3_code: 'PAK',
    country_name: 'Pakistan',
    nationality: 'Pakistani',
    has_postcodes: true,
    has_posttowns: true,
  },
  '169': {
    id: 169,
    num_code: 585,
    alpha_2_code: 'PW',
    alpha_3_code: 'PLW',
    country_name: 'Palau',
    nationality: 'Palauan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '170': {
    id: 170,
    num_code: 275,
    alpha_2_code: 'PS',
    alpha_3_code: 'PSE',
    country_name: 'Palestine, State of',
    nationality: 'Palestinian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '171': {
    id: 171,
    num_code: 591,
    alpha_2_code: 'PA',
    alpha_3_code: 'PAN',
    country_name: 'Panama',
    nationality: 'Panamanian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '172': {
    id: 172,
    num_code: 598,
    alpha_2_code: 'PG',
    alpha_3_code: 'PNG',
    country_name: 'Papua New Guinea',
    nationality: 'Papua New Guinean, Papuan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '173': {
    id: 173,
    num_code: 600,
    alpha_2_code: 'PY',
    alpha_3_code: 'PRY',
    country_name: 'Paraguay',
    nationality: 'Paraguayan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '174': {
    id: 174,
    num_code: 604,
    alpha_2_code: 'PE',
    alpha_3_code: 'PER',
    country_name: 'Peru',
    nationality: 'Peruvian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '175': {
    id: 175,
    num_code: 608,
    alpha_2_code: 'PH',
    alpha_3_code: 'PHL',
    country_name: 'Philippines',
    nationality: 'Philippine, Filipino',
    has_postcodes: true,
    has_posttowns: true,
  },
  '176': {
    id: 176,
    num_code: 612,
    alpha_2_code: 'PN',
    alpha_3_code: 'PCN',
    country_name: 'Pitcairn',
    nationality: 'Pitcairn Island',
    has_postcodes: false,
    has_posttowns: true,
  },
  '177': {
    id: 177,
    num_code: 616,
    alpha_2_code: 'PL',
    alpha_3_code: 'POL',
    country_name: 'Poland',
    nationality: 'Polish',
    has_postcodes: true,
    has_posttowns: true,
  },
  '178': {
    id: 178,
    num_code: 620,
    alpha_2_code: 'PT',
    alpha_3_code: 'PRT',
    country_name: 'Portugal',
    nationality: 'Portuguese',
    has_postcodes: true,
    has_posttowns: true,
  },
  '179': {
    id: 179,
    num_code: 630,
    alpha_2_code: 'PR',
    alpha_3_code: 'PRI',
    country_name: 'Puerto Rico',
    nationality: 'Puerto Rican',
    has_postcodes: true,
    has_posttowns: true,
  },
  '180': {
    id: 180,
    num_code: 634,
    alpha_2_code: 'QA',
    alpha_3_code: 'QAT',
    country_name: 'Qatar',
    nationality: 'Qatari',
    has_postcodes: false,
    has_posttowns: true,
  },
  '181': {
    id: 181,
    num_code: 638,
    alpha_2_code: 'RE',
    alpha_3_code: 'REU',
    country_name: 'Réunion',
    nationality: 'Réunionese, Réunionnais',
    has_postcodes: true,
    has_posttowns: true,
  },
  '182': {
    id: 182,
    num_code: 642,
    alpha_2_code: 'RO',
    alpha_3_code: 'ROU',
    country_name: 'Romania',
    nationality: 'Romanian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '183': {
    id: 183,
    num_code: 643,
    alpha_2_code: 'RU',
    alpha_3_code: 'RUS',
    country_name: 'Russian Federation',
    nationality: 'Russian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '184': {
    id: 184,
    num_code: 646,
    alpha_2_code: 'RW',
    alpha_3_code: 'RWA',
    country_name: 'Rwanda',
    nationality: 'Rwandan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '185': {
    id: 185,
    num_code: 652,
    alpha_2_code: 'BL',
    alpha_3_code: 'BLM',
    country_name: 'Saint Barthélemy',
    nationality: 'Barthélemois',
    has_postcodes: false,
    has_posttowns: true,
  },
  '186': {
    id: 186,
    num_code: 654,
    alpha_2_code: 'SH',
    alpha_3_code: 'SHN',
    country_name: 'Saint Helena, Ascension and Tristan da Cunha',
    nationality: 'Saint Helenian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '187': {
    id: 187,
    num_code: 659,
    alpha_2_code: 'KN',
    alpha_3_code: 'KNA',
    country_name: 'Saint Kitts and Nevis',
    nationality: 'Kittitian or Nevisian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '188': {
    id: 188,
    num_code: 662,
    alpha_2_code: 'LC',
    alpha_3_code: 'LCA',
    country_name: 'Saint Lucia',
    nationality: 'Saint Lucian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '189': {
    id: 189,
    num_code: 663,
    alpha_2_code: 'MF',
    alpha_3_code: 'MAF',
    country_name: 'Saint Martin (French part)',
    nationality: 'Saint-Martinoise',
    has_postcodes: false,
    has_posttowns: true,
  },
  '190': {
    id: 190,
    num_code: 666,
    alpha_2_code: 'PM',
    alpha_3_code: 'SPM',
    country_name: 'Saint Pierre and Miquelon',
    nationality: 'Saint-Pierrais or Miquelonnais',
    has_postcodes: false,
    has_posttowns: true,
  },
  '191': {
    id: 191,
    num_code: 670,
    alpha_2_code: 'VC',
    alpha_3_code: 'VCT',
    country_name: 'Saint Vincent and the Grenadines',
    nationality: 'Saint Vincentian, Vincentian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '192': {
    id: 192,
    num_code: 882,
    alpha_2_code: 'WS',
    alpha_3_code: 'WSM',
    country_name: 'Samoa',
    nationality: 'Samoan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '193': {
    id: 193,
    num_code: 674,
    alpha_2_code: 'SM',
    alpha_3_code: 'SMR',
    country_name: 'San Marino',
    nationality: 'Sammarinese',
    has_postcodes: false,
    has_posttowns: true,
  },
  '194': {
    id: 194,
    num_code: 678,
    alpha_2_code: 'ST',
    alpha_3_code: 'STP',
    country_name: 'Sao Tome and Principe',
    nationality: 'São Toméan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '195': {
    id: 195,
    num_code: 682,
    alpha_2_code: 'SA',
    alpha_3_code: 'SAU',
    country_name: 'Saudi Arabia',
    nationality: 'Saudi, Saudi Arabian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '196': {
    id: 196,
    num_code: 686,
    alpha_2_code: 'SN',
    alpha_3_code: 'SEN',
    country_name: 'Senegal',
    nationality: 'Senegalese',
    has_postcodes: false,
    has_posttowns: true,
  },
  '197': {
    id: 197,
    num_code: 688,
    alpha_2_code: 'RS',
    alpha_3_code: 'SRB',
    country_name: 'Serbia',
    nationality: 'Serbian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '198': {
    id: 198,
    num_code: 690,
    alpha_2_code: 'SC',
    alpha_3_code: 'SYC',
    country_name: 'Seychelles',
    nationality: 'Seychellois',
    has_postcodes: false,
    has_posttowns: true,
  },
  '199': {
    id: 199,
    num_code: 694,
    alpha_2_code: 'SL',
    alpha_3_code: 'SLE',
    country_name: 'Sierra Leone',
    nationality: 'Sierra Leonean',
    has_postcodes: false,
    has_posttowns: true,
  },
  '200': {
    id: 200,
    num_code: 702,
    alpha_2_code: 'SG',
    alpha_3_code: 'SGP',
    country_name: 'Singapore',
    nationality: 'Singaporean',
    has_postcodes: true,
    has_posttowns: false,
  },
  '201': {
    id: 201,
    num_code: 534,
    alpha_2_code: 'SX',
    alpha_3_code: 'SXM',
    country_name: 'Sint Maarten (Dutch part)',
    nationality: 'Sint Maarten',
    has_postcodes: false,
    has_posttowns: true,
  },
  '202': {
    id: 202,
    num_code: 703,
    alpha_2_code: 'SK',
    alpha_3_code: 'SVK',
    country_name: 'Slovakia',
    nationality: 'Slovak',
    has_postcodes: false,
    has_posttowns: true,
  },
  '203': {
    id: 203,
    num_code: 705,
    alpha_2_code: 'SI',
    alpha_3_code: 'SVN',
    country_name: 'Slovenia',
    nationality: 'Slovenian, Slovene',
    has_postcodes: true,
    has_posttowns: true,
  },
  '204': {
    id: 204,
    num_code: 90,
    alpha_2_code: 'SB',
    alpha_3_code: 'SLB',
    country_name: 'Solomon Islands',
    nationality: 'Solomon Island',
    has_postcodes: false,
    has_posttowns: true,
  },
  '205': {
    id: 205,
    num_code: 706,
    alpha_2_code: 'SO',
    alpha_3_code: 'SOM',
    country_name: 'Somalia',
    nationality: 'Somali, Somalian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '206': {
    id: 206,
    num_code: 710,
    alpha_2_code: 'ZA',
    alpha_3_code: 'ZAF',
    country_name: 'South Africa',
    nationality: 'South African',
    has_postcodes: true,
    has_posttowns: true,
  },
  '207': {
    id: 207,
    num_code: 239,
    alpha_2_code: 'GS',
    alpha_3_code: 'SGS',
    country_name: 'South Georgia and the South Sandwich Islands',
    nationality: 'South Georgia or South Sandwich Islands',
    has_postcodes: false,
    has_posttowns: true,
  },
  '208': {
    id: 208,
    num_code: 728,
    alpha_2_code: 'SS',
    alpha_3_code: 'SSD',
    country_name: 'South Sudan',
    nationality: 'South Sudanese',
    has_postcodes: false,
    has_posttowns: true,
  },
  '209': {
    id: 209,
    num_code: 724,
    alpha_2_code: 'ES',
    alpha_3_code: 'ESP',
    country_name: 'Spain',
    nationality: 'Spanish',
    has_postcodes: true,
    has_posttowns: true,
  },
  '210': {
    id: 210,
    num_code: 144,
    alpha_2_code: 'LK',
    alpha_3_code: 'LKA',
    country_name: 'Sri Lanka',
    nationality: 'Sri Lankan',
    has_postcodes: true,
    has_posttowns: true,
  },
  '211': {
    id: 211,
    num_code: 729,
    alpha_2_code: 'SD',
    alpha_3_code: 'SDN',
    country_name: 'Sudan',
    nationality: 'Sudanese',
    has_postcodes: false,
    has_posttowns: true,
  },
  '212': {
    id: 212,
    num_code: 740,
    alpha_2_code: 'SR',
    alpha_3_code: 'SUR',
    country_name: 'Suriname',
    nationality: 'Surinamese',
    has_postcodes: false,
    has_posttowns: true,
  },
  '213': {
    id: 213,
    num_code: 744,
    alpha_2_code: 'SJ',
    alpha_3_code: 'SJM',
    country_name: 'Svalbard and Jan Mayen',
    nationality: 'Svalbard',
    has_postcodes: false,
    has_posttowns: true,
  },
  '214': {
    id: 214,
    num_code: 748,
    alpha_2_code: 'SZ',
    alpha_3_code: 'SWZ',
    country_name: 'Swaziland',
    nationality: 'Swazi',
    has_postcodes: false,
    has_posttowns: true,
  },
  '215': {
    id: 215,
    num_code: 752,
    alpha_2_code: 'SE',
    alpha_3_code: 'SWE',
    country_name: 'Sweden',
    nationality: 'Swedish',
    has_postcodes: true,
    has_posttowns: true,
  },
  '216': {
    id: 216,
    num_code: 756,
    alpha_2_code: 'CH',
    alpha_3_code: 'CHE',
    country_name: 'Switzerland',
    nationality: 'Swiss',
    has_postcodes: true,
    has_posttowns: true,
  },
  '217': {
    id: 217,
    num_code: 760,
    alpha_2_code: 'SY',
    alpha_3_code: 'SYR',
    country_name: 'Syrian Arab Republic',
    nationality: 'Syrian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '218': {
    id: 218,
    num_code: 158,
    alpha_2_code: 'TW',
    alpha_3_code: 'TWN',
    country_name: 'Taiwan, Province of China',
    nationality: 'Chinese, Taiwanese',
    has_postcodes: true,
    has_posttowns: true,
  },
  '219': {
    id: 219,
    num_code: 762,
    alpha_2_code: 'TJ',
    alpha_3_code: 'TJK',
    country_name: 'Tajikistan',
    nationality: 'Tajikistani',
    has_postcodes: true,
    has_posttowns: true,
  },
  '220': {
    id: 220,
    num_code: 834,
    alpha_2_code: 'TZ',
    alpha_3_code: 'TZA',
    country_name: 'Tanzania, United Republic of',
    nationality: 'Tanzanian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '221': {
    id: 221,
    num_code: 764,
    alpha_2_code: 'TH',
    alpha_3_code: 'THA',
    country_name: 'Thailand',
    nationality: 'Thai',
    has_postcodes: true,
    has_posttowns: true,
  },
  '222': {
    id: 222,
    num_code: 626,
    alpha_2_code: 'TL',
    alpha_3_code: 'TLS',
    country_name: 'East Timor',
    nationality: 'East Timorese',
    has_postcodes: false,
    has_posttowns: true,
  },
  '223': {
    id: 223,
    num_code: 768,
    alpha_2_code: 'TG',
    alpha_3_code: 'TGO',
    country_name: 'Togo',
    nationality: 'Togolese',
    has_postcodes: false,
    has_posttowns: true,
  },
  '224': {
    id: 224,
    num_code: 772,
    alpha_2_code: 'TK',
    alpha_3_code: 'TKL',
    country_name: 'Tokelau',
    nationality: 'Tokelauan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '225': {
    id: 225,
    num_code: 776,
    alpha_2_code: 'TO',
    alpha_3_code: 'TON',
    country_name: 'Tonga',
    nationality: 'Tongan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '226': {
    id: 226,
    num_code: 780,
    alpha_2_code: 'TT',
    alpha_3_code: 'TTO',
    country_name: 'Trinidad and Tobago',
    nationality: 'Trinidadian or Tobagonian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '227': {
    id: 227,
    num_code: 788,
    alpha_2_code: 'TN',
    alpha_3_code: 'TUN',
    country_name: 'Tunisia',
    nationality: 'Tunisian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '228': {
    id: 228,
    num_code: 792,
    alpha_2_code: 'TR',
    alpha_3_code: 'TUR',
    country_name: 'Turkey',
    nationality: 'Turkish',
    has_postcodes: true,
    has_posttowns: true,
  },
  '229': {
    id: 229,
    num_code: 795,
    alpha_2_code: 'TM',
    alpha_3_code: 'TKM',
    country_name: 'Turkmenistan',
    nationality: 'Turkmen',
    has_postcodes: true,
    has_posttowns: true,
  },
  '230': {
    id: 230,
    num_code: 796,
    alpha_2_code: 'TC',
    alpha_3_code: 'TCA',
    country_name: 'Turks and Caicos Islands',
    nationality: 'Turks and Caicos Island',
    has_postcodes: false,
    has_posttowns: true,
  },
  '231': {
    id: 231,
    num_code: 798,
    alpha_2_code: 'TV',
    alpha_3_code: 'TUV',
    country_name: 'Tuvalu',
    nationality: 'Tuvaluan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '232': {
    id: 232,
    num_code: 800,
    alpha_2_code: 'UG',
    alpha_3_code: 'UGA',
    country_name: 'Uganda',
    nationality: 'Ugandan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '233': {
    id: 233,
    num_code: 804,
    alpha_2_code: 'UA',
    alpha_3_code: 'UKR',
    country_name: 'Ukraine',
    nationality: 'Ukrainian',
    has_postcodes: true,
    has_posttowns: true,
  },
  '234': {
    id: 234,
    num_code: 784,
    alpha_2_code: 'AE',
    alpha_3_code: 'ARE',
    country_name: 'United Arab Emirates',
    nationality: 'Emirati, Emirian, Emiri',
    has_postcodes: false,
    has_posttowns: true,
  },
  '235': {
    id: 235,
    num_code: 826,
    alpha_2_code: 'GB',
    alpha_3_code: 'GBR',
    country_name: 'United Kingdom of Great Britain and Northern Ireland',
    nationality: 'British, UK',
    has_postcodes: true,
    has_posttowns: true,
  },
  '236': {
    id: 236,
    num_code: 581,
    alpha_2_code: 'UM',
    alpha_3_code: 'UMI',
    country_name: 'United States Minor Outlying Islands',
    nationality: 'American',
    has_postcodes: false,
    has_posttowns: true,
  },
  '237': {
    id: 237,
    num_code: 840,
    alpha_2_code: 'US',
    alpha_3_code: 'USA',
    country_name: 'United States of America',
    nationality: 'American',
    has_postcodes: true,
    has_posttowns: true,
  },
  '238': {
    id: 238,
    num_code: 858,
    alpha_2_code: 'UY',
    alpha_3_code: 'URY',
    country_name: 'Uruguay',
    nationality: 'Uruguayan',
    has_postcodes: true,
    has_posttowns: true,
  },
  '239': {
    id: 239,
    num_code: 860,
    alpha_2_code: 'UZ',
    alpha_3_code: 'UZB',
    country_name: 'Uzbekistan',
    nationality: 'Uzbekistani, Uzbek',
    has_postcodes: true,
    has_posttowns: true,
  },
  '240': {
    id: 240,
    num_code: 548,
    alpha_2_code: 'VU',
    alpha_3_code: 'VUT',
    country_name: 'Vanuatu',
    nationality: 'Ni-Vanuatu, Vanuatuan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '241': {
    id: 241,
    num_code: 862,
    alpha_2_code: 'VE',
    alpha_3_code: 'VEN',
    country_name: 'Venezuela (Bolivarian Republic of)',
    nationality: 'Venezuelan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '242': {
    id: 242,
    num_code: 704,
    alpha_2_code: 'VN',
    alpha_3_code: 'VNM',
    country_name: 'Vietnam',
    nationality: 'Vietnamese',
    has_postcodes: true,
    has_posttowns: true,
  },
  '243': {
    id: 243,
    num_code: 92,
    alpha_2_code: 'VG',
    alpha_3_code: 'VGB',
    country_name: 'Virgin Islands (British)',
    nationality: 'British Virgin Island',
    has_postcodes: false,
    has_posttowns: true,
  },
  '244': {
    id: 244,
    num_code: 850,
    alpha_2_code: 'VI',
    alpha_3_code: 'VIR',
    country_name: 'Virgin Islands (U.S.)',
    nationality: 'U.S. Virgin Island',
    has_postcodes: true,
    has_posttowns: true,
  },
  '245': {
    id: 245,
    num_code: 876,
    alpha_2_code: 'WF',
    alpha_3_code: 'WLF',
    country_name: 'Wallis and Futuna',
    nationality: 'Wallis and Futuna, Wallisian or Futunan',
    has_postcodes: false,
    has_posttowns: true,
  },
  '246': {
    id: 246,
    num_code: 732,
    alpha_2_code: 'EH',
    alpha_3_code: 'ESH',
    country_name: 'Western Sahara',
    nationality: 'Sahrawi, Sahrawian, Sahraouian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '247': {
    id: 247,
    num_code: 887,
    alpha_2_code: 'YE',
    alpha_3_code: 'YEM',
    country_name: 'Yemen',
    nationality: 'Yemeni',
    has_postcodes: false,
    has_posttowns: true,
  },
  '248': {
    id: 248,
    num_code: 894,
    alpha_2_code: 'ZM',
    alpha_3_code: 'ZMB',
    country_name: 'Zambia',
    nationality: 'Zambian',
    has_postcodes: false,
    has_posttowns: true,
  },
  '249': {
    id: 249,
    num_code: 716,
    alpha_2_code: 'ZW',
    alpha_3_code: 'ZWE',
    country_name: 'Zimbabwe',
    nationality: 'Zimbabwean',
    has_postcodes: false,
    has_posttowns: true,
  },
}

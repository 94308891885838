import React from 'react'
import { useForm } from 'react-hook-form'
import { Typography, Box, makeStyles } from '@material-ui/core'
import { Input } from '@gground/capcom.form'
import { Button } from '@gground/capcom.core'
import { useAppDispatch } from 'src/store'
import { useNavigate } from 'react-router-dom'

import { signIn, trackSnowplow } from 'src/store/slices/user.slice'
import Layout from 'src/components/Layout'
import CopyToClipboard from 'src/components/CopyToClipboard'
import PasswordInput from 'src/components/PasswordInput'
import { showErrorToast } from 'src/utils/toasts'
import { ErrorCode } from 'src/utils/errorCode'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(3, 'auto', 0),
    maxWidth: 600,
    width: '100%',
  },
  bottomLinks: {
    marginTop: 58,
    marginBottom: 48,
    '& > :nth-child(2)': {
      marginTop: theme.spacing(2),
    },
  },
}))

interface FormSubmitParams {
  email: string
  password: string
}

const SignIn = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {
    handleSubmit,
    register,
    watch,
    errors,
    clearErrors,
    formState: { isSubmitted },
  } = useForm({
    shouldFocusError: false,
    reValidateMode: 'onSubmit',
  })
  const onSubmit = ({ email, password }: FormSubmitParams) => {
    dispatch(trackSnowplow({ category: 'signin', action: 'signin' }))
    dispatch(signIn({ email, password }))
      .unwrap()
      .then(({ '2fa_method': method }) => navigate(method ? '2fa' : '/sign-up/2fa'))
      .catch(({ error_code }) => {
        if (error_code === ErrorCode.CONSULTANT_ACCESS_REVOKED) {
          showErrorToast('You no longer have access to the portal.')
        } else if (error_code === ErrorCode.CONSULTANT_HAS_NO_PORTAL_ACCESS) {
          showErrorToast(
            'You do not have access to the portal. Check your email or reach out to support.',
          )
        } else {
          showErrorToast('Sign in failed. Please try again.')
        }
      })
  }

  return (
    <Layout>
      <Box className={classes.container}>
        <Typography variant="h1">Login to the GetGround Accountant Portal</Typography>
        <Box mt={2}>
          <Typography>
            In the wrong place? <a href="https://app.getground.co.uk">Go to our customer app.</a>
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={3}>
            <Input
              name="email"
              id="email"
              label="Email"
              fullWidth
              onFocus={() => clearErrors}
              ref={register({
                required: 'This is a required field',
                pattern: {
                  value: /^\w?([.-]?\w+)+@\w+([.-]+\w+)+$/,
                  message: 'Enter a valid email address',
                },
                maxLength: {
                  value: 128,
                  message: 'Email address needs to be less than 128 characters',
                },
              })}
              error={errors.email}
              helperText={errors.email && errors.email.message}
            />
          </Box>
          <Box mt={2}>
            <PasswordInput
              watch={watch}
              register={register}
              errors={errors}
              isFormSubmitted={isSubmitted}
              onFocus={() => clearErrors}
            />
          </Box>
          {/* <Box mt={2}>
          <Typography>
            Forgot your password? <Link to="/forgot-password/enter-email">Reset password</Link>
          </Typography>
        </Box> */}
          <Box mt={3}>
            <Button type="submit">Login</Button>
          </Box>
        </form>
        <Box className={classes.bottomLinks}>
          <Typography>
            Need help? You can email <CopyToClipboard text="gethelp@getground.co.uk" />
          </Typography>
          <Typography>
            Don’t have an invite but want access? Contact{' '}
            <CopyToClipboard text="sales@getground.co.uk" />
          </Typography>
        </Box>
      </Box>
    </Layout>
  )
}

export default SignIn

import React from 'react'
import {
  Box,
  Checkbox,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { Controller } from 'react-hook-form'
import { Input } from '@gground/capcom.form'
import { Button, Callout } from '@gground/capcom.core'

import Layout from 'src/components/Layout'
import Bin from 'src/icons/Bin'
import { isEmail } from 'src/utils/validate'

import useShareholdingStructure from './useShareholdingStructure'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  deleteIconContainer: {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'center',
  },
  table: {
    '& .MuiTableCell-root:first-child': {
      paddingLeft: 40,
    },
  },
  tableBody: {
    '& tr': {
      height: 64,
    },
    '& td': {
      paddingBottom: 0,
      paddingTop: 16,
      verticalAlign: 'top',
      overflow: 'hidden',
    },
  },
  tableHeader: {
    backgroundColor: '#F9F9FA',
    color: '#1C444E',
    height: 56,
    '&.MuiTableCell-root:first-child': {
      paddingLeft: 27,
    },
  },
  errorMessage: {
    color: '#DF3011',
    margin: theme.spacing(2.5, 0),
  },
  firstRow: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      color: '#4E545F',
    },
  },
  initialValue: {
    paddingTop: 5,
  },
  footer: {
    backgroundColor: '#FFF',
    height: 'auto',
  },
  footerButton: {
    color: '#006BC2',
    cursor: 'pointer',
    textDecoration: 'underline',
    width: 'fit-content',
  },
}))

const ShareholdingStructure = () => {
  const classes = useStyles()
  const {
    form: { register, control, errors },
    fieldArray: { fields, append, remove },
    getError,
    saveAndContinue,
    saveAndExit,
    errorMessage,
    setErrorMessage,
    isHoldCo,
    isLoading,
    companyId,
  } = useShareholdingStructure()

  return (
    <Layout saveAndExit={companyId ? saveAndExit : null}>
      <Box className={classes.container}>
        <Box my={2}>
          <Typography variant="h1">
            {!isHoldCo ? 'Shareholder details' : 'Holding Company shareholder details'}
          </Typography>
        </Box>

        <Box mb={4}>
          {!isHoldCo ? (
            <Callout severity="info">
              Each shareholder will have their own GetGround account and all sharehodler accounts
              will be associated with this company. Each shareholder will receive their invitation
              email once company details have been approved by the client.
            </Callout>
          ) : (
            <Callout severity="warning">
              Each shareholder will own a portion of the the holding company. The holding company is
              the 100% shareholder of all subsidiaries. Shareholders will receive their invitation
              email once company setup has been completed. Each shareholder will have their own
              GetGround profile, and will be able to view their holding company and any subsidiary
              companies.
            </Callout>
          )}
        </Box>
        <form onSubmit={saveAndContinue}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={6} className={classes.tableHeader}>
                    <Typography variant="h3">Shareholders</Typography>
                  </TableCell>
                </TableRow>
                <TableRow className={classes.firstRow}>
                  <TableCell>First name</TableCell>
                  <TableCell>Last name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell align="right">
                    {isHoldCo ? 'Shares (1000 total)' : 'Shares (%)'}
                  </TableCell>
                  <TableCell>Director</TableCell>
                  <TableCell style={{ minWidth: '40px' }} />
                </TableRow>
              </TableHead>
              {!isLoading ? (
                <>
                  <TableBody className={classes.tableBody}>
                    {fields.map((item, index) => (
                      <TableRow key={item.id}>
                        <TableCell style={{ width: '23%' }}>
                          {index === 0 ? (
                            <Typography className={classes.initialValue}>
                              {item.first_name}
                            </Typography>
                          ) : (
                            <Input
                              id="first_name"
                              name={`shareholders.${index}.first_name`}
                              fullWidth
                              ref={register({ required: 'This field is required' })}
                              defaultValue={item.first_name}
                              error={getError(index, 'first_name')}
                              helperText={
                                getError(index, 'first_name')
                                  ? errors?.shareholders?.[index]?.first_name?.message
                                  : ''
                              }
                            />
                          )}
                        </TableCell>
                        <TableCell style={{ width: '23%' }}>
                          {index === 0 ? (
                            <Typography className={classes.initialValue}>
                              {item.last_name}
                            </Typography>
                          ) : (
                            <Input
                              id="last_name"
                              name={`shareholders.${index}.last_name`}
                              fullWidth
                              ref={register({ required: 'This field is required' })}
                              defaultValue={item.last_name}
                              error={getError(index, 'last_name')}
                              helperText={
                                getError(index, 'last_name')
                                  ? errors?.shareholders?.[index]?.last_name?.message
                                  : ''
                              }
                            />
                          )}
                        </TableCell>
                        <TableCell style={{ width: '37%' }}>
                          {index === 0 ? (
                            <Typography className={classes.initialValue}>{item.email}</Typography>
                          ) : (
                            <Input
                              id="email"
                              name={`shareholders.${index}.email`}
                              fullWidth
                              ref={register({
                                required: 'This field is required',
                                validate: {
                                  email: (val) => isEmail(val) || 'Invalid email address',
                                },
                                maxLength: {
                                  value: 128,
                                  message: 'Needs to be less than 128 characters',
                                },
                              })}
                              onFocus={() => setErrorMessage('')}
                              defaultValue={item.email}
                              error={getError(index, 'email')}
                              helperText={
                                getError(index, 'email')
                                  ? errors?.shareholders?.[index]?.email?.message
                                  : ''
                              }
                            />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Box width="51px" ml="auto">
                            <Input
                              id="allocated_shares"
                              name={`shareholders.${index}.allocated_shares`}
                              fullWidth
                              ref={register({
                                required: 'Required',
                                min: { value: 1, message: isHoldCo ? 'Min 1' : 'Min 1%' },
                                max: {
                                  value: isHoldCo ? 1000 : 100,
                                  message: isHoldCo ? 'Max 1000' : 'Max 100%',
                                },
                                validate: {
                                  wholeNumberOnly: (val) => val % 1 === 0 || 'No floating',
                                },
                                valueAsNumber: true,
                              })}
                              defaultValue={item.allocated_shares}
                              onFocus={() => setErrorMessage('')}
                              error={getError(index, 'allocated_shares')}
                              helperText={
                                getError(index, 'allocated_shares')
                                  ? errors?.shareholders?.[index]?.allocated_shares?.message
                                  : ''
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell style={{ paddingTop: 10 }}>
                          <Controller
                            control={control}
                            name={`shareholders.${index}.is_director`}
                            defaultValue={item.is_director}
                            render={({ onChange, onBlur, value, ref }) => (
                              <Checkbox
                                onBlur={onBlur}
                                onChange={(e) => onChange(e.target.checked)}
                                checked={value}
                                inputRef={ref}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell style={{ paddingLeft: 0, paddingTop: 18 }}>
                          {index !== 0 ? (
                            <Box className={classes.deleteIconContainer}>
                              <Bin onClick={() => remove(index)} />
                            </Box>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>

                  <TableFooter>
                    <TableRow className={classes.footer}>
                      <TableCell colSpan={6}>
                        {errorMessage && (
                          <Typography className={classes.errorMessage}>{errorMessage}</Typography>
                        )}
                        {fields.length < 8 && (
                          <Typography
                            onClick={() => append({ is_director: false })}
                            className={classes.footerButton}
                          >
                            + Add another shareholder
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={6} style={{ padding: 0 }}>
                      <Skeleton variant="rect" height={100} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <Box my={4}>
            <Button type="submit">Next</Button>
          </Box>
        </form>
      </Box>
    </Layout>
  )
}

export default ShareholdingStructure

import React from 'react'
import { SnackbarProvider as ReactSnackbarProvider } from 'notistack'
import { makeStyles } from '@material-ui/core'

import { SnackbarConfigurator } from 'src/utils/toasts'

const notificationStyle = {
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
  borderRadius: '4px',
  fontWeight: 500,
}

const useStyles = makeStyles({
  success: {
    backgroundColor: '#BAF2C7 !important',
    color: '#163C1B !important',
    ...notificationStyle,
  },
  error: {
    backgroundColor: '#FACAC1 !important',
    color: '#4D1005 !important',
    ...notificationStyle,
  },
  warning: {
    backgroundColor: '#FFEC99 !important',
    color: '#5C3E00 !important',
    ...notificationStyle,
  },
  info: {
    backgroundColor: '#E8F4F7 !important',
    color: '#2A6574 !important',
    ...notificationStyle,
  },
})

const SnackbarProvider: React.FC = ({ children }) => {
  const classes = useStyles()

  return (
    <ReactSnackbarProvider
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      maxSnack={2}
      autoHideDuration={3000}
      hideIconVariant
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
    >
      <SnackbarConfigurator />
      {children}
    </ReactSnackbarProvider>
  )
}

export default SnackbarProvider

import React from 'react'
import { Card, CardHeader, CardContent, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    '& .MuiCardHeader-action': {
      width: '100%',
    },
    '& .MuiSkeleton-root': {
      transform: 'none',
    },
  },
  loadingContainer: {
    '& .MuiSkeleton-root': {
      transform: 'none',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
    },
  },
}))

const LoadingSection = () => {
  const classes = useStyles()
  return (
    <Card>
      <CardHeader action={<Skeleton height={24} width={200} />} className={classes.cardHeader} />
      <CardContent className={classes.loadingContainer}>
        <Skeleton height={58} />
        <Skeleton height={58} />
      </CardContent>
    </Card>
  )
}

export default LoadingSection

import { Box, makeStyles, MenuItem, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Select } from '@gground/capcom.form'
import { Button, Callout } from '@gground/capcom.core'

import BackButton from 'src/components/BackButton'
import Layout from 'src/components/Layout'
import { useAppDispatch, useAppSelector } from 'src/store'
import { fetchPartnerOverview } from 'src/store/slices/partner.slice'
import { Controller, useForm } from 'react-hook-form'
import { createPropertyPurchase } from 'src/store/slices/propertyPurchases.slice'
import { OverviewStatus } from 'src/types'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 600,
    margin: theme.spacing(2, 0),
  },
}))

const ControllingHoldco = () => {
  const classes = useStyles()
  const { clientId } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const clients = useAppSelector((state) => state.partner.clients)
  const holdcos = clients
    .find((client) => client.id === parseInt(clientId, 10))
    ?.property_purchases.filter(
      (pp) => pp.is_holding_co && pp.company_formation.incorporation === OverviewStatus.COMPLETE,
    )
    .map((pp) => ({ id: pp.id, companyId: pp.company_id }))

  useEffect(() => {
    dispatch(fetchPartnerOverview())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { control, handleSubmit, errors } = useForm()
  const onSubmit = (companyId: number) => {
    dispatch(
      createPropertyPurchase({
        clientId,
        payload: { is_holding_co: false, parent_company_id: companyId },
      }),
    )
      .unwrap()
      .then(({ id }) => {
        navigate(`/clients/${clientId}/companies/${id}/details`)
      })
  }

  return (
    <Layout>
      <Box my={4} className={classes.container}>
        <BackButton to="/dashboard" />
        <Box my={2}>
          <Typography variant="h1">Select the controlling holding company</Typography>
        </Box>

        <Callout severity="info">
          This is the holding company that will be the 100% owner of the new subsidiary.
        </Callout>

        <form onSubmit={handleSubmit((formData) => onSubmit(formData.holdco))} noValidate>
          <Box mt={2} mb={4}>
            <Controller
              name="holdco"
              control={control}
              rules={{ required: 'This field is required' }}
              defaultValue=""
              render={({ onChange, value }) => {
                return (
                  <Select
                    name="holdco"
                    label="Holding company *"
                    error={Boolean(errors.holdco)}
                    onChange={(e: React.MouseEvent) => onChange(e)}
                    value={value}
                    fullWidth
                  >
                    {holdcos?.map(({ id, companyId }) => (
                      <MenuItem key={id} value={companyId}>
                        {`#${id}`}
                      </MenuItem>
                    ))}
                  </Select>
                )
              }}
            />
          </Box>
          <Button type="submit">Next</Button>
        </form>
      </Box>
    </Layout>
  )
}

export default ControllingHoldco

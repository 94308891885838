import React from 'react'
import { Button, Callout } from '@gground/capcom.core'
import { Box, Typography, DialogActions } from '@material-ui/core'
import { Mail as MailIcon } from '@gground/capcom.icons'

import { sendEmailForApproval } from 'src/store/slices/propertyPurchases.slice'
import { showErrorToast, showSuccessToast } from 'src/utils/toasts'
import { useAppDispatch } from 'src/store'
import { useNavigate } from 'react-router-dom'
import { trackSnowplow } from 'src/store/slices/user.slice'

interface Props {
  onClose: () => void
  clientId: number
  propertyPurchaseId: number
  clientName: string
}

const ConfirmationModal = ({ onClose, clientName, clientId, propertyPurchaseId }: Props) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const reviewAndSend = () => {
    dispatch(
      trackSnowplow({
        category: 'review_screen',
        action: 'review_screen_popup_send',
        ppId: propertyPurchaseId,
      }),
    )
    dispatch(sendEmailForApproval({ clientId, propertyPurchaseId }))
      .unwrap()
      .then(() => {
        showSuccessToast('Sent to customer for approval.')
        navigate('/dashboard')
      })
      .catch(() => showErrorToast('Failed to send. Try again'))
      .finally(onClose)
  }

  return (
    <Box>
      <Typography>
        Once you send this information for approval, {clientName} will be able to view and edit any
        part of it. If they are not yet a member of the platform, they will receive an invitation to
        sign up.
      </Typography>
      <Box my={2}>
        <Callout severity="warning">
          IMPORTANT: Once you send this information, you will not be able to edit it, only your
          client will be able to make changes.
        </Callout>
      </Box>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Box ml={1}>
          <Button onClick={() => reviewAndSend()} startIcon={<MailIcon />}>
            Send
          </Button>
        </Box>
      </DialogActions>
    </Box>
  )
}

export default ConfirmationModal

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { Button } from '@gground/capcom.core'
import { ClientOverview, OverviewStatus } from 'src/types'
import { useAppDispatch } from 'src/store'
import { trackSnowplow } from 'src/store/slices/user.slice'
import ProgressStep from './ProgressStep'
import StepTask from './StepTask'

interface CreateProfileStepProps {
  clientId: ClientOverview['id']
  profileStatus: ClientOverview['profile_status']
}

const CreateProfileStep = ({ clientId, profileStatus }: CreateProfileStepProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const resume = () => {
    dispatch(trackSnowplow({ category: 'dashboard', action: 'profile_resume' }))
    navigate(`/clients/${clientId}`)
  }

  return (
    <ProgressStep status={profileStatus} name="Complete profile" position={1}>
      {profileStatus === OverviewStatus.IN_PROGRESS ? (
        <Box mx={2} mb={2}>
          <Button onClick={resume}>Resume</Button>
        </Box>
      ) : profileStatus === OverviewStatus.PENDING_APPROVAL ? (
        <StepTask name="Sent for review" status={profileStatus} />
      ) : null}
    </ProgressStep>
  )
}

export default CreateProfileStep

import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Typography, Box, makeStyles, Grid } from '@material-ui/core'
import { SortNeutral, Download as DownloadIcon } from '@gground/capcom.icons'
import { Button } from '@gground/capcom.core'
import {
  fetchBusinessAccountTransactions,
  downloadBusinessAccountTransactions,
} from 'src/store/slices/company.slice'
import { useAppDispatch, useAppSelector } from 'src/store'
import { Transaction as TransactionType } from 'src/types'
import { showErrorToast } from 'src/utils/toasts'
import { trackSnowplow } from 'src/store/slices/user.slice'
import { useFlags } from 'launchdarkly-react-client-sdk'
import Transaction from './Transaction'
import type { CompanyOutletContext } from '../../types'

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'fit-content',
    minWidth: 650,
    '& .MuiGrid-item': {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
    },
  },
  headerWrapper: {
    display: 'contents',
    '& > .MuiGrid-item': {
      paddingTop: 11,
      paddingBottom: 11,
      borderBottom: '1px solid #DEE0E3',
      color: '#4E545F',
    },
  },
  sortIcon: {
    marginLeft: theme.spacing(2),
    cursor: 'pointer',
  },
  contentToEnd: {
    justifyContent: 'flex-end',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 64,
    padding: 16,
    borderBottom: '1px solid #DEE0E3',
  },
}))

const TransactionsList = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { clientId, companyId } = useOutletContext<CompanyOutletContext>()
  const txs = useAppSelector((state) => state.company.wallet?.transactions)

  const { showFees } = useFlags()
  const { transactionsDailyAccountBalance: showBalance } = useFlags()

  useEffect(() => {
    if (clientId && companyId) {
      dispatch(fetchBusinessAccountTransactions({ clientId, companyId }))
    }
  }, [clientId, companyId, dispatch])

  const exportToCSV = () => {
    dispatch(downloadBusinessAccountTransactions({ clientId, companyId }))
      .unwrap()
      .catch(() => showErrorToast('Failed to download transactions'))

    dispatch(
      trackSnowplow({
        category: 'transactions',
        action: 'export_csv',
      }),
    )
  }

  const amountGridItemSize = showFees && showBalance ? 3 : showFees || showBalance ? 4 : 6

  return (
    <Box overflow="auto" width="100%">
      <Box className={classes.actions}>
        <Button onClick={exportToCSV} startIcon={<DownloadIcon />} variant="outlined">
          Export to CSV
        </Button>
      </Box>
      <Grid container className={classes.container}>
        <Box className={classes.headerWrapper}>
          <Grid item xs={2}>
            <Typography>Date</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Details</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>Category</Typography>
          </Grid>
          <Grid container item xs={5}>
            <Grid item xs={amountGridItemSize} className={classes.contentToEnd}>
              <Typography>Paid in</Typography>
            </Grid>
            <Grid item xs={amountGridItemSize} className={classes.contentToEnd}>
              <Typography>Paid out</Typography>
            </Grid>
            {showFees ? (
              <Grid item xs={amountGridItemSize} className={classes.contentToEnd}>
                <Typography>Fees</Typography>
              </Grid>
            ) : null}
            {showBalance ? (
              <Grid item xs={amountGridItemSize} className={classes.contentToEnd}>
                <Typography>Balance</Typography>
              </Grid>
            ) : null}
          </Grid>
        </Box>
        {txs.map((item: TransactionType) => (
          <Transaction
            tx={item}
            key={item.date}
            clientId={clientId}
            companyId={companyId}
            showFees={showFees}
            showBalance={showBalance}
          />
        ))}
      </Grid>
    </Box>
  )
}

export default TransactionsList

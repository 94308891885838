import React from 'react'
import { Box, Card, CardHeader, CardContent, Grid, Typography, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { getOneLineAddress } from 'src/utils/misc'
import { showSuccessToast } from 'src/utils/toasts'
import type { CompanyDetails } from 'src/types'

const useStyles = makeStyles((theme) => ({
  box: {
    background: '#F9F9FA',
    borderRadius: 4,
    padding: theme.spacing(1),
    cursor: 'pointer',
    height: 58,
  },
  name: {
    color: '#4E545F',
  },
  value: {
    fontWeight: 500,
  },
  loadingContainer: {
    '& .MuiSkeleton-root': {
      transform: 'none',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
    },
  },
}))

interface Props {
  companyDetails: CompanyDetails['company_details']
  isLoading: boolean
}

const CompanyDetailsSection = ({ companyDetails, isLoading }: Props) => {
  const classes = useStyles()
  return (
    <Card>
      <CardHeader
        title="Company details"
        action={
          <Box>
            <Typography variant="subtitle1" component="i">
              Hint: Click on a field to copy it
            </Typography>
          </Box>
        }
      />
      <CardContent>
        {isLoading ? (
          <Box className={classes.loadingContainer}>
            <Skeleton height={58} />
            <Skeleton height={58} />
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <Box className={classes.box}>
                <Typography variant="body1" className={classes.name}>
                  Company name:
                </Typography>
                <CopyToClipboard
                  text={companyDetails?.company_name}
                  onCopy={() => showSuccessToast('Copied')}
                >
                  <Typography variant="body1" className={classes.value}>
                    {companyDetails?.company_name}
                  </Typography>
                </CopyToClipboard>
              </Box>
            </Grid>
            <Grid item sm={4}>
              <Box className={classes.box}>
                <Typography variant="body1" className={classes.name}>
                  Company number:
                </Typography>
                <CopyToClipboard
                  text={companyDetails?.registration_number}
                  onCopy={() => showSuccessToast('Copied')}
                >
                  <Typography variant="body1" className={classes.value}>
                    {companyDetails?.registration_number}
                  </Typography>
                </CopyToClipboard>
              </Box>
            </Grid>
            <Grid item sm={4}>
              <Box className={classes.box}>
                <Typography variant="body1" className={classes.name}>
                  UTR code:
                </Typography>
                <CopyToClipboard
                  text={companyDetails?.utr_code}
                  onCopy={() => showSuccessToast('Copied')}
                >
                  <Typography variant="body1" className={classes.value}>
                    {companyDetails?.utr_code}
                  </Typography>
                </CopyToClipboard>
              </Box>
            </Grid>
            <Grid item sm={12}>
              <Box className={classes.box}>
                <Typography variant="body1" className={classes.name}>
                  Full address:
                </Typography>
                <CopyToClipboard
                  text={getOneLineAddress(companyDetails?.registered_address) || ''}
                  onCopy={() => showSuccessToast('Copied')}
                >
                  <Typography variant="body1" className={classes.value}>
                    {getOneLineAddress(companyDetails?.registered_address)}
                  </Typography>
                </CopyToClipboard>
              </Box>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}

export default CompanyDetailsSection

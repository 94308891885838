import React from 'react'
import { Box, MenuItem, Typography } from '@material-ui/core'
import { Select, Input } from '@gground/capcom.form'
import { Controller, useFormContext } from 'react-hook-form'
import { validateLettersOnly } from './utils'
import type { ClientFormFields } from './types'

type EmploymentStatus = NonNullable<ClientFormFields['employment_status']>

const employmentStatusOptions: Record<EmploymentStatus, string> = {
  full_time: 'Employed full-time',
  part_time: 'Employed part-time',
  self_employed: 'Self-employed',
  unemployed: 'Unemployed',
  pensioner: 'Pensioner',
  student: 'Student',
  other: 'Other',
}

const ProfessionFormFragment = () => {
  const { register, clearErrors, control, errors, setValue, watch } =
    useFormContext<ClientFormFields>()

  const employmentStatus = watch('employment_status')

  return (
    <Box mt={4} component="section">
      <Typography variant="h2" style={{ fontWeight: 500 }}>
        Profession
      </Typography>
      <Typography>
        Companies House require a profession for all shareholders of a company. We do not share
        salary range with Companies House.
      </Typography>
      <Box mt={2}>
        <Controller
          name="employment_status"
          control={control}
          defaultValue=""
          render={({ onChange, value }) => (
            <Select
              id="employment_status"
              name="employment_status"
              label="Employment status"
              value={value}
              onChange={(e) => {
                const update = e.target.value as EmploymentStatus
                const occupation = ['unemployed', 'pensioner', 'student'].includes(update)
                  ? employmentStatusOptions[update]
                  : null

                setValue('occupation', occupation)
                onChange(e)
              }}
              fullWidth
            >
              {Object.entries(employmentStatusOptions).map(([status, label]) => (
                <MenuItem key={status} value={status}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Box>

      {employmentStatus === 'other' ? (
        <Box mt={2}>
          <Input
            type="text"
            id="employment_status_other_reason"
            name="employment_status_other_reason"
            label="Please explain your employment status"
            helperText={errors.employment_status_other_reason?.message}
            error={!!errors.employment_status_other_reason}
            onFocus={() => clearErrors()}
            ref={register()}
            fullWidth
          />
        </Box>
      ) : null}

      <Box mt={2}>
        <Input
          type="text"
          id="occupation"
          name="occupation"
          label="Profession"
          helperText={errors.occupation?.message}
          error={!!errors.occupation}
          onFocus={() => clearErrors()}
          ref={register({ validate: validateLettersOnly })}
          fullWidth
        />
      </Box>
    </Box>
  )
}

export default ProfessionFormFragment

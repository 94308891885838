import type { Client, PropertyPurchase, PropertyPurchaseUpdate } from 'src/types'
import { API } from './base'

export interface CreatePropertyPurchasePayload {
  clientId: number
  payload: Pick<PropertyPurchase, 'is_holding_co' | 'shareholder_details'>
}

export const createPropertyPurchase = async ({
  clientId,
  payload,
}: CreatePropertyPurchasePayload): Promise<PropertyPurchase> =>
  API.post(`partner_portal/clients/${clientId}/property_purchases`, payload).then(
    (response) => response.data,
  )

export interface FetchPropertyPurchasePayload {
  clientId: number
  propertyPurchaseId: number
}

export const fetchPropertyPurchase = async ({
  clientId,
  propertyPurchaseId,
}: FetchPropertyPurchasePayload): Promise<PropertyPurchase> =>
  API.get(`partner_portal/clients/${clientId}/property_purchases/${propertyPurchaseId} `).then(
    (response) => response.data,
  )

export interface UpdatePropertyPurchasePayload {
  clientId: number
  propertyPurchaseId: number
  payload: PropertyPurchaseUpdate
}

export const updatePropertyPurchase = async ({
  clientId,
  propertyPurchaseId,
  payload,
}: UpdatePropertyPurchasePayload): Promise<PropertyPurchase> =>
  API.patch(
    `partner_portal/clients/${clientId}/property_purchases/${propertyPurchaseId}`,
    payload,
  ).then((response) => response.data)

export interface SendEmailForApprovalOptions {
  clientId: Client['id']
  propertyPurchaseId: PropertyPurchase['id']
}

export const sendEmailForApproval = async ({
  clientId,
  propertyPurchaseId,
}: SendEmailForApprovalOptions) =>
  API.post(
    `partner_portal/clients/${clientId}/property_purchases/${propertyPurchaseId}/send_for_approval`,
  ).then((response) => response.data)

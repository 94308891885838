export const hasLowerCaseLetter = (input: string) => /[a-z]/.test(input)

export const hasCapitalLetter = (input: string) => /[A-Z]/.test(input)

export const hasSpecialCharacter = (input: string) => /[^a-zA-Z0-9,.'-\s]/.test(input)

export const noSpecialCharacter = (input: string) => !/[^a-zA-Z0-9,.'-\s]/.test(input)

export const hasNumber = (input: string) => /\d/.test(input)

export const noLetters = (input: string) => !/[a-zA-Z]+/.test(input)

export const isEmail = (input: string) => /^\w?([.-]?\w+)+@\w+([.-]+\w+)+$/.test(input)

export const lettersOnly = (input: string) => !/[^a-zA-Z-\s]/.test(input)

export const noTrailingWhitespace = (input: string) => input.length === input.trim().length

export const isAddressLine = (input: string) => !/[^a-zA-Z0-9#,.'-/\s]/.test(input)

export const isGreaterThanZero = (input: string) => {
  const inputWithoutCommas = input.toString().replace(/,/g, '')
  return Number(inputWithoutCommas) > 0
}

export const isCurrencyValue = (input: string) => /^\d+(\.\d{1,2})?$/.test(input)

import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const HorizontalArrows = (props: SvgIconProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.89153 19.0347C7.55679 19.3694 7.01408 19.3694 6.67934 19.0347L3.25077 15.6061C2.91604 15.2713 2.91604 14.7286 3.25077 14.3939L6.67934 10.9653C7.01408 10.6306 7.55679 10.6306 7.89153 10.9653C8.22626 11.3001 8.22626 11.8428 7.89153 12.1775L5.06905 15L7.89153 17.8225C8.22626 18.1572 8.22626 18.6999 7.89153 19.0347Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2481 4.96535C16.5829 4.63061 17.1256 4.63061 17.4603 4.96535L20.8889 8.39392C21.2236 8.72865 21.2236 9.27136 20.8889 9.6061L17.4603 13.0347C17.1256 13.3694 16.5829 13.3694 16.2481 13.0347C15.9134 12.6999 15.9134 12.1572 16.2481 11.8225L19.0706 9.00001L16.2481 6.17753C15.9134 5.84279 15.9134 5.30008 16.2481 4.96535Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 15C14 15.5 13.6429 16 13.2857 16L4.71429 16C4.35714 16 4 15.5 4 15C4 14.5 4.35714 14 4.71429 14L13.2857 14C13.6429 14 14 14.5 14 15Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9971 9C19.9971 9.5 19.6399 10 19.2828 10L10.7114 10C10.3542 10 9.99707 9.5 9.99707 9C9.99707 8.5 10.3542 8 10.7114 8L19.2828 8C19.6399 8 19.9971 8.5 19.9971 9Z"
    />
  </SvgIcon>
)

export default HorizontalArrows
